<script>
export default {
  props: {
    currentMenuIndex: {
      type: Number,
      required: true,
    },
    isFirstItem: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['movePrev', 'openPreview', 'closePreview', 'exit'],
  data() {
    return {
      showPreview: false,
    };
  },
  computed: {
    progressText() {
      const texts = [
        this.$globalTexts.giftcard__front || 'giftcard__front',
        this.$globalTexts.giftcard__inside || 'giftcard__inside',
        this.$globalTexts.giftcard__web || 'giftcard__web',
        this.$globalTexts.giftcard__summary || 'giftcard__summary',
      ];

      const display = `${this.currentMenuIndex + 1}/${texts.length}
        ${texts[this.currentMenuIndex]}`;

      return display;
    },
  },
  methods: {
    setShowPreview() {
      this.showPreview = true;
      this.$emit('openPreview');
    },
    setHidePreview() {
      this.showPreview = false;
      this.$emit('closePreview');
    },
    previousOrExit() {
      if (this.isFirstItem) {
        this.$emit('exit');
      } else {
        this.$emit('movePrev');
      }
    },
  },
};
</script>

<template>
  <div :class="['progress-menu__wrapper',
    {'progress-menu-wrapper__preview-mode' : showPreview}]"
  >
    <div class="progress-menu">
      <template v-if="showPreview">
        <div class="progress-menu__prev-text">
          {{ $globalTexts.giftcard__preview || 'giftcard__preview' }}
        </div>
        <img
            src="../../static/icons/cancel_icon.svg"
            class="progress-menu__cancel-icon"
            @click="setHidePreview"
          >
      </template>
      <template v-else>
        <div
          class="progress-menu__previous-button"
          @click="previousOrExit()"
        >
          <img
            src="../../static/icons/arrow_left_icon.svg"
            class="progress-menu__prev-icon"
          >
        </div>
        <div
          v-if="!isFirstItem"
          class="progress-menu__divider"
        ></div>
        <span class="progress-menu__progress">
          {{ progressText }}
        </span>
        <div
          class="progress-menu__preview-button"
          @click="setShowPreview"
        >
          <img src="../../static/icons/eye_icon.svg">
          {{ $globalTexts.giftcard__preview || 'giftcard__preview' }}
        </div>
      </template>
    </div>
  </div>
</template>

<style>
  .progress-menu__wrapper {
    position: relative;
    max-height: 48px;
    padding: 0 1rem;
    z-index: 1;
    background: var(--color-white);
    border-bottom: 1px solid var(--color-border-50);
    box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%),
                0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
  }

  .progress-menu-wrapper__preview-mode {
    box-shadow: none;
    border: none;
    max-height: 20px;
    padding: 10px 10px 0 1rem;
  }

  .progress-menu {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    max-width: 450px;
    margin: auto;
  }

  .progress-menu__prev-icon {
    width: 16px;
    margin-right: 5px;
  }

  .progress-menu__divider {
    border-right: 1px solid var(--color-border-50);
    align-self: stretch;
  }

  .progress-menu__progress {
    font-size: 14px;
    line-height: 22px;
  }

  .progress-menu__preview-button {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
  }

  .progress-menu__prev-text {
    font-size: 14px;
    font-weight: 700;
  }

  .progress-menu__cancel-icon {
    margin-left: auto;
    width: 14px;
  }
</style>
