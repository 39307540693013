import { defineReceiver, defineCommand } from '@drapejs/invoker';
import { request, query, sales } from '@motillo/drapejs-litium';
import { gql } from 'graphql-request';

export const commands = {
  addPrintToCart: defineCommand<{
    url: string;
    variantSystemId: string;
    quantity?: number;
    variants: string;
    giftCardInfo: string;
    printVariantSystemId: string;
    printProductSystemId: string;
    printImageSystemId: string;
    printAddons: string;
    printText: string;
    printPosition: string;
    printPreviewDataUrl: string;
    desiredWidth: number;
    desiredHeight: number;
    colors: string;
  }>('addPrintToCart'),
  changeDeliveryOption: defineCommand<{
    url: string;
    pickedShippingDate: Date;
    deliveryPreference: string;
  }>('changeDeliveryOption'),
  setCartRowUpsellProduct: defineCommand<{
    url: string;
    rowSystemId: string;
    variantSystemId: string;
    upsellItemRowSystemId: string;
    upsellItemVariantSystemId: string;
    isUpsellItem: boolean;
    quantity: number;
  }>('setCartRowUpsellProduct'),
  setAddress: defineCommand<{
    url: string;
    partial: boolean;
    saveOrganizationAddress: boolean;
    isWholesaleAddress: boolean;
    careOf: string;
    email: string;
    firstName: string;
    lastName: string;
    postalCode: string;
    address1: string;
    address2: string;
    city: string;
    mobilePhone: string;
    phone: string;
    state: string;
    country: string;
    systemId: string;
  }>('setAddress'),
  addOrUpdateContactPerson: defineCommand<{
    systemId: string;
    url: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    saveContact: boolean;
    navCustomerContactNumber: string;
  }>('addOrUpdateContactPerson'),
  placeWholesaleOrder: defineCommand<{
    url: string;
    data: {
      noteOfGoods: string;
      checkoutAddress: {
        address1: string;
        address2: string;
        careOf: string;
        city: string;
        country: string;
        zip: string;
        phone: string;
        state: string;
      };
    };
  }>('placeWholesaleOrder'),
  setSentPostPurchaseGtmEvent: defineCommand<{
    url: string;
    orderId: string;
  }>('setSentPostPurchaseGtmEvent'),
  addBackInStockAlert: defineCommand<{
    url: string;
    email: string;
    variantId: string;
  }>('addBackInStockAlert'),
  removeGroupFromCart: defineCommand<{
    groupId: string;
  }>('removeGroupFromCart'),
  getProductDataFromCart: defineCommand<{
    url: string;
    path: string;
  }>('getProductDataFromCart'),
  updateAddonExtraInfo: defineCommand<{
    rowId: string;
    addonId: string;
    extraInfo: string;
  }>('updateAddonExtraInfo')
};

export const receivers = {
  addPrintToCart: defineReceiver(
    commands.addPrintToCart,
    async function (command, data) {
      const quantity = command.quantity || 1;
      const result = await request(
        this.cache,
        query(
          gql`
            mutation addPrintToCart(
              $url: String!
              $variantSystemId: String
              $quantity: Decimal
              $variants: String
              $printVariantSystemId: String
              $printProductSystemId: String
              $printImageSystemId: String
              $printAddons: String
              $printText: String
              $printPosition: String
              $printPreviewDataUrl: String
              $desiredWidth: Decimal
              $desiredHeight: Decimal
              $colors: String
              $giftCardInfo: String
            ) {
              session(url: $url) {
                addPrintToCart {
                  addPrintToCart(
                    variantSystemId: $variantSystemId
                    quantity: $quantity
                    variants: $variants
                    printVariantSystemId: $printVariantSystemId
                    printProductSystemId: $printProductSystemId
                    printImageSystemId: $printImageSystemId
                    printAddons: $printAddons
                    printText: $printText
                    printPosition: $printPosition
                    printPreviewDataUrl: $printPreviewDataUrl
                    desiredWidth: $desiredWidth
                    desiredHeight: $desiredHeight
                    colors: $colors
                    giftCardInfo: $giftCardInfo
                  ) {
                    error
                    row {
                      ...RowFields
                    }
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: command.url,
          variantSystemId: command.variantSystemId,
          quantity,
          printVariantSystemId: command.printVariantSystemId,
          printProductSystemId: command.printProductSystemId,
          printImageSystemId: command.printImageSystemId,
          printAddons: command.printAddons,
          printText: command.printText,
          printPosition: command.printPosition,
          printPreviewDataUrl: command.printPreviewDataUrl,
          variants: command.variants,
          desiredWidth: command.desiredWidth,
          desiredHeight: command.desiredHeight,
          colors: command.colors,
          giftCardInfo: command.giftCardInfo
        }
      );

      await this.cache.setItem(
        '__cart',
        result.session.addPrintToCart.addPrintToCart.cart
      );

      return {
        error: result.session.addPrintToCart.addPrintToCart.error,
        row: result.session.addPrintToCart.addPrintToCart.row
      };
    },
    'litium'
  ),
  changeDeliveryOption: defineReceiver(
    commands.changeDeliveryOption,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation changeDeliveryOption(
              $url: String!
              $deliveryPreference: String!
              $pickedShippingDate: DateTime
            ) {
              session(url: $url) {
                changeDeliveryOption {
                  changeDeliveryOption(
                    deliveryPreference: $deliveryPreference
                    pickedShippingDate: $pickedShippingDate
                  ) {
                    error
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          deliveryPreference: command.deliveryPreference,
          pickedShippingDate: command.pickedShippingDate
        }
      );

      await this.cache.setItem(
        '__cart',
        result.session.changeDeliveryOption.changeDeliveryOption.cart
      );

      return {
        error: result.session.changeDeliveryOption.changeDeliveryOption.error
      };
    },
    'litium'
  ),
  setCartRowUpsellProduct: defineReceiver(
    commands.setCartRowUpsellProduct,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setCartRowUpsellProduct(
              $url: String!
              $rowSystemId: String!
              $variantSystemId: String!
              $upsellItemRowSystemId: String!
              $upsellItemVariantSystemId: String!
              $isUpsellItem: Boolean!
              $quantity: Decimal!
            ) {
              session(url: $url) {
                setCartRowUpsellProduct {
                  setCartRowUpsellProduct(
                    rowSystemId: $rowSystemId
                    variantSystemId: $variantSystemId
                    upsellItemRowSystemId: $upsellItemRowSystemId
                    upsellItemVariantSystemId: $upsellItemVariantSystemId
                    isUpsellItem: $isUpsellItem
                    quantity: $quantity
                  ) {
                    error
                    row {
                      id
                      variantName
                      articleNumber
                      unitPrice
                      quantity
                    }
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          rowSystemId: command.rowSystemId,
          variantSystemId: command.variantSystemId,
          upsellItemRowSystemId: command.upsellItemRowSystemId,
          upsellItemVariantSystemId: command.upsellItemVariantSystemId,
          quantity: command.quantity,
          isUpsellItem: true
        }
      );

      await this.cache.setItem(
        '__cart',
        result.session.setCartRowUpsellProduct.setCartRowUpsellProduct.cart
      );

      return {
        error:
          result.session.setCartRowUpsellProduct.setCartRowUpsellProduct.error,
        row: result.session.setCartRowUpsellProduct.setCartRowUpsellProduct.row
      };
    },
    'litium'
  ),
  setAddress: defineReceiver(
    commands.setAddress,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setAddress(
              $url: String
              $careOf: String
              $email: String
              $firstName: String
              $lastName: String
              $postalCode: String
              $address1: String
              $address2: String
              $city: String
              $mobilePhone: String
              $phone: String
              $state: String
              $country: String!
              $partial: Boolean!
              $systemId: String
              $isWholesaleAddress: Boolean!
              $saveOrganizationAddress: Boolean!
            ) {
              session(url: $url) {
                setAddress {
                  setAddress(
                    email: $email
                    careOf: $careOf
                    firstName: $firstName
                    lastName: $lastName
                    postalCode: $postalCode
                    address1: $address1
                    address2: $address2
                    city: $city
                    mobilePhone: $mobilePhone
                    phone: $phone
                    state: $state
                    country: $country
                    partial: $partial
                    systemId: $systemId
                    isWholesaleAddress: $isWholesaleAddress
                    saveOrganizationAddress: $saveOrganizationAddress
                  ) {
                    error
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          email: command.email || '',
          careOf: command.careOf || '',
          firstName: command.firstName || '',
          lastName: command.lastName || '',
          address1: command.address1 || '',
          address2: command.address2 || '',
          city: command.city || '',
          mobilePhone: command.mobilePhone || '',
          phone: command.phone || '',
          state: command.state || '',
          country: command.country || '',
          partial: command.partial || false,
          postalCode: command.postalCode || '',
          isWholesaleAddress: true,
          saveOrganizationAddress: command.saveOrganizationAddress || false,
          systemId: command.systemId
        }
      );

      await this.cache.setItem(
        '__cart',
        result.session.setAddress.setAddress.cart
      );

      if (
        !command.partial &&
        result.session.setAddress.setAddress.error &&
        result.session.setAddress.setAddress.error !== 'NONE'
      ) {
        throw result.session.setAddress.setAddress.error;
      }

      return result.session.setAddress.setAddress;
    },
    'litium'
  ),
  addOrUpdateContactPerson: defineReceiver(
    commands.addOrUpdateContactPerson,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation addOrUpdateContactPerson(
              $url: String!
              $systemId: String!
              $firstName: String!
              $lastName: String!
              $phone: String!
              $email: String!
              $saveContact: Boolean!
            ) {
              session(url: $url) {
                addOrUpdateContactPerson {
                  addOrUpdateContactPerson(
                    systemId: $systemId
                    firstName: $firstName
                    lastName: $lastName
                    phone: $phone
                    email: $email
                    saveContact: $saveContact
                  ) {
                    error
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          systemId: command.systemId || '',
          firstName: command.firstName || '',
          lastName: command.lastName || '',
          phone: command.phone || '',
          email: command.email || '',
          saveContact: command.saveContact || false,
          navCustomerContactNumber: command.navCustomerContactNumber || ''
        }
      );

      await this.cache.setItem(
        '__cart',
        result.session.addOrUpdateContactPerson.addOrUpdateContactPerson.cart
      );

      return result.session.addOrUpdateContactPerson.addOrUpdateContactPerson;
    },
    'litium'
  ),
  placeWholesaleOrder: defineReceiver(
    commands.placeWholesaleOrder,
    async function (command, data) {
      const input = { ...command.data };

      const result = await request(
        this.cache,
        query(
          gql`
            mutation placeWholesaleOrder(
              $url: String
              $input: PlaceWholesaleOrderInput!
            ) {
              session(url: $url) {
                placeWholesaleOrder {
                  placeWholesaleOrder(input: $input) {
                    error
                    orderId
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          input
        }
      );

      await this.cache.setItem(
        '__cart',
        result.session.placeWholesaleOrder.placeWholesaleOrder.cart
      );
      return result.session.placeWholesaleOrder.placeWholesaleOrder;
    },
    'litium'
  ),
  setSentPostPurchaseGtmEvent: defineReceiver(
    commands.setSentPostPurchaseGtmEvent,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation setSentPostPurchaseGtmEvent(
              $url: String
              $orderId: Guid!
            ) {
              session(url: $url) {
                setSentPostPurchaseGtmEvent {
                  setSentPostPurchaseGtmEvent(orderId: $orderId)
                }
              }
            }
          `,
          ''
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          orderId: command.orderId
        }
      );

      return result.session.setSentPostPurchaseGtmEvent
        .setSentPostPurchaseGtmEvent;
    },
    'litium'
  ),
  addBackInStockAlert: defineReceiver(
    commands.addBackInStockAlert,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation addBackInStockAlert(
              $url: String
              $email: String
              $variantId: String
            ) {
              session(url: $url) {
                addBackInStockAlert {
                  addBackInStockAlert(email: $email, variantId: $variantId)
                }
              }
            }
          `,
          ''
        ),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
          email: command.email,
          variantId: command.variantId
        }
      );

      return {
        error: result.session.addBackInStockAlert.addBackInStockAlert
      };
    },
    'litium'
  ),
  removeGroupFromCart: defineReceiver(
    commands.removeGroupFromCart,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation removeGroupFromCart($url: String!, $groupId: Guid!) {
              session(url: $url) {
                removeGroupFromCart {
                  removeGroupFromCart(groupId: $groupId) {
                    error
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).url}`,
          groupId: command.groupId
        }
      );

      await this.cache.setItem(
        '__cart',
        result.session.removeGroupFromCart.removeGroupFromCart.cart
      );

      return {
        error: result.session.removeGroupFromCart.removeGroupFromCart.error
      };
    },
    'litium'
  ),
  getProductDataFromCart: defineReceiver(
    commands.getProductDataFromCart,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            query getProductDataFromCart($url: String!, $path: String!) {
              session(url: $url) {
                page(path: $path) {
                  dataJson {
                    dataJson
                  }
                }
              }
            }
          `,
          ''
        ),
        {
          url: command.url,
          path: command.path
        }
      );

      return {
        dataJson: result.session.page.dataJson.dataJson
      };
    },
    'litium'
  ),
  updateAddonExtraInfo: defineReceiver(
    commands.updateAddonExtraInfo,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(
          gql`
            mutation updateAddonExtraInfo(
              $url: String!
              $rowId: Guid!
              $addonId: Guid!
              $extraInfo: String!
            ) {
              session(url: $url) {
                updateAddonExtraInfo {
                  updateAddonExtraInfo(
                    rowId: $rowId
                    addonId: $addonId
                    extraInfo: $extraInfo
                  ) {
                    error
                    cart {
                      ...CartFields
                    }
                  }
                }
              }
            }
          `,
          ...sales.withCartFields()
        ),
        {
          url: `${(<any>command).url}`,
          rowId: command.rowId,
          addonId: command.addonId,
          extraInfo: command.extraInfo
        }
      );

      await this.cache.setItem(
        '__cart',
        result.session.updateAddonExtraInfo.updateAddonExtraInfo.cart
      );

      return {
        error: result.session.updateAddonExtraInfo.updateAddonExtraInfo.error
      };
    },
    'litium'
  )
};
