<script>
import { defineComponent, inject } from 'vue';
import { injectionKeys as litiumKeys } from '@motillo/drapejs-litium';

export default defineComponent({
  props: {
    selectedVariant: {
      type: Object,
      default: {}
    }
  },
  setup() {
    const toLitiumMediaUrl = inject(litiumKeys.toLitiumMediaUrlKey, () => '');
    return {
      toLitiumMediaUrl
    };
  },
  computed: {
    imageUrl() {
      return this.toLitiumMediaUrl(this.selectedVariant.certificateImageId);
    },
    showCertificates() {
      return !!this.selectedVariant.certificateImageId;
    }
  }
});
</script>

<template>
  <div v-if="showCertificates" class="product-info__certificate">
    <img
      :src="imageUrl"
      loading="lazy"
      class="product-info__certificate-image"
    />
  </div>
</template>

<style>
.product-info__certificate {
  padding: 0 0 1rem 1rem;
  border-bottom: 1px solid #333333;
  margin-bottom: 1rem;
}

.product-info__certificate-image {
  max-width: 150px;
  max-height: 100px;
}
</style>
