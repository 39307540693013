<script>
import { fetchPageBuilder, navigateKey, OptionsComponent, routeKey } from '@drapejs/core';
import { search } from '@motillo/drapejs-litium';
import { searchMixin } from "./search";
import SearchViewPhone from './SearchViewPhone.vue';
import SearchViewDesktop from './SearchViewDesktop.vue';

const quickSearchProductCount = 20;

export default {
  extends: OptionsComponent,
  components: {
    SearchViewDesktop,
    SearchViewPhone,
  },
  mixins: [searchMixin],
  data: () => ({
    searchResult: null,
    width: 0,
    searchString: '',
  }),
  inject: {
    navigate: navigateKey,
    $productCategoriesRootReactive: '$productCategoriesRootReactive',
    registerModal: 'registerModal',
    unregisterModal: 'unregisterModal',
    route: routeKey
  },
  provide() {
    return {
      closeSearch: this.closeSearch,
    };
  },
  emits: ['close'],
  watch: {
    'route.href'() {
      this.closeSearch();
    },
  },
  computed: {
    productCategoriesRoot() {
      return this.$productCategoriesRootReactive;
    },
    isDesktop() {
      return this.width > 768;
    },
  },
  methods: {
    async applyFacetSuggestion(facet) {
      if (!this.productCategoriesRoot?.url) {
        return;
      }
      const query = {
        facets: `${facet.facetId}:${facet.facetValueId}`,
      };

      await this.goToProductsRootPage(query);
    },
    async performQuickSearch(searchString) {
      this.searchString = searchString;

      if (searchString === '') {
        this.searchResult = null;
        return;
      }

      if (!this.productCategoriesRoot?.id) {
        return;
      }

      const request = fetchPageBuilder(
        this.$route.protocol,
        this.$route.host,
        this.$route.pathname,
        {
          ...this.$route.query,
          search: searchString,
        },
        '',
      );

      request.take = quickSearchProductCount;
      request.categorySystemId = this.productCategoriesRoot.id;

      this.searchResult = await this.$invoke(
        search.commands.performQuickSearch,
        request,
      );
      
      this.pushSearchEvent({
        searchPhrase: this.searchString,
        productHitCount: this.searchResult?.productSearch?.hits || 0,
        pageHitCount: this.searchResult?.pageSearch?.hits || 0        
      });

      this.searchResult.searchString = searchString;
    },
    async performFullSearch(searchString) {
      if (!this.productCategoriesRoot?.url) {
        return;
      }

      const query = { ...this.$route.query };
      delete query.search;

      if (searchString) {
        query.search = searchString;
      }

      await this.goToProductsRootPage(query);
    },
    buildUrlQueryString(query) {
      const querySegments = [];
      Object.keys(query).forEach((key) => {
        const value = query[key];
        if (value) {
          querySegments.push(`${key}=${encodeURIComponent(value)}`);
        }
      });

      return querySegments.length > 0 ? `?${querySegments.join('&')}` : '';
    },
    async goToProductsRootPage(query) {
      const querySegments = [];
      Object.keys(query).forEach((key) => {
        const value = query[key];
        if (value) {
          querySegments.push(`${key}=${encodeURIComponent(value)}`);
        }
      });

      const queryString = querySegments.length > 0 ? `?${querySegments.join('&')}` : '';
      await this.navigate(`${this.productCategoriesRoot.url}${queryString}`);
    },
    setWidth() {
      this.width = window.innerWidth;
    },
    closeSearch() {
      this.$emit('close');
    },
  },
  created() {
    this.setWidth();
    window.addEventListener('resize', this.setWidth);
  },
  mounted() {
    this.registerModal('SearchView');
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setWidth);
    this.unregisterModal('SearchView');
  },
};
</script>

<template>
  <div class="search-view">
    <search-view-phone
      v-if="!isDesktop"
      :search-string="searchString"
      :search-result="searchResult"
      @perform-quick-search="performQuickSearch"
      @perform-full-search="performFullSearch"
      @apply-facet-suggestion="applyFacetSuggestion"
      @close-search="closeSearch()"
    />
    <search-view-desktop
      v-if="isDesktop"
      :search-string="searchString"
      :search-result="searchResult"
      @perform-quick-search="performQuickSearch"
      @perform-full-search="performFullSearch"
      @apply-facet-suggestion="applyFacetSuggestion"
      @close-search="closeSearch()"
    />
  </div>
</template>

<style>
</style>
