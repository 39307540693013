<script>

import { fetchPageBuilder } from '@drapejs/core';

import * as user from '../../connectors/litium/user';
import {getParameterByName} from '../utils.ts'

export default {  
  data() {
    return {
      show: false,
      isError: false
    };
  },
  computed: {
    message() {
      return !this.isError ? this.$globalTexts.mypages__new_email_validation_fail : this.$globalTexts.mypages__new_email_validation_ok;
    },
    emailUnverified() {
      return this.$user?.person?.emailUnverified;
    }
  },
  methods: {
    close() {
      this.show = false;
    },
    async validateEmail(code) {
      const requestArgs = this.buildCommandArgs({
        code: code
      });

      var result = await this.executeCommand(
        user.commands.validateEmail,
        requestArgs,
      );

      return result.error === 'NONE';
    },
    async sendEmailValidation() {
      const requestArgs = this.buildCommandArgs({
        newEmail: this.emailUnverified
      });

      const response = await this.executeCommand(
        user.commands.sendEmailVerification,
        requestArgs,
      );

      setTimeout(() => {
        this.show = false;
      }, 2000);
    },
    buildCommandArgs(formArgs) {
      return {
        ...formArgs,
        ...fetchPageBuilder(
          window.location.protocol,
          window.location.hostname,
          window.location.pathname,
          {},
          '',
        ),
      };
    },
    async executeCommand(command, args) {
      try {
        const response = await this.$invoke(command, args);
        return response;
      } catch (err) {
        return [];
      }
    }
  },
  async mounted() {
    const action = getParameterByName('action');    

    if(action === "validate-email") {
      const code = getParameterByName('code');
      var result = await this.validateEmail(code);
      this.isError = !result;
      this.show = true;

      if(!this.isError) {
        setTimeout(() => {
          this.show = false;
        }, 10000);
      }
    }
  },
};
</script>

<template>
  <div class="email-validation-message" v-if="show">
    <div class="email-validation-message__container">
      <img v-if="isError"
        class="email-validation-message__icon"
        src="../static/icons/error.svg"
      />
      <img v-if="!isError"
        class="email-validation-message__icon"
        src="../static/icons/success.svg"
      />
      <span class="email-validation-message__text" v-html="message" />
      <a href="" @click.prevent="sendEmailValidation" class="email-validation-message__link" v-if="isError && emailUnverified">{{ $globalTexts.mypages__resend_validation }}</a>

      <img
          class="email-validation-message__close"
          src="../static/icons/cancel_white_icon.svg"
          @click="close()"
        />
    </div>    
  </div>
</template>

<style>
.email-validation-message {
  background-color: #333333;  
  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; 
}

.email-validation-message__container {
  width: 100%;
}

.email-validation-message__icon {
  width: 15px;
  height: 12px;
  line-height: 22px;
  vertical-align: middle;
  margin: 0 4px;
}

.email-validation-message__text {
  color: #ffffff;
  font-family: Mulish;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.email-validation-message__link {
  text-decoration: underline;
  color: #ffffff;
  font-family: Mulish;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 0 4px;
}

.email-validation-message__close {
  width: 15px;
  height: 12px;
  line-height: 22px;
  vertical-align: middle;
  margin: 6px 14px;
  float: right;
  cursor: pointer;
}
</style>