<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    name: {
      type: String,
    },
    modelValue: {
      type: [Object, String, Number, Boolean],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue", "change"],
};
</script>

<template>
  <label class="radio-button">
    <span
      class="radio-button__label"
      :class="this.isError && 'radio-button__label-error'"
      v-html="text"
    />
    <input
      class="radio-button__input"
      type="radio"
      :checked="modelValue"
      :name="name"
      @input="$emit('update:modelValue', $event.target.checked)"
      @change="$emit('change', $event.target.checked)"
      :disabled="disabled"
    />
    <span class="radio-button__checkmark" :class="this.isError && 'radio-button__button-error'">
      <span class="radio-button__checkmark-icon"></span>
    </span>
  </label>
  <div class="radio-button__description" v-if="description">
    {{ description }}
  </div>
</template>

<style>
.radio-button {
  display: flex;
  position: relative;
  padding-left: 1.75rem;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  margin: 0;
}

.radio-button__label {
  font-size: 14px;
  white-space: nowrap;
  color: #1a1a1a;
  font-weight: 700;
  line-height: 19px;
}

.radio-button__input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.radio-button:has(.radio-button__input[disabled]) > .radio-button__label{
  color: #bcbfc7;
}

.radio-button__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: rgba(184, 204, 214, 0.25);
  border-radius: 50%;
  box-shadow: inset 1px 2px 2px rgba(109, 109, 142, 0.15);
}

.radio-button__checkmark-icon {
  display: none;
  background-color: white;
  height: 6px;
  width: 6px;
  padding: 2px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  left: 6px;
}

.radio-button__description {
  font-size: 13px;
  white-space: nowrap;
  color: #797b80;
  padding: 0.5rem 0 0 1.75rem;
}

.radio-button__input:checked ~ .radio-button__checkmark {
  background-color: #333333;
}

.radio-button__input:checked
  ~ .radio-button__checkmark
  .radio-button__checkmark-icon {
  display: inline;
}

.radio-button__label-error{
  color: var(--color-feedback-error);
}

.radio-button__input:checked ~ .radio-button__button-error {
  background-color: var(--color-feedback-error);
}
</style>
