<script>
import { defineComponent, ref } from 'vue';

import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useContext from '@/composables/useContext';
import useUser from '@/composables/useUser';
import ModalContainer from '@/components/ModalContainer.vue';
import Dropdown from '../../forms/Dropdown.vue';
import {
  createPrintessInfo,
  fetchDesignsNav,
  deleteDesignNav,
  saveDesignNav
} from '../../../helpers/printess.js';

export default defineComponent({
  components: {
    Dropdown,
    ModalContainer
  },
  props: {
    current: null
  },
  const: {
    newDesign: null
  },
  emits: ['setPrintessInfo'],
  setup() {
    const { websiteText } = useWebsiteTexts();
    const printessOpen = ref(false);
    const titlePromptOpen = ref(false);
    const selectedDesign = ref(null);
    const { channel } = useContext();
    const { organization } = useUser();
    return {
      websiteText,
      printessOpen,
      titlePromptOpen,
      selectedDesign,
      channel,
      organization
    };
  },
  data() {
    return {
      designs: [],
      defaultDesign: {
        Name: this.websiteText('giftcard__addon_printess_choose_design').value
      },
      loadingDesigns: false,
      titleInput: null,
      openNewDesign: true,
      organisationNumber: this.organization.navCustomerNumber
    };
  },
  computed: {
    dropdownOptions() {
      if (this.designs.length === 0) return [];
      return [this.defaultDesign].concat(this.designs);
    }
  },
  methods: {
    openPrintess(openNewDesign) {
      this.openNewDesign = openNewDesign;
      if (openNewDesign) {
        this.resetSelection();
      }
      this.printessOpen = true;
    },
    closePrintess() {
      window.removeEventListener('message', this.handleMessageEvent);
      this.printessOpen = false;
    },
    loadPrintess() {
      window.addEventListener('message', this.handleMessageEvent);
      this.$refs.printessIframe?.contentWindow.postMessage(
        {
          cmd: 'attach',
          properties: {
            templateName: this.openNewDesign
              ? 'Vinga-GiftCard'
              : this.selectedDesign?.ExternalId,
            templateVersion: 'draft',
            formFields: [
              {
                name: 'MATRIX_PRODUCT',
                value: this.channel.language
              },
              {
                name: 'value',
                value: this.current.fields?.GiftcardType
              },
              {
                name: 'ARTICLE_NUMBER',
                value: this.current.articleNumber
              }
            ],
            token: process.env.PRINTESS_TOKEN
          }
        },
        '*'
      );
    },
    resetSelection() {
      this.selectedDesign = null;
      this.titleInput = '';
    },
    handleMessageEvent(event) {
      switch (event.data.cmd) {
        case 'back':
          this.closePrintess();
          break;
        case 'basket':
          this.newDesign = event.data;
          this.closePrintess();
          this.openTitlePrompt();
          break;
      }
    },
    openTitlePrompt() {
      this.titlePromptOpen = true;
    },
    closeTitlePrompt() {
      this.titlePromptOpen = false;
    },
    setSelectedDesign(design) {
      if (!!design?.ExternalId) {
        this.selectedDesign = design;
        this.titleInput = design.Name;
      } else {
        this.resetSelection();
      }
      this.setPrintessInfo();
    },
    showError() {
      return !this.loadingDesigns && this.designs.length === 0;
    },
    async fetchDesigns() {
      this.loadingDesigns = true;
      try {
        this.designs = await fetchDesignsNav(
          this.channel,
          this.organisationNumber
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDesigns = false;
      }
    },
    async deleteDesign(externalId) {
      try {
        await deleteDesignNav(
          this.channel,
          this.organisationNumber,
          externalId
        );
        this.designs = this.designs.filter((d) => d.ExternalId != externalId);
        this.resetSelection();
      } catch (error) {
        console.log(error);
      }
    },
    async storeDesign(action) {
      try {
        if (this.titleInput.length > 30) {
          alert(
            this.websiteText(
              'giftcard__addon_printess_modal_input_error_length'
            ).value
          );
        } else {
          const newDesign = await saveDesignNav(
            this.channel,
            this.organisationNumber,
            this.titleInput,
            action,
            this.current.articleNumber,
            this.newDesign
          );
          this.designs.push(newDesign);
          this.setSelectedDesign(newDesign);
          this.closeTitlePrompt();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async saveDesign() {
      if (!!this.selectedDesign) {
        this.designs = this.designs.filter(
          (d) => d.Name !== this.selectedDesign.Name
        );
        await this.storeDesign('Overwrite');
      } else {
        await this.storeDesign('Save');
      }
    },
    async saveNewDesign() {
      await this.storeDesign('Save');
    },
    setPrintessInfo() {
      var info = createPrintessInfo(this.selectedDesign);
      this.$emit('setPrintessInfo', info);
    }
  },
  mounted() {
    this.fetchDesigns();
    if (window.visualViewport) {
      window.visualViewport.addEventListener('scroll', () => {
        this.$refs.printessIframe?.contentWindow.postMessage(
          {
            cmd: 'viewportScroll',
            height: window.visualViewport.height,
            offsetTop: window.visualViewport.offsetTop
          },
          '*'
        );
      });
    }
  }
});
</script>

<template>
  <div class="addon-printess-container">
    <div v-if="designs.length > 0">
      <dropdown
        :options="dropdownOptions"
        class="add-printess__design-dropdown"
        :key="designs"
        :label="
          loadingDesigns
            ? websiteText('giftcard__addon_printess_loading_designs').value
            : ''
        "
        :error-message="
          showError()
            ? websiteText('giftcard__addon_printess_no_designs').value
            : ''
        "
        @change="setSelectedDesign"
        ><template v-slot:selected="slotProps">
          <div class="channel-select__selected">
            {{ selectedDesign?.Name || dropdownOptions[0]?.Name }}
          </div>
        </template>
        <template v-slot:option="slotProps">
          <div class="channel-select__option">
            <div>{{ slotProps.option?.Name }}</div>
          </div>
        </template>
      </dropdown>
    </div>
    <div class="addon-printess__open-printess-buttons">
      <button
        class="addon-printess__open-printess-button secondary-button"
        @click="openPrintess(true)"
      >
        {{ $globalTexts.giftcard__addon_printess_new_designs }}
      </button>
      <button
        class="addon-printess__open-printess-button secondary-button"
        @click="openPrintess(false)"
        :disabled="!selectedDesign"
      >
        {{ $globalTexts.giftcard__addon_printess_edit_designs }}
      </button>
    </div>
    <div v-if="!!selectedDesign">
      <p
        class="addon-printess__chosen-design-text addon-printess__chosen-design-title"
      >
        {{ $globalTexts.giftcard__addon_printess_chosen_design_title }}
      </p>
      <p class="addon-printess__chosen-design-text">
        "{{ selectedDesign.Name }}"
      </p>
      <div class="addon-printess__preview">
        <img
          class="addon-printess__thumbnail"
          :src="selectedDesign.ThumbnailURL"
        />
        <button
          @click="deleteDesign(selectedDesign.ExternalId)"
          class="addon-printess__remove-design-button"
        >
          <img src="../../../static/icons/trash_icon.svg" alt="trash icon" />
        </button>
      </div>
    </div>
  </div>
  <div v-if="printessOpen" id="save-modal__overlay">
    <iframe
      v-if="printessOpen"
      ref="printessIframe"
      class="printess-iframe"
      allow="clipboard-read; clipboard-write"
      src="https://editor.printess.com/printess-editor/embed.html"
      @load="loadPrintess()"
    ></iframe>
  </div>
  <modal-container
    v-if="titlePromptOpen"
    @close-modal="closeTitlePrompt()"
    position="center"
    :show-exit-button="true"
    :show-back-button="false"
    :show-left-exit-button="false"
  >
    <p class="save-modal__title">
      {{ $globalTexts.giftcard__addon_printess_modal_title }}
    </p>
    <div class="save-modal__main">
      <div class="save-modal__content">
        <p class="save-modal__content-text">
          {{ $globalTexts.giftcard__addon_printess_modal_content }}
        </p>
        <input
          v-if="titlePromptOpen"
          class="save-modal__title-input"
          type="text"
          :placeholder="
            websiteText('giftcard__addon_printess_title_input').value
          "
          v-model="titleInput"
        />
      </div>
      <div class="save-modal__buttons">
        <button
          class="save-modal__buttons-save primary-button"
          @click="saveDesign()"
        >
          {{ $globalTexts.giftcard__addon_printess_modal_save }}
        </button>
        <button
          class="save-modal__buttons-save-new secondary-button"
          @click="saveNewDesign()"
          v-if="!!selectedDesign"
        >
          {{ $globalTexts.giftcard__addon_printess_modal_save_new }}
        </button>
      </div>
    </div>
  </modal-container>
</template>

<style>
.addon-printess-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 2rem;
}

.addon-printess__save-token-button {
  text-decoration: underline;
  height: 40px;
  margin-top: 1rem;
}

.addon-printess__save-token-button:not(:hover) {
  background: none;
}

.addon-printess__open-printess-buttons {
  justify-content: space-between;
  display: flex;
  margin-top: 1rem;
}

.addon-printess__chosen-design-text {
  margin: 0px;
}

.addon-printess__chosen-design-title {
  font-weight: 800;
  margin-top: 1rem;
}

.addon-printess__open-printess-button {
  width: 150px;
  padding: 0px !important;
}

.addon-printess__save-token-input {
  margin-top: 1rem;
}

.addon-printess__title-input {
  top: 50%;
  left: 50%;
  width: 300px !important;
  margin-left: -150px;
}

#save-modal__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.printess-iframe {
  width: 90vw;
  height: 90vh;
  border: none;
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -45vw;
  margin-top: -45vh;
  z-index: 1000;
}

.modal-container--center {
  max-width: 655px !important;
}

.save-modal__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 19px;
  margin: 1rem 0 0 1rem;
}

.save-modal__main {
  display: flex;
  padding: 1rem;
}

.save-modal__content {
  border: 1px solid var(--color-neutrals-50);
  border-radius: 3px;
  padding: 1rem;
}
.save-modal__content-text {
  width: 275px;
  margin: 0;
}
.save-modal__title-input {
  margin: 20px 0px 0px;
}
.save-modal__buttons {
  padding: 0px 2rem;
}
.save-modal__buttons-save {
  width: 250px;
}
.save-modal__buttons-save-new {
  width: 250px;
  margin-top: 1rem;
}

.addon-printess__preview {
  display: flex;
  align-items: start;
  margin-top: 1rem;
  justify-content: space-between;
}

.addon-printess__thumbnail {
  max-height: 250px;
  max-width: 250px;
}

.addon-printess__remove-design-button {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .modal-container--center {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 655px) {
  .save-modal__main {
    display: block;
  }
  .save-modal__buttons-save {
    margin-top: 1rem;
  }
  .save-modal__content {
    min-width: 300px;
  }
  .save-modal__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
