<script>
export default {
  props: {
    isFlipped: Boolean,
    isLink: Boolean
  },
  computed: {
    arrowStyle(){
      return this.isFlipped ? {
        transform: 'rotate(180deg)'
      } : {};
    },
    arrowClass(){
      return this.isLink ? 'dropdown-arrow--link' : '';
    }
  }
}
</script>

<template>
    <svg
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="dropdown-arrow"
    :class="arrowClass" :style="arrowStyle"
    >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M18.1002686,24.5 L34.3270399,7.98697064 C35.22432,7.07619685 35.22432,5.59560562 34.3270399,4.68483183 C33.4320547,3.77172272 31.9794247,3.77172272 31.0821446,4.68483183 L13.9810415,22.0876171 C12.6729862,23.4210834 12.6729862,25.5789166 13.9810415,26.9123829 L31.0821446,44.3151682 C31.9794247,45.2282773 33.4320547,45.2282773 34.3270399,44.3151682 C35.22432,43.4043944 35.22432,41.9238031 34.3270399,41.0130294 L18.1002686,24.5 Z"
        transform="translate(24.000000, 24.500000) rotate(-90.000000) translate(-24.000000, -24.500000) "
        class="dropdown-arrow__content"
      ></path>
    </g>
  </svg>

</template>

<style>
.dropdown-arrow {
    position: relative;
    width: 13px;
    height: 13px;
}

.dropdown-arrow__content {
    fill: var(--color-border-50);
}

.dropdown-arrow--link .dropdown-arrow__content {
    fill: var(--color-border-50);
}
</style>