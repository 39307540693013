<script>
export default {
  emits: ["close-modal", "navigate-back"],
  props: {
    position: {
      type: String,
      default: "",
    },
    showExitButton: {
      type: Boolean,
      default: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showLeftExitButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nossr() {
      return !this.$ssrContext;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    navigateBackward() {
      this.$emit("navigate-back");
    },
  },
};
</script>

<template>
  <div v-if="nossr" :class="['modal-container__wrapper', `modal-container__wrapper--${this.position}`]">
    <div class="modal-background-cover" @click="closeModal()"></div>
    <div :class="['modal-container', `modal-container--${this.position}`]">
      <div class="modal-container__options-wrapper">
        <img
          v-if="showLeftExitButton"
          class="modal-container__left-exit-button"
          src="../static/icons/cancel_icon.svg"
          @click="closeModal()"
        />
        <img
          v-if="showBackButton"
          class="modal-container__back-button"
          @click="navigateBackward()"
          src="../static/icons/arrow_left_icon.svg"
        />
        <img
          v-if="showExitButton"
          class="modal-container__exit-button"
          src="../static/icons/cancel_icon.svg"
          @click="closeModal()"
        />
      </div>
      <slot />
    </div>
  </div>
</template>

<style>
.modal-background-cover {
  display: none;
  z-index: 100;
}

.modal-container {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: auto;
  background: white;
  width: 100%;
}

.modal-container__options-wrapper {
  position: relative;
  z-index: 10;
}

.modal-container__left-exit-button {
  cursor: pointer;
  padding-top: 1rem;
  position: absolute;
  left: 1rem;
}

.modal-container__back-button {
  cursor: pointer;
  padding-top: 1rem;
  position: absolute;
  left: 1rem;
  display: flex;
  align-items: center;
  z-index: 10;
}

.modal-container__exit-button {
  cursor: pointer;
  top: 1rem;
  position: absolute;
  right: 1rem;
}

@media (--phone) {
  .modal-container {
    left: 0;
  }
}

@media (min-width: 768px) {
  .modal-container {
    max-width: 400px;
    overflow: initial;
  }

  .modal-container__wrapper {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .modal-background-cover {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .modal-container__wrapper--center{
    display: flex;
  }

  .modal-container--left {
    left: 0;
  }

  .modal-container--right {
    right: 0;
  }

  .modal-container--center {
    position: relative;
    margin: auto;
    padding: 0;
    background-color: #fff;
    overflow-y: auto;
    max-height: calc(100vh - 1rem);
    min-height: 80px;
  }
}
</style>
