<script>
import SearchViewInput from "./SearchViewInput.vue";
import SearchViewDesktopResults from "./SearchViewDesktopResults.vue";

export default {
  components: {
    SearchViewInput,
    SearchViewDesktopResults,
  },
  props: {
    searchResult: {
      type: Object,
    },
    searchString: {
      type: String,
    },
  },
  methods: {
    closeIfClickIsOutsideOfSearchOverlay(e) {
      if (!this.$refs.searchViewDesktop.isSameNode(e.target)) return;
      this.$emit("close-search");
    },
  },
  mounted() {
    window.addEventListener("click", this.closeIfClickIsOutsideOfSearchOverlay);
  },
  beforeUnmount() {
    window.removeEventListener(
      "click",
      this.closeIfClickIsOutsideOfSearchOverlay
    );
  },
};
</script>

<template>
  <div class="search-view-desktop" ref="searchViewDesktop">
    <div class="search-view-desktop__content-wrapper">
      <div class="search-view-desktop__content">
        <search-view-input
          @update:searchPhrase="$emit('performQuickSearch', $event)"
          @fullSearch="$emit('performFullSearch', $event)"
          :search-string="searchString"
        />
        <search-view-desktop-results
          :search-result="searchResult"
          @applyFacetSuggestion="$emit('applyFacetSuggestion', $event)"
          @fullSearch="$emit('performFullSearch', $event)"
        />
      </div>
    </div>
  </div>
</template>

<style>
.search-view-desktop {
  z-index: 10000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
}

.search-view-desktop__content-wrapper {
  z-index: 10000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  max-height: calc(100vh - 200px);
  box-shadow: 4px 12px 14px -8px rgba(184, 204, 214, 0.3);
}

.search-view-desktop__content {
  margin: 1rem auto 1rem auto;
  overflow: hidden;
  max-height: calc(100vh - 250px);
}
</style>