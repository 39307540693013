<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    label: {
      type: [String, null],
      default: "",
    },
  },
};
</script>

<template>
  <div class="site-footer-expand-label">
    {{ label }}
    <svg
      v-if="!active"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.3559 8.05853L11.7772 16.4458C11.8405 16.5173 11.9315 16.5583 12.027 16.5583C12.1226 16.5583 12.2135 16.5173 12.2768 16.4458L19.6988 8.05853"
        stroke="#282828"
      />
    </svg>
    <svg
      v-else
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6441 16.5613L12.2228 8.17404C12.1595 8.10247 12.0685 8.06149 11.973 8.06149C11.8774 8.06149 11.7865 8.10247 11.7232 8.17404L4.30119 16.5613"
        stroke="#9D9FA6"
      />
    </svg>
  </div>
</template>

<style>
.site-footer-expand-label {
  padding: 10px 0 10px 0;
  font-size: 16px;
  font-weight: bold;
  height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
</style>