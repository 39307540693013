<template>
  <svg
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="site-header-menu-icon"
  >
    <rect y="14.5" width="23" height="1" fill="black" />
    <rect y="7.5" width="23" height="1" fill="black" />
    <rect y="0.5" width="23" height="1" fill="black" />
  </svg>
</template>

<style>
.site-header-menu-icon {
    padding: 10px;
    width: 40px;
    height: 40px;
}

.site-header-menu-icon rect {
    transition: fill linear 0.2s;
}

.site-header--foreground-white .site-header-menu-icon rect {
    fill: var(--color-neutrals-00);
}
</style>