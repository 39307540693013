<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  inject: ["closeNavigation"],
  computed: {
    subCategories() {
      return this.category.subCategories;
    },
  },
});
</script>

<template>
  <div class="navigation-desktop-all-products">
    <div class="navigation-desktop-products-list">
      <div class="navigation-desktop-all-products__all navigation-desktop-category">
        <router-link :to="category.url" @click="closeNavigation()">{{
          $globalTexts.global__label_products_menu_see_all
        }}</router-link>
      </div>
      <div class="navigation-desktop-category" v-for="sub in subCategories" :key="sub.id">
        <router-link :to="sub.url" @click="closeNavigation()">{{
          sub.name
        }}</router-link>
      </div>
    </div>
  </div>
</template>

<style>
.navigation-desktop-all-products {
  font-size: 12px;
  display: flex;
}

.navigation-desktop-all-products .navigation-desktop-category {
  list-style-type: none;
  margin: 0 0 15px;
  text-indent: 0;
  margin: 0 0 14px;
  min-width: 200px;
  padding-right: 50px;
}

.navigation-desktop-all-products a {
  text-decoration: none;
  color: inherit;
}

.navigation-desktop-all-products__all {
  font-size: 14px;
  font-weight: bold;
  color: var(--color-neutrals-60);
}

li.navigation-desktop-all-products__all {
  margin-bottom: 28px;
}

.navigation-desktop-all-products .navigation-desktop-products-list {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  white-space: nowrap;
}

@media (--tablet) {
  .navigation-desktop-all-products .navigation-desktop-products-list {
    max-height: 450px;
  }
}

@media (--desktop) {
  .navigation-desktop-all-products .navigation-desktop-products-list {
    max-height: 320px;
  }
}
</style>