<script>
import TemplateMenu from './TemplateMenu.vue';
import ImageUpload from './ImageUpload.vue';
// Maxlength values on the textareas
const titleCharacterLimit = 75;
const messageCharacterLimit = 150;

export default {
  components: { TemplateMenu, ImageUpload },
  inject: {
    $giftCardStateReactive: '$giftCardStateReactive',
    $textSuggestionsReactive: '$textSuggestionsReactive',
    changeTextContent: 'changeTextContent',
    $imageLimits: '$imageLimits',
  },
  emits: ['setTextContent', 'setLogoType', 'deleteLogoType'],
  data() {
    return {
      textContent: { title: '', message: '' },
      showTextTemplates: false,
    };
  },
  computed: {
    hasTextSuggestions() {
      return this.textSuggestions.length > 0;
    },
    textSuggestions() {
      const suggestions = [];

      if (this.$textSuggestionsReactive?.titleSuggestions?.length > 0) {
        suggestions.push({
          value: 'title',
          name: this.$globalTexts.giftcard__titles || 'giftcard__titles',
          options: this.$textSuggestionsReactive.titleSuggestions,
        });
      }

      if (this.$textSuggestionsReactive?.messageSuggestions?.length > 0) {
        suggestions.push({
          value: 'message',
          name: this.$globalTexts.giftcard__messages || 'giftcard__messages',
          options: this.$textSuggestionsReactive.messageSuggestions,
        });
      }

      return suggestions;
    },
    logoTypeId() {
      return this.$giftCardStateReactive?.giftCardLogoTypeSystemId || '';
    },
    stopScrollWhenTemplateIsOpen() {
      return this.showTextTemplates ? 'hidden' : 'auto';
    },
    titleWithTotal() {
      return `${this.titleInputCount}/${titleCharacterLimit}`;
    },
    messageWithTotal() {
      return `${this.messageInputCount}/${messageCharacterLimit}`;
    },
    aboveTitleLimit() {
      return this.titleInputCount > titleCharacterLimit ? '' : undefined;
    },
    aboveMessageLimit() {
      return this.messageInputCount > messageCharacterLimit ? '' : undefined;
    },
    showSuggestions() {
      return this.textSuggestions.length > 0;
    },
    titleInputCount() {
      return this.textContent.title.length;
    },
    messageInputCount() {
      return this.textContent.message.length;
    },
  },
  methods: {
    setTextTemplate(template) {
      this.textContent = { ...this.textContent, ...template };
      this.setTextContent(this.textContent);
    },
    openTextTemplates() {
      this.showTextTemplates = true;
    },
    closeTextTemplates() {
      this.showTextTemplates = false;
    },
    updateTextContent(e) {
      if (e.target.name.toLowerCase().includes('title')) {
        this.textContent.title = e.target.value;
      } else {
        this.textContent.message = e.target.value;
      }
      this.setTextContent(this.textContent);
    },
    setTextContent(textContent) {
      this.changeTextContent(textContent);
    },
  },
  mounted() {
    this.textContent = {
      title: this.$giftCardStateReactive?.giftCardTitle || '',
      message: this.$giftCardStateReactive?.giftCardDescription || '',
    };
  },
};
</script>

<template>
  <div class="inside-menu__wrapper">
    <div class="inside-menu">
      <h5 class="inside-menu__heading">
        {{ $globalTexts.giftcard__inside_title || 'giftcard__inside_title' }}
      </h5>
      <p class="inside-menu__text" v-if="hasTextSuggestions">
        {{
          $globalTexts.giftcard__inside_question || 'giftcard__inside_question'
        }}
        <br />
        {{
          $globalTexts.giftcard__inside_message || 'giftcard__inside_message'
        }}
      </p>
      <template v-if="showSuggestions">
        <button
          class="inside-menu__text inside-menu__text-button"
          @click="openTextTemplates"
        >
          {{
            $globalTexts.giftcard__message_suggestions ||
            'giftcard__message_suggestions'
          }}
        </button>
        <div class="inside-menu__text-templates" v-show="showTextTemplates">
          <template-menu
            :templates="textSuggestions"
            :currentTemplates="textContent"
            @setText="setTextTemplate"
            @close="closeTextTemplates"
          />
        </div>
      </template>
      <label :warning="aboveTitleLimit" class="inside-menu__textarea-label">
        {{ $globalTexts.giftcard__text_title || 'giftcard__text_title' }}
        <textarea
          @input="updateTextContent"
          v-model="textContent.title"
          name="inside-title"
          :placeholder="
            $globalTexts.giftcard__title_placeholder ||
            'giftcard__title_placeholder'
          "
          cols="30"
          rows="3"
          maxlength="75"
        >
        </textarea>
        <div class="inside-menu__character-count">
          {{ titleWithTotal }}
        </div>
      </label>
      <label :warning="aboveMessageLimit" class="inside-menu__textarea-label">
        {{
          $globalTexts.giftcard__personal_message ||
          'giftcard__personal_message'
        }}
        <textarea
          @input="updateTextContent"
          v-model="textContent.message"
          name="inside-message"
          :placeholder="
            $globalTexts.giftcard__message_placeholder ||
            'giftcard__message_placeholder'
          "
          cols="30"
          rows="8"
          maxlength="150"
        ></textarea>
        <div class="inside-menu__character-count">
          {{ messageWithTotal }}
        </div>
      </label>
      <ImageUpload
        class="inside-menu__upload-label"
        @setImage="$emit('setLogoType', $event)"
        @deleteImage="$emit('deleteLogoType', $event)"
        :imageId="logoTypeId"
        :sizeLimitInMB="$imageLimits.insideLogotypeSizeLimit"
        :recommendation="
          $globalTexts.giftcard__logo_size_recommendation ||
          'giftcard__logo_size_recommendation'
        "
        :upload="
          $globalTexts.giftcard__upload_logotype_text ||
          'giftcard__upload_logotype_text'
        "
      />
    </div>
  </div>
</template>

<style>
.inside-menu__wrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: v-bind(stopScrollWhenTemplateIsOpen);
  margin-right: -1rem;
  position: relative;
}

.inside-menu {
  /* add padding only when scrollbar is not present */
  padding-right: calc(100% - 280px);
}

.inside-menu__heading {
  line-height: 35px;
  font-size: 20px;
  color: var(--color-text-dark);
  margin: 0 0 16px;
  font-weight: normal;
}

.inside-menu__text {
  line-height: 24px;
  font-size: 16px;
  padding: 0;
}

.inside-menu__text-button {
  text-decoration: underline;
  display: block;
}

.inside-menu__text-templates {
  position: absolute;
  inset: 0 1rem 0 0;
  z-index: 1;
  background: var(--color-white);
}

.inside-menu__textarea-label {
  max-width: 300px;
  margin-top: 26px;
  display: block;
}

.inside-menu__textarea-label textarea {
  border-radius: 2px;
  resize: vertical;
}

.inside-menu__character-count {
  text-align: right;
  color: var(--color-border-50);
  font-size: 12px;
  line-height: 14px;
}

.inside-menu__textarea-label[warning],
.inside-menu__textarea-label[warning] .inside-menu__character-count {
  color: red;
}

.inside-menu__textarea-label[warning] textarea {
  border-color: red;
}

.inside-menu__upload-label {
  margin-top: 26px;
}

@media (--phoneAndTablet) {
  .inside-menu__wrapper {
    padding: 1rem;
    margin-right: 0;
  }

  .inside-menu {
    padding-right: 0;
  }

  .inside-menu__text-templates {
    inset: 0;
  }

  .inside-menu__textarea-label {
    max-width: 500px;
  }
}
</style>
