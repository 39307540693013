<script>
export default {
}
</script>

<template>
  <img
    class="menu-arrow"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEnSURBVHgBxdZLioNAFAXQVxUXYM9aRy5BcOLQJWQHsXdmr0eE7hUkAUFnEZwIIqYqhCAOEut9kgt+KNB7pAbPHRCSZZn/bVLXdQfIaEAmTdOo7/vLOI7HJEkOgAwaMAzD8qsLLGIHyLRtOwRBcFZK7e9L+zAMT03T/Lu8Bw2wMWV/VAQJwIEgA6gIFgAFwQbAIlgBGAQ7wBUhAnBBiAG2IkQBWxDKnuxU67ouBsForQ8GkS+W8qqqfpWdanaiwQcyTdOXXk21d8e/bUEcx5HneREIZp7n3FweI9tsyU9ZloWCN+T+r1Csy+29OOBZuTjgVbkoYEu5GGBruQjApZwd4FrOCsCUswGw5SwASjkZQC0nAdZTFFN+ew6QWU5RbDk5doqawwdCrlTF5WuA9NVXAAAAAElFTkSuQmCC"
  />
</template>

<style>
.menu-arrow {
  display: inline-block;
  width: 40px;
  height: auto;
  padding: 12px;
}
</style>