export const searchMixin = {
  methods: {
    pushSearchEvent({ searchPhrase, productHitCount, pageHitCount }) {
      if (typeof dataLayer === 'undefined') {
        return;
      }

      if (dataLayer.find((e) => e.event === 'search' && e.search_term === searchPhrase)) {
        return;
      }

      dataLayer.push({
        event: 'search',
        search_term: searchPhrase,
        search_hit_count_products: productHitCount || 0,
        search_hit_count_pages: pageHitCount || 0,
      });
    },
  },
};
