<script>
import { defineComponent } from "vue";
import NavigationPhone from "./NavigationPhone.vue";
import NavigationPhoneArrowIcon from './NavigationPhoneArrowIcon.vue';

export default defineComponent({
    components: {
        NavigationPhone,
        NavigationPhoneArrowIcon
    },
    inject: [
        "closeNavigation"
    ],
    props: {
        label: {
            type: String,
            required: true,
        },
        to: {
            type: String,
            required: true,
        }
    },
});
</script>

<template>
    <router-link
        :to="to"
        class="navigation-phone-menu-link-item"
        @click="closeNavigation()"
        >
        {{label}}
    </router-link>
</template>

<style>
.navigation-phone-menu-link-item {
    display: flex;
    justify-content: space-between;
    height: 42px;
    align-items: center;
    font-weight: bold;
    margin-left: 14px;
    font-size: 16px;
}
</style>