<script>
import { inject } from 'vue';
import { OptionsComponent } from '@drapejs/core';
import ModalContainer from './ModalContainer.vue';
import CartGroupItem from './CartGroupItem.vue';
import CartGiftCardItem from './CartGiftCardItem.vue';
import { getItemKey } from '@drapejs/core';
import * as vingaSales from '../../connectors/litium/sales.ts';
import ProductConfigurator from '@/components/ProductConfigurator';
import { useCartFunctions } from '@/helpers/cart-actions';

export default {
  extends: OptionsComponent,
  components: {
    ModalContainer,
    CartGroupItem,
    ProductConfigurator,
    CartGiftCardItem
  },
  data() {
    return {
      error: '',
      isQuickCartOpened: false,
      rowGroupForEdit: '',
      variantData: [],
      selectedVariant: null,
      decorationData: [],
      addonData: [],
      isGiftCard: false,
      giftCardFreightPrice: {},
      configuratorState: null,
      editedBaseProductname: '',
      showLeaveConfigConfirmation: false
    };
  },
  setup() {
    const { removeGroupFromCart } = useCartFunctions();
    const getItem = inject(getItemKey);
    return { removeGroupFromCart, getItem };
  },
  inject: ['registerModal', 'unregisterModal'],
  computed: {
    titleText() {
      return this.$globalTexts.cart__title;
    },
    emptyCartText() {
      return this.$globalTexts.global__label_empty_cart;
    },
    toCheckout() {
      return this.$globalTexts.cart__to_checkout;
    },
    rows() {
      return this.$cart?.rows || [];
    },
    cartIsEmpty() {
      return this.$cart?.isEmpty || !this.rows.length || false;
    },
    totalLabel() {
      return this.$globalTexts.cart__total;
    },
    total() {
      return this.$formatPrice(
        this.$cart?.totalOrderRow + this.$cart?.totalFee || 0
      );
    },
    checkoutUrl() {
      return this.$globalFields?.CheckoutPageUrl || '';
    },
    gceRows() {
      return this.rows.filter((r) => this.isGiftCardRow(r));
    },
    groupedRows() {
      const groups = [];
      this.rows
        .filter((r) => !this.isGiftCardRow(r))
        .forEach((r) => {
          const existingGroup = groups.find(
            (g) =>
              g?.groupId === r.rowGroupId &&
              g?.baseProductId === r.baseProductId
          );

          if (existingGroup) {
            const matchingVariant = existingGroup.rows.find(
              (existingRow) => existingRow.articleNumber === r.articleNumber
            );

            if (matchingVariant) {
              matchingVariant.quantity += r.quantity;
            } else {
              existingGroup.rows.push(r);
            }
          } else {
            const clonedRow = {};
            groups.push({
              groupId: r.rowGroupId,
              baseProductId: r.baseProductId,
              rows: [Object.assign(clonedRow, r)],
              baseProductName: r.baseProductName,
              baseProductUrl: r.url,
              variantSystemId: r.productId,
              rowId: r.id,
              articleNumber: r.articleNumber
            });
          }
        });

      return groups;
    }
  },
  watch: {
    '$route.pathname': function () {
      this.closeCart();
    }
  },
  methods: {
    pushVisitCartEvent() {
      if (typeof dataLayer === 'undefined') {
        return;
      }

      const items = this.rows?.map((i) => ({
        item_id: i.articleNumber,
        item_name: i.googleAnalyticsName || i.productName || i.variantName
      }));
      const dataToPush = {
        event: 'view_cart',
        currency: this.$cart?.currency.id,
        value: this.$cart?.grandTotal,
        items
      };

      dataLayer.push(dataToPush);
    },
    isGiftCardRow(row) {
      return !!row.giftCardInput;
    },
    async openEdit(groupData) {
      try {
        this.error = '';
        this.requestPending = true;
        const result = await this.$invoke(
          vingaSales.commands.getProductDataFromCart,
          {
            url: window.location.href,
            path: groupData.url
          }
        );

        const { product } = result.dataJson;
        const allVars =
          product?.otherVariants?.filter?.((o) => o.systemId) || [];
        if (product?.selectedVariant?.articleNumber) {
          allVars.add(product.selectedVariant);
          this.selectedVariant = product.selectedVariant;
        } else {
          this.selectedVariant = product?.otherVariants?.find(
            (v) => v.articleNumber === groupData.articleNumber
          );
        }

        this.variantData = allVars;
        this.decorationData = product.fields.Decorations || [];
        this.addonData = product.fields.Addons || [];
        this.editedBaseProductname = groupData.name;

        this.isGiftCard = product?.isGiftcard;
        this.giftCardFreightPrice = product?.fields?.GiftCardFreightPrice || [];

        const stateString = await this.getItem(
          `__configurator_${groupData.id}`
        );
        this.configuratorState = JSON.parse(stateString);
        this.rowGroupForEdit = groupData.id;
      } catch (err) {
        this.error = err;
      } finally {
        this.requestPending = false;
      }
    },
    closeCart() {
      this.isQuickCartOpened = false;
      this.unregisterModal('QuickCart');

      this.$emit('close');
    },
    openCart() {
      this.isQuickCartOpened = true;
      this.registerModal('QuickCart');
      this.pushVisitCartEvent();
    },
    onCheckoutClick() {
      try {
        localStorage.setItem('checkoutPreviousRoute', this.$route.fullPath);
      } catch (e) {}
    },
    async removeProductGroup() {
      const { _, error } = await this.removeGroupFromCart(this.rowGroupForEdit);
      this.rowGroupForEdit = '';
    },
    backToCart() {
      this.hideCloseEditConfirm();
      this.rowGroupForEdit = '';
    },
    showCloseEditConfirm() {
      this.showLeaveConfigConfirmation = true;
    },
    hideCloseEditConfirm() {
      this.showLeaveConfigConfirmation = false;
    }
  },
  mounted() {
    this.$mitt.on('openQuickCart', this.openCart);
  },
  beforeUnmount() {
    this.$mitt.off('openQuickCart', this.openCart);
  }
};
</script>

<template>
  <teleport to="body">
    <modal-container
      v-if="isQuickCartOpened"
      @close-modal="closeCart()"
      @navigate-back="backToCart()"
      position="right"
      :show-exit-button="!rowGroupForEdit"
      :show-back-button="!!rowGroupForEdit && !showLeaveConfigConfirmation"
      :show-left-exit-button="false"
      class="quick-cart__modal"
    >
      <div v-if="!rowGroupForEdit" class="quick-cart">
        <h2
          class="quick-cart__title"
          :class="{ 'quick-cart__title-empty--phone': cartIsEmpty }"
        >
          {{ titleText }}
        </h2>

        <div v-if="cartIsEmpty" class="quick-cart__empty">
          {{ emptyCartText }}
        </div>

        <div v-if="!cartIsEmpty" class="quick-cart__list-wrapper">
          <div class="quick-cart__list">
            <CartGiftCardItem v-for="row in gceRows" :key="row.id" :row="row" />
            <CartGroupItem
              :data-group-id="group.groupId"
              v-for="group in groupedRows"
              :key="group.groupId"
              :group="group"
              @edit="openEdit"
            />
          </div>
        </div>

        <div v-if="!cartIsEmpty" class="quick-cart__summary">
          <div class="quick-cart__order-total">
            <div>{{ totalLabel }}</div>
            <div>{{ total }}</div>
          </div>
          <div class="quick-cart__to-checkout" v-if="checkoutUrl">
            <router-link
              :to="checkoutUrl"
              class="primary-button quick-cart__to-checkout-btn"
              @click.native="onCheckoutClick()"
            >
              {{ toCheckout }}
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="rowGroupForEdit" class="modal-product-editor">
        <div class="modal-config-confirm-container">
          <button
            v-show="!showLeaveConfigConfirmation"
            class="modal-product-editor__back"
            @click="showCloseEditConfirm()"
          >
            {{ $globalTexts.cart__back_to_cart || 'cart__back_to_cart' }}
          </button>
          <ProductConfigurator
            v-show="!showLeaveConfigConfirmation"
            class="modal-product-configurator"
            :configurator-saved-state="configuratorState"
            :variants="variantData"
            :selected-variant="selectedVariant"
            :decorations="decorationData"
            :addons="addonData"
            :isGiftCard="isGiftCard"
            :giftCardFreightPrice="giftCardFreightPrice"
            :modal="true"
            :base-product-name="editedBaseProductname"
            @remove-group="removeProductGroup"
          />
          <div
            v-if="showLeaveConfigConfirmation"
            class="decoration-remove__modal"
          >
            <img
              class="quick-cart-modal__overlay-exit"
              src="../static/icons/cancel_icon.svg"
              alt="exit icon"
              @click="hideCloseEditConfirm()"
            />
            <p class="decoration-remove__modal-title">
              {{
                $globalTexts.decoration__exit_question ||
                'decoration__exit_question'
              }}
            </p>
            <p class="decoration-remove__modal-subtitle">
              {{
                $globalTexts.decoration__exit_info || 'decoration__exit_info'
              }}
            </p>
            <div class="decoration-remove__modal-options">
              <button
                class="decoration-remove__modal-cancel secondary-button"
                @click="backToCart()"
              >
                {{
                  $globalTexts.decoration__leave_editor ||
                  'decoration__leave_editor'
                }}
              </button>
              <button
                class="decoration-remove__modal-accept primary-button"
                @click="hideCloseEditConfirm()"
              >
                {{
                  $globalTexts.decoration__continue_edit ||
                  'decoration__continue_edit'
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal-container>
  </teleport>
</template>

<style>
.modal-product-editor {
  height: 100%;
}

.modal-product-editor__back {
  top: 12px;
  left: 40px;
  font-size: 14px;
  line-height: 24px;
  position: absolute;
  z-index: 10;
}

.quick-cart__modal .modal-container {
  max-width: 450px;
}

.quick-cart__modal .modal-container__exit-button {
  width: 14px;
}

.quick-cart {
  display: flex;
  flex-direction: column;
  width: 100%;
  right: 0;
  z-index: 9999;
  height: 100%;
  padding-top: 0.5rem;
  background-color: #f6f6f6;
}

.quick-cart__title {
  font-weight: normal;
  display: flex;
  justify-content: center;
  margin: 0.2rem 2rem 0;
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 700;
  line-height: 24px;
}

.quick-cart__empty {
  padding-top: 3rem;
  text-align: center;
  font-size: 22px;
  color: #1a1a1a;
  font-weight: 700;
}

.quick-cart__list-wrapper {
  overflow-y: auto;
  max-height: 100%;
}

.quick-cart__list {
  padding: 0 12px;
  margin-top: 0.75rem;
}

.quick-cart__summary {
  margin-top: auto;
  background-color: #fff;
  border-top: 1px solid var(--color-neutrals-20);
  box-shadow: 4px 5px 20px rgba(0, 81, 121, 0.07);
}

.quick-cart__to-checkout {
  padding: 0.75rem 0;
}

a.quick-cart__to-checkout-btn {
  margin: 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 1rem;
  width: calc(100% - 2rem);
  margin-left: 1rem;
  text-align: center;
}

.quick-cart__order-total {
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.quick-cart-modal__overlay-exit {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

@media (--tabletAndDesktop) {
  .quick-cart__list {
    padding: 0 20px;
  }
}
</style>
