<script>
import ProductCard from "../ProductCard.vue";

export default {
  components: {
    ProductCard,
  },
  props: {
    searchResult: {
      type: Object,
    },
  },
  inject: ["closeSearch"],
  computed: {
    products() {
      return this.searchResult?.productSearch?.products || [];
    },
    productHits() {
      return this.searchResult?.productSearch?.hits || 0;
    },

    pages() {
      return this.searchResult?.pageSearch?.pages || [];
    },
    pageHits() {
      return this.searchResult?.pageSearch?.hits || 0;
    },
    facetSuggestions() {
      return this.searchResult?.productSearch?.facetSuggestions || [];
    },
    searchString() {
      return this.searchResult?.searchString || "";
    },
    productHitsLabel() {
      return this.$replaceTokens(this.$globalTexts.search__see_all, {
        hits: this.productHits,
      });
    },
    hasAnySearchResults() {
      return (
        this.products.length > 0 ||
        this.facetSuggestions.length > 0 ||
        this.pages.length > 0
      );
    },
  },
  methods: {
    performFullSearch() {
      this.$emit("fullSearch", this.searchString);
      this.$nextTick(() => {
        this.closeSearch();
      });
    },
  },
};
</script>

<template>
  <div
    class="search-view-phone-results"
    v-if="searchResult && hasAnySearchResults"
  >
    <div class="search-view-phone-results__content">
      <div
        class="search-view-phone-results__facets"
        v-if="facetSuggestions && facetSuggestions.length > 0"
      >
        <div
          v-for="facet in facetSuggestions"
          :key="facet.id"
          class="search-view-phone-results__facet-suggestion"
        >
          <div
            v-for="facetValue in facet.values"
            :key="facetValue.id"
            class="search-view-phone-results__facet-value-suggestion"
            @click="
              $emit('applyFacetSuggestion', {
                facetId: facet.id,
                facetValueId: facetValue.id,
              })
            "
          >
            {{ facetValue.text }}
          </div>
        </div>
      </div>
      <div class="search-view-phone-results__scrollable-content">
        <div class="search-view-phone-results__pages" v-if="pageHits">
          <div class="search-view-phone-results__page-caption">
            {{ $globalTexts.search__page_results_title }}
          </div>
          <router-link
            v-for="(page, idx) in pages"
            :key="idx"
            :to="page.url"
            class="search-view-phone-results__page-suggestion"
          >
            {{ page.pageName }}
          </router-link>
        </div>
        <div
          class="search-view-phone-results__all-product-hits"
          v-if="productHits"
          @click="performFullSearch()"
        >
          {{ productHitsLabel }}
        </div>
        <div class="search-view-phone-results__products">
          <product-card
            v-for="(product, idx) in products"
            :key="idx"
            :product="product"
            class="search-view-phone-results__product"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    class="jsPhoneNoSearchResults search-view-phone-results__no-result"
    v-if="searchResult && !hasAnySearchResults"
  >
    {{ $globalTexts.search__no_hits }}
  </div>
</template>

<style>
.search-view-phone-results {
  background-color: white;
  padding-top: 0.5rem;
  height: 100%;
  padding-bottom: 8rem;
}
.search-view-phone-results__content {
  height: 100%;
}

.search-view-phone-results__scrollable-content {
  height: 100%;
  overflow-y: auto;
}

.search-view-phone-results__page {
  padding-right: 0.5rem;
}

.search-view-phone-results__all-product-hits {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  margin: 0.15rem 0.75rem 1rem;
  display: inline-block;
}

.search-view-phone-results__facets {
  display: flex;
  overflow-x: auto;
  font-size: 14px;
  margin: 0 0 0.85rem 0.75rem;
}

.search-view-phone-results__facets {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.search-view-phone-results__facets::-webkit-scrollbar {
  display: none;
}

.search-view-phone-results__facet-suggestion {
  display: flex;
}

.search-view-phone-results__facet-value-suggestion {
  color: white;
  background-color: black;
  padding: 9px 20px;
  border-radius: 1.25rem;
  margin-right: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
}

.search-view-phone-results__pages {
  display: flex;
  flex-direction: column;
  margin: 1rem 0.75rem -0.75rem;
}

.search-view-phone-results__page-caption {
  font-size: 16px;
  font-weight: 700;
  color: var(--color-neutrals-60);
  line-height: 24px;
}

.search-view-phone-results__page-suggestion {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1a1a1a;
  margin-top: 0.5rem;
}

.search-view-phone-results__products {
  display: flex;
  flex-wrap: wrap;
  margin: auto 0.5rem;
}

.search-view-phone-results__product {
  width: 50%;
  margin-bottom: 10px;
}

.search-view-phone-results__no-result {
  background-color: white;
  text-align: center;
  padding: 0.25rem 1rem 1.25rem;
  color: #333333;
}

@media (max-width: 320px) {
  .search-view-phone-results__product {
    width: 100%;
  }
}

.search-view-phone-results__product .product-card__variants {
  display: none;
}

.search-view-phone-results__product .product-card__name,
.search-view-phone-results__product .product-card__price {
  font-size: 12px;
}

.search-view-phone-results__product .product-card__articleNumber {
  font-size: 10px;
}
</style>