<script>
import { defineComponent, inject } from 'vue';

import useWebsiteTexts from '@/composables/useWebsiteTexts';

import { configuratorStateKey } from '../ProductConfigurator/ProductConfiguratorKeys';
import RadioButton from '../forms/RadioButton.vue';

export default defineComponent({
    components: {
        RadioButton
    },
    inject: ["$addToCartContextReactive"],
    emits: ["changeGiftcardType"],
    setup() {
        const { websiteText } = useWebsiteTexts();
        const { configuratorState } = inject(configuratorStateKey);
        return { websiteText, configuratorState };
    },
    methods: {
        setDeliveryOption(option){
            this.$emit('changeGiftcardType', option)
        }
    },
});
</script>

<template>
    <div class="recipient-delivery-type-selector">
        <radio-button
            name="deliveryType"
            @change="setDeliveryOption('Physical')"
            :text="$globalTexts.giftcard__deliver_type_physical_title ?? 'test'"
            :description="$globalTexts.giftcard__deliver_type_physical_message ?? 'test'"
            :modelValue="configuratorState.giftcardType == 'Physical'"
        />
        <!-- <radio-button
            name="deliveryType"
            @change="setDeliveryOption('Email')"
            :text="$globalTexts.giftcard__deliver_type_email_title ?? 'test'"
            :description="$globalTexts.giftcard__deliver_type_email_message ?? 'test'"
            :modelValue="configuratorState.giftcardType == 'Email'"
        />
        <radio-button
            name="deliveryType"
            @change="setDeliveryOption('Sms')"
            :text="$globalTexts.giftcard__deliver_type_sms_title ?? 'test'"
            :description="$globalTexts.giftcard__deliver_type_sms_message ?? 'test'"
            :modelValue="configuratorState.giftcardType == 'Sms'"
        /> -->
    </div>
</template>

<style>
.recipient-delivery-type-selector {
    display:block;
    overflow:hidden;
}
.recipient-delivery-type-selector > .radio-button__description{
    white-space: normal;
    padding-bottom: 0.5rem;
}
</style>

