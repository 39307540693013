<script>
import { defineComponent, inject } from 'vue';

import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useContext from '@/composables/useContext';
import { configuratorStateKey } from '../ProductConfigurator/ProductConfiguratorKeys';
import ErrorMessage from '../forms/ErrorMessage.vue';

export default defineComponent({
  components: {
    ErrorMessage
  },
  setup() {
    const { websiteText } = useWebsiteTexts();
    const { isAuthorized, channel } = useContext();
    const { configuratorState, updateCustomPrice } =
      inject(configuratorStateKey);
    return {
      websiteText,
      isAuthorized,
      channel,
      updateCustomPrice,
      configuratorState
    };
  },
  props: {
    isGiftCard: {
      type: Boolean
    },
    variants: {
      type: Array,
      required: true
    },
    giftCardFreightPrice: {
      type: Number,
      default: 0
    },
    current: null
  },
  data() {
    return {
      inputAmountWithVat: null,
      inputAmountWithoutVat: null,
      inputAmountValue: null
    };
  },
  computed: {
    flexibleVariant() {
      return this.variants.filter((v) => v.fields.IsFlexibleType)[0];
    },
    previousValue() {
      return this.configuratorState.customPrice;
    },
    discount() {
      const discountGroupString = this.$user?.organization?.discountGroup;
      if (discountGroupString) {
        const discountGroupAmount = discountGroupString.replace('%', '');
        return 1 - discountGroupAmount / 100;
      }
      return 1;
    },
    discountAndVat() {
      return this.discount / (1 + 0.25); // Marcus request for hardcoded 25% VAT, will be changed in 2024
    }
  },
  methods: {
    onInputWithVatChange() {
      this.setFlexibleValues(this.inputAmountWithVat * this.discountAndVat);
    },
    onInputWithoutVatChange() {
      this.setFlexibleValues(this.inputAmountWithoutVat * this.discount);
    },
    onInputValueChange() {
      this.setFlexibleValues(this.inputAmountValue);
    },
    setFlexibleValues(value) {
      this.inputAmountWithVat = this.formatValue(
        value / this.discountAndVat
      ).toString();
      this.inputAmountWithoutVat = this.formatValue(
        value / this.discount
      ).toString();
      this.inputAmountValue = this.formatValue(value).toString();
      this.updateCustomPrice(
        this.formatValue(
          this.getPriceWithMargins(value / this.discountAndVat) *
            this.discountAndVat
        )
      );
    },
    formatValue(value) {
      return Math.round(value * 100) / 100;
    },
    getPriceWithMargins(value) {
      var v = this.flexibleVariant;
      if (!v) return 0;
      return Math.min(
        v.fields.MaxGiftCardPrice,
        Math.max(v.fields.MinGiftCardPrice, value)
      );
    },
    isValidInputs() {
      return (
        this.inputAmountWithVat === null ||
        this.inputAmountWithVat == 0 ||
        this.getPriceWithMargins(this.inputAmountWithVat) ==
          this.inputAmountWithVat
      );
    },
    getPrice(variant) {
      if (
        this.channel.combineGiftCardPriceWithShipping &&
        this.isGiftCard &&
        !this.isAuthorized()
      ) {
        return variant.price + this.giftCardFreightPrice;
      }
      if (variant.price) return variant.price;
      if (this.previousValue) return this.previousValue;
      return 0;
    },
    getVariantUrl(variant) {
      return this.$toVariantUrl(variant);
    },
    resetValues() {
      this.inputAmountWithVat = null;
      this.inputAmountWithoutVat = null;
      this.inputAmountValue = null;
    }
  },
  watch: {
    previousValue(value) {
      if (!this.inputAmountWithoutVat && value != this.inputAmountWithoutVat) {
        this.setFlexibleValues(value * this.discount);
      }
    }
  }
});
</script>

<template>
  <div class="giftcard-type-selector">
    <router-link
      v-for="variant in variants"
      v-if="flexibleVariant == null"
      :to="getVariantUrl(variant)"
    >
      <div
        class="giftcard-type"
        :class="variant.articleNumber == current?.articleNumber && 'current'"
      >
        <button class="secondary-button giftcard-type-button">
          {{ variant.fields._name }}
          <br />
          <br />
          {{ $formatPrice(getPrice(variant)) }}
        </button>
      </div>
    </router-link>
    <div v-if="flexibleVariant != null">
      <p class="giftcard__flexible_title">
        {{ websiteText('giftcard__flexible_input_with_vat_label').value }}
      </p>
      <div class="giftcard__flexible_input_description">
        {{ websiteText('giftcard__flexible_input_with_vat_description').value }}
      </div>
      <error-message
        v-if="!isValidInputs()"
        :message="
          this.$replaceTokens(
            this.$globalTexts.giftcard__flexible_input_error,
            {
              min: `${flexibleVariant.fields.MinGiftCardPrice}`,
              max: `${flexibleVariant.fields.MaxGiftCardPrice}`
            }
          ) || ''
        "
      />
      <div class="giftcard__flexible_input">
        <input
          v-model="inputAmountWithVat"
          type="number"
          :placeholder="$globalTexts.giftcard__flexible_input_placeholder"
          @input="onInputWithVatChange()"
        />
        <p>
          {{
            websiteText('giftcard__flexible_input_with_vat_input_label').value
          }}
        </p>
      </div>
      <p class="giftcard__flexible_title">
        {{ websiteText('giftcard__flexible_input_without_vat_label').value }}
      </p>
      <div class="giftcard__flexible_input_description">
        {{
          websiteText('giftcard__flexible_input_without_vat_description').value
        }}
      </div>
      <div class="giftcard__flexible_input">
        <input
          v-model="inputAmountWithoutVat"
          type="number"
          :placeholder="$globalTexts.giftcard__flexible_input_placeholder"
          @input="onInputWithoutVatChange()"
        />
        <p>
          {{
            websiteText('giftcard__flexible_input_without_vat_input_label')
              .value
          }}
        </p>
      </div>
      <p class="giftcard__flexible_title">
        {{ websiteText('giftcard__flexible_input_value_label').value }}
      </p>
      <div class="giftcard__flexible_input_description">
        {{ websiteText('giftcard__flexible_input_value_description').value }}
      </div>
      <div class="giftcard__flexible_input">
        <input
          v-model="inputAmountValue"
          type="number"
          :placeholder="$globalTexts.giftcard__flexible_input_placeholder"
          @input="onInputValueChange()"
        />
        <p>
          {{ websiteText('giftcard__flexible_input_value_input_label').value }}
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.giftcard-type-selector {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  flex-wrap: wrap;
}
.giftcard-type {
  width: 190px;
  padding-bottom: 10px;
}
.giftcard-type > button {
  width: 100%;
  height: 80px;
}
.current > button {
  border: 2px solid var(--color-neutrals-100);
}
.giftcard-type-button {
  padding: 0 !important;
}
.giftcard__flexible_input_description {
  font-size: 15px;
  color: #797b80;
  padding-left: 1.75rem;
  margin-bottom: 0.5rem;
}
.giftcard__flexible_title {
  margin: 0;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.giftcard__flexible_input {
  display: flex;
  margin-bottom: 1.5em;
}
.giftcard__flexible_input > input {
  width: 150px !important;
}
.giftcard__flexible_input > p {
  margin: auto;
  margin-left: 1em;
}
</style>
