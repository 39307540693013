<script>
import { fetchPageBuilder } from '@drapejs/core';
import * as user from "../../../connectors/litium/user";

import MyPagesMenuOrganizationSwitcher from "./MyPagesMenuOrganizationSwitcher.vue";
import MenuArrow from "./components/MenuArrow.vue"

export default {
  components: {
    MyPagesMenuOrganizationSwitcher,
    MenuArrow,
  },
  emits: ["navigate", "close"],
  data: () => ({
    error: "",
  }),
  computed: {
    organizationName() {
      return this.$user?.organization?.organizationName || "";
    },
    isOrganizationSwitcherVisibile() {
      return this.$user?.organizations?.length > 1 || this.$user?.canRepresentAnyOrganization;
    },
  },
  methods: {
    navigateMenu(menuItem) {
      this.$emit("navigate", menuItem);
    },
    async logout() {
      try {
        this.error = "";

        const requestArgs = fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          ""
        );

        const response = await this.$invoke(user.commands.logout, requestArgs);

        if (response.error === "NONE") {
          this.$emit("close");
          window.location.reload();
        } else {
          this.error = this.$globalTexts.global__logout_failed;
        }

        return response;
      } catch (err) {
        this.error = err;
      }
    },
  },
};
</script>

<template>
  <div class="my-pages-menu">
    <div class="my-pages-menu__header">
      <h1 class="my-pages-menu__header-title">
        {{ $globalTexts.mypages__title }}
      </h1>
      <span class="my-pages-menu__header-subtitle">{{ organizationName }}</span>
    </div>

    <div class="my-pages-menu__list">
      <div class="my-pages-menu__list-title">
        {{ $globalTexts.mypages__dashboard }}
      </div>

      <div class="my-pages-menu__list-item" @click="navigateMenu('orders')">
        {{ $globalTexts.mypages__orders }}
        <menu-arrow />
      </div>

      <div
        class="my-pages-menu__list-item"
        @click="navigateMenu('personProfile')"
      >
        {{ $globalTexts.mypages__profile }}
        <menu-arrow />
      </div>

      <div
        class="my-pages-menu__list-item"
        @click="navigateMenu('organizationProfile')"
      >
        {{ $globalTexts.mypages__company_information }}
        <menu-arrow />
      </div>

      <div class="my-pages-menu__logout" @click="logout">
        <img class="" src="../../static/icons/logout.svg" />
        <div class="my-pages-menu__logout-caption">
          {{ $globalTexts.global__logout_label }}
        </div>
      </div>
      <div class="my-pages-menu__error" v-if="error">
        {{ error }}
      </div>
    </div>
    <my-pages-menu-organization-switcher
      v-if="isOrganizationSwitcherVisibile"
    />
  </div>
</template>

<style>
.my-pages-menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  cursor: pointer;
}

.my-pages-menu__header {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-bottom: 1.5px solid #f0f0f0;
  padding: 0 14px 20px 1rem;
}

.my-pages-menu__header-title {
  font-size: 31px;
  line-height: 41px;
  margin: 0;
  text-align: left;
}

.my-pages-menu__header-subtitle {
  font-size: 16px;
  line-height: 24px;
}

.my-pages-menu__list {
  height: 100%;
  margin-top: 10px;
  padding-left: 14px;
}

.my-pages-menu__list-item,
.my-pages-menu__list-title {
  display: flex;
  justify-content: space-between;
  height: 42px;
  align-items: center;
  font-weight: bold;
  text-transform: capitalize;
}

.my-pages-menu__logout {
  display: flex;
  align-items: center;
  margin-top: 30px;
  cursor: pointer;
  user-select: none;
}

.my-pages-menu__logout-caption {
  font-weight: bold;
  padding-left: 0.75rem;
}

.my-pages-menu__error {
  color: var(--color-feedback-error);
  padding-top: 0.75rem;
  padding-left: 14px;
  font-size: 15px;
  font-weight: 700;
}
</style>