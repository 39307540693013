<script>
export default {
  props: {
    templates: {
      type: Array,
      required: true,
    },
    currentTemplates: {
      type: Object,
      required: true,
    },
  },
  emits: ['setText', 'close'],
  data() {
    return {
      openTab: 0,
    };
  },
  computed: {
    tabNames() {
      return this.templates.map((t) => t.name);
    },
    tabTemplates() {
      return this.templates[this.openTab]?.options || [];
    },
    currentText() {
      if (this.templates[this.openTab]?.value === 'title') {
        return this.currentTemplates?.title;
      }
      return this.currentTemplates?.message;
    },
  },
  methods: {
    setOpenTab(index) {
      this.openTab = index;
    },
    selectTemplate(template) {
      if (!this.templates?.[this.openTab]) {
        return;
      }

      const textContent = {};
      if (this.templates[this.openTab]?.value === 'title') {
        textContent.title = template;
      } else {
        textContent.message = template;
      }
      this.$emit('setText', textContent);
    },
  },
};
</script>

<template>
  <div class="template-menu">
    <div class="template-menu__navigation" @click="$emit('close')">
      <img
        src="../../../static/icons/arrow_left_icon.svg"
        class="template-menu__back-icon"
      />
      {{ $globalTexts.global__label_back || 'global__label_back' }}
    </div>
    <div class="template-menu__tabs">
      <span
        v-for="(tab, idx) in tabNames"
        :key="tab"
        :class="[
          'template-menu__tab',
          { 'template-menu__tab--selected': openTab === idx },
        ]"
        @click="setOpenTab(idx)"
      >
        {{ tab }}
      </span>
    </div>
    <div class="template-menu__list">
      <div
        v-for="text in tabTemplates"
        :key="text"
        class="template-menu__template-text"
        @click="selectTemplate(text)"
      >
        <img
          v-if="text === currentText"
          src="../../../static/icons/checkmark_icon.svg"
          class="template-menu__selected-icon"
        />
        {{ text }}
      </div>
    </div>
  </div>
</template>

<style>
.template-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
}

.template-menu__navigation {
  color: var(--color-text-medium);
  margin-bottom: 26px;
  line-height: 24px;
  display: flex;
  gap: 5px;
  cursor: pointer;
}

.template-menu__back-icon {
  width: 16px;
  filter: invert(52%) sepia(2%) saturate(895%) hue-rotate(185deg)
    brightness(93%) contrast(93%);
}

.template-menu__tabs {
  display: flex;
  gap: 10px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 26px;
  color: var(--color-neutrals-60);
  cursor: pointer;
}

.template-menu__tab--selected {
  color: var(--color-text-darker);
  position: relative;
  pointer-events: none;
}

.template-menu__tab--selected::after {
  position: absolute;
  content: '';
  left: 0;
  top: calc(100% + 1px);
  width: 100%;
  border-bottom: 1px solid var(--color-text-darker);
}

.template-menu__list {
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
}

.template-menu__selected-icon {
  float: left;
  width: 16px;
  margin-right: 5px;
}

.template-menu__template-text {
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid var(--color-neutrals-20);
}

.template-menu__template-text:last-child {
  border-bottom: none;
}
</style>
