<script>
import { computed } from 'vue';
import { fetchPageBuilder, OptionsComponent, navigateKey } from '@drapejs/core';
import { format } from 'date-fns';
import { deviceWidths } from '../../constants';
import { commands } from '../../../connectors/litium/giftcard';

import * as config from '../../config';

import FrontMenu from './SideMenus/FrontMenu.vue';
import InsideMenu from './SideMenus/InsideMenu.vue';
import MainMenu from './MainMenu.vue';
import NavigationMenu from './NavigationMenu.vue';
import PreviewArea from './Previews/PreviewArea.vue';
import WebMenu from './SideMenus/WebMenu.vue';
import ProgressMenu from './ProgressMenu.vue';
import GiftCardSettings from './Previews/GiftCardSettings.vue';
import LoadingSpinner from '../LoadingSpinner.vue';
import WarningModal from './WarningModal.vue';

let resizeDebouncer = null;

export default {
  extends: OptionsComponent,
  components: {
    FrontMenu,
    MainMenu,
    PreviewArea,
    NavigationMenu,
    InsideMenu,
    WebMenu,
    ProgressMenu,
    GiftCardSettings,
    LoadingSpinner,
    WarningModal,
  },
  props: {
    context: {
      type: String,
      default: 'page',
    },
    wizardSettings: {
      type: Object,
    },
  },
  data: () => ({
    showExit: false,
    showWarning: false,
    giftCardLoaded: false,
    menuItems: [
      'menu-front',
      'menu-inside',
      'menu-back',
      'menu-web',
      'menu-settings',
    ],
    showPreview: false,
    requestPending: false,
    currentMenuItem: 'menu-front',
    website: {
      background: { name: '100', src: '100' },
      message: '',
      logotype: {},
    },
    giftCardEditor: {},
    giftCardState: {},
    isDesktopView: false,
  }),
  inject: {
    navigate: navigateKey,
  },
  provide() {
    return {
      $giftCardsReactive: computed(
        () => this.giftCardEditor?.settings?.giftCardTypes || []
      ),
      $coverImagesReactive: computed(() => this.coverImages),
      $websiteImagesReactive: computed(() => this.websiteImages),
      $giftCardStateReactive: computed(() => this.giftCardState),
      $requestPendingReactive: computed(() => this.requestPending),
      $feesReactive: computed(() => this.giftCardEditor?.settings?.fees || []),
      $textSuggestionsReactive: computed(
        () => this.giftCardEditor?.textSuggestions || {}
      ),
      $imageLimits: computed(() => this.giftCardEditor?.imageLimits || {}),
      $grandTotalReactive: computed(
        () => this.giftCardEditor?.settings?.grandTotal || 0
      ),
      changeCoverImage: (image) => {
        this.giftCardState.coverImageSystemId = image.id;
      },
      changeWebsiteImage: (image) => {
        this.giftCardState.websiteImageSystemId = image.id;
      },
      changeTextContent: (textContent) => this.changeTextContent(textContent),
      changeWebsiteTextContent: (text) => this.changeWebsiteTextContent(text),
      invertWebsiteTextColor: (bool) => this.invertWebsiteTextColor(bool),
      changeLocale: (locale) => this.changeLocale(locale),
      changeVariant: (variant) => this.changeVariant(variant),
      changeQuantity: (quantity) => this.changeQuantity(quantity),
    };
  },
  computed: {
    coverImages() {
      return this.getGiftCardEditorImages(
        this.giftCardEditor?.coverImages || []
      );
    },
    websiteImages() {
      return this.getGiftCardEditorImages(
        this.giftCardEditor?.websiteImages || []
      );
    },
    visibleMenu() {
      const items = Array.from(this.menuItems);
      if (!this.showWhenPreviewOnPhoneOnly) {
        items.splice(items.indexOf('menu-back'), 1);
      }

      if (!this.hideWhenPreviewOnPhoneOnly) {
        items.splice(items.indexOf('menu-settings'), 1);
      }

      return items;
    },
    logoType() {
      return this.giftCardEditor?.logoType || null;
    },
    isCurrentItemFirst() {
      return this.currentMenuItem === this.visibleMenu[0];
    },
    isCurrentItemLast() {
      return (
        this.currentMenuItem === this.visibleMenu[this.visibleMenu.length - 1]
      );
    },
    hideSideMenu() {
      if (this.isDesktopView) {
        return (
          this.currentMenuItem === 'menu-back' ||
          this.currentMenuItem === 'menu-settings'
        );
      }

      return this.showPreview;
    },
    showWhenPreviewOnPhoneOnly() {
      return this.isDesktopView || this.showPreview;
    },
    hideWhenPreviewOnPhoneOnly() {
      return this.isDesktopView || !this.showPreview;
    },
    previewType() {
      return !this.hideWhenPreviewOnPhoneOnly &&
        this.currentMenuItem === 'menu-settings'
        ? 'menu-web'
        : this.currentMenuItem;
    },
    previewData() {
      return {
        type: this.previewType,
        website: {
          message:
            this.website.message ||
            this.$globalTexts.giftcard__personal_message ||
            'giftcard__personal_message',
          logotype: this.website.logotype,
          background: this.website.background,
        },
      };
    },
    requestContext() {
      return {
        channelSystemId: this.$channel.systemId,
        websiteSystemId: this.$channel.website?.systemId,
        culture: this.$channel.locale,
      };
    },
  },
  methods: {
    async addNewGiftCard() {
      if (this.redirectToLogin()) {
        return;
      }

      await this.fetchGiftCardSettings();
      this.giftCardLoaded = true;
    },
    async editGiftCard(giftCardInput) {
      if (this.redirectToLogin()) {
        return;
      }

      this.giftCardState = {
        ...giftCardInput,
      };
      await this.fetchGiftCardSettings();
      this.giftCardLoaded = true;
    },
    openExitModal() {
      this.showExit = true;
    },
    closeExitModal() {
      this.showExit = false;
    },
    getGiftCardEditorImages(imagesProp) {
      const images = [];
      if (!imagesProp) return images;
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(imagesProp)) {
        if (value?.length === 0 && key !== 'Custom') continue;

        const item = {
          value: key,
          covers: value,
          name: key,
        };

        if (key === 'UnCategorized') {
          item.name =
            this.$globalTexts.giftcard__uncategorized ||
            'giftcard__uncategorized';
          item.sort = 'Z';
        } else if (key === 'Custom') {
          item.name = this.$globalTexts.giftcard__custom || 'giftcard__custom';
          item.sort = 'ZZ';
        } else {
          item.sort = key;
        }

        images.push(item);
      }

      images.sort((a, b) => a.sort.localeCompare(b.sort));
      return images;
    },
    currentMenuIndex() {
      return this.visibleMenu.indexOf(this.currentMenuItem);
    },
    selectPrevious() {
      if (this.redirectToLogin()) {
        return;
      }

      this.currentMenuItem = this.visibleMenu[this.currentMenuIndex() - 1];
    },
    selectNext() {
      if (this.redirectToLogin()) {
        return;
      }

      this.currentMenuItem = this.visibleMenu[this.currentMenuIndex() + 1];
    },
    selectMenuItem(item) {
      if (this.redirectToLogin()) {
        return;
      }

      this.currentMenuItem = item;
    },
    openSideMenu(sideMenuId) {
      if (this.redirectToLogin()) {
        return;
      }

      return this.currentMenuItem === sideMenuId;
    },
    openPreview() {
      if (this.redirectToLogin()) {
        return;
      }

      if (
        this.currentMenuItem === 'menu-settings' &&
        !this.showWhenPreviewOnPhoneOnly
      ) {
        this.currentMenuItem = 'menu-web';
      }
      this.showPreview = true;
    },
    closePreview() {
      if (this.redirectToLogin()) {
        return;
      }

      if (
        this.currentMenuItem === 'menu-back' &&
        this.showWhenPreviewOnPhoneOnly
      ) {
        this.currentMenuItem = 'menu-inside';
      }
      this.showPreview = false;
    },
    async uploadCoverImages(images) {
      if (this.redirectToLogin()) {
        return;
      }

      const uploadedImages = await this.uploadCustomImages(
        images,
        'upload-cover-images'
      );
      const coverImages = this.giftCardEditor?.coverImages?.['Custom'];
      if (coverImages && coverImages.length >= 0) {
        coverImages.push(...uploadedImages);
        this.$mitt.emit(
          'giftCardEditor:coverImagesUploaded',
          uploadedImages[uploadedImages.length - 1]
        );
      }
    },
    async uploadWebsiteImages(images) {
      if (this.redirectToLogin()) {
        return;
      }

      const uploadedImages = await this.uploadCustomImages(
        images,
        'upload-website-images'
      );
      const websiteImages = this.giftCardEditor?.websiteImages?.['Custom'];
      if (websiteImages && websiteImages.length >= 0) {
        websiteImages.push(...uploadedImages);
        this.$mitt.emit(
          'giftCardEditor:websiteImagesUploaded',
          uploadedImages[uploadedImages.length - 1]
        );
      }
    },
    async uploadCustomImages(images, endpoint) {
      if (this.redirectToLogin()) {
        return;
      }

      const uploadedImages = await this.executeFetchRequest({
        requestPath: endpoint,
        method: 'POST',
        requestBody: {
          organizationId: this.giftCardState.organizationId || '',
          images,
        },
      });
      return uploadedImages;
    },
    async deleteCoverImage(image) {
      if (this.redirectToLogin()) {
        return;
      }

      const imageId = await this.deleteCustomImage(image, 'delete-cover-image');
      const coverImages = this.giftCardEditor?.coverImages?.['Custom'];
      if (coverImages && coverImages.length >= 0) {
        this.giftCardEditor.coverImages.Custom =
          this.giftCardEditor.coverImages.Custom.filter(
            (i) => i.id !== imageId
          );
        this.$mitt.emit('giftCardEditor:coverImageDeleted', imageId);
      }
    },
    async deleteWebsiteImage(image) {
      if (this.redirectToLogin()) {
        return;
      }

      const imageId = await this.deleteCustomImage(
        image,
        'delete-website-image'
      );
      const websiteImages = this.giftCardEditor?.websiteImages?.['Custom'];
      if (websiteImages && websiteImages.length >= 0) {
        this.giftCardEditor.websiteImages.Custom =
          this.giftCardEditor.websiteImages.Custom.filter(
            (i) => i.id !== imageId
          );
        this.$mitt.emit('giftCardEditor:websiteImageDeleted', imageId);
      }
    },
    async deleteCustomImage(image, endpoint) {
      if (this.redirectToLogin()) {
        return;
      }

      const imageId = await this.executeFetchRequest({
        requestPath: endpoint,
        method: 'DELETE',
        requestBody: {
          organizationId: this.giftCardState.organizationId || '',
          imageId: image.id,
        },
      });
      return imageId;
    },
    async uploadGiftCardLogoType(image) {
      if (this.redirectToLogin()) {
        return;
      }

      const logoType = await this.uploadLogotype(
        image,
        'upload-giftcard-logotype'
      );
      this.giftCardState.giftCardLogoTypeSystemId = logoType.id;
    },
    async uploadWebsiteLogoType(image) {
      if (this.redirectToLogin()) {
        return;
      }

      const logoType = await this.uploadLogotype(
        image,
        'upload-website-logotype'
      );
      this.giftCardState.webLogoTypeSystemId = logoType.id;
    },
    async uploadLogotype(image, endpoint) {
      if (this.redirectToLogin()) {
        return;
      }

      const logoType = await this.executeFetchRequest({
        requestPath: endpoint,
        method: 'POST',
        requestBody: {
          organizationId: this.giftCardState.organizationId || '',
          giftCardId: this.giftCardState.giftCardId,
          image,
        },
      });

      return logoType;
    },
    async deleteGiftCardLogoType(logoTypeId) {
      if (this.redirectToLogin()) {
        return;
      }

      await this.deleteLogoType(logoTypeId);
      this.giftCardState.giftCardLogoTypeSystemId = null;
    },
    async deleteWebLogoType(logoTypeId) {
      if (this.redirectToLogin()) {
        return;
      }

      await this.deleteLogoType(logoTypeId);
      this.giftCardState.webLogoTypeSystemId = null;
    },
    async deleteLogoType(logoTypeId) {
      if (this.redirectToLogin()) {
        return;
      }

      await this.executeFetchRequest({
        requestPath: 'delete-logoType',
        method: 'DELETE',
        requestBody: {
          giftCardId: this.giftCardState.giftCardId,
          imageId: logoTypeId,
        },
      });
    },
    changeTextContent(textContent) {
      if (this.redirectToLogin()) {
        return;
      }
      this.giftCardState.giftCardTitle = textContent.title;
      this.giftCardState.giftCardDescription = textContent.message;
    },
    changeWebsiteTextContent(text) {
      if (this.redirectToLogin()) {
        return;
      }

      this.giftCardState.webGiftCardDescription = text;
    },
    invertWebsiteTextColor(bool) {
      if (this.redirectToLogin()) {
        return;
      }

      this.giftCardState.websiteTextColorInverted = bool;
    },
    async changeLocale(locale) {
      if (this.redirectToLogin()) {
        return;
      }

      this.giftCardState.giftCardLocale = locale;
      this.giftCardState.variantId = '';
      this.giftCardState.variantSystemId = '';
      this.giftCardState.giftCardCurrencyId = '';

      await this.fetchGiftCardSettings();
    },
    async changeQuantity(quantity) {
      if (this.redirectToLogin()) {
        return;
      }

      this.giftCardState.quantity = quantity;
      await this.fetchGiftCardSettings();
      return this.giftCardState.quantity;
    },
    async changeVariant(variant) {
      if (this.redirectToLogin()) {
        return;
      }

      this.giftCardState.variantId = variant.variantId;
      this.giftCardState.variantSystemId = variant.variantSystemId;
      this.giftCardState.quantity = variant.quantity;
      this.giftCardState.insideCoverImageSystemId = variant.insideCoverImageId;
      this.giftCardState.backCoverImageSystemId = variant.backCoverImageId;
      this.giftCardState.channelSystemId = variant.channelSystemId;
      this.giftCardState.giftCardLocale = variant.locale;
      this.giftCardState.giftCardCurrencyId = variant.currencyId;
      await this.fetchGiftCardSettings();
    },
    async fetchGiftCardSettings() {
      const organizationId =
        this.$user?.organization?.organizationId ||
        (await this.$getItem('__user'))?.organization?.organizationId ||
        '';

      const request = this.buildCommandRequest({
        variantId: this.giftCardState.variantId || '',
        variantSystemId: this.giftCardState.variantSystemId || '',
        locale: this.giftCardState.giftCardLocale || '',
        quantity: this.giftCardState.quantity || 1,
        giftCardId: this.giftCardState.giftCardId,
        organizationId,
        orderRowSystemId: this.giftCardState.orderRowSystemId || '',
        webGiftCardDescription: this.giftCardState.webGiftCardDescription || '',
        websiteTextColorInverted:
          this.giftCardState.websiteTextColorInverted || false,
      });

      this.giftCardEditor = await this.executeGraphQlRequest(
        commands.fetchGiftCardEditor,
        request
      );

      const settings = this.giftCardEditor?.settings || {};
      const giftCardType =
        (settings?.giftCardTypes || [])?.find((t) => t.isSelected) || {};
      const variant =
        (giftCardType?.variants || [])?.find((t) => t.isSelected) || {};

      const giftCardState = {
        type: this.giftCardState.type || '',
        variantId: variant.variantId || '',
        variantSystemId: variant.variantSystemId || '',
        quantity: variant.quantity || 1,
        giftCardId: settings.giftCardId,
        giftCardLocale: variant.locale || '',
        channelSystemId: variant.channelSystemId,
        giftCardCurrencyId: variant.currencyId || '',
        insideCoverImageSystemId: variant.insideCoverImageId || '',
        backCoverImageSystemId: variant.backCoverImageId || '',
        organizationId,
        webGiftCardDescription: this.giftCardState.webGiftCardDescription || '',
        giftCardTitle: this.giftCardState.giftCardTitle || '',
        giftCardDescription: this.giftCardState.giftCardDescription || '',
        websiteTextColorInverted:
          this.giftCardState.websiteTextColorInverted || false,
      };

      giftCardState.coverImageSystemId =
        this.giftCardState.coverImageSystemId ||
        (this.coverImages || []).find((c) => c.covers?.length > 0)?.covers[0]
          ?.id;

      giftCardState.websiteImageSystemId =
        this.giftCardState.websiteImageSystemId ||
        (this.websiteImages || []).find((c) => c.covers?.length > 0)?.covers[0]
          ?.id;

      giftCardState.giftCardLogoTypeSystemId =
        this.giftCardState.giftCardLogoTypeSystemId || '';

      giftCardState.webLogoTypeSystemId =
        this.giftCardState.webLogoTypeSystemId || '';

      giftCardState.orderRowSystemId =
        this.giftCardState.orderRowSystemId || '';

      this.giftCardState = giftCardState;
    },
    openWarningModal() {
      this.showWarning = true;
    },
    closeWarningModal() {
      this.showWarning = false;
    },
    preSaveGiftCard() {
      if (
        !this.giftCardState.giftCardDescription ||
        !this.giftCardState.giftCardLogoTypeSystemId ||
        !this.giftCardState.giftCardTitle ||
        !this.giftCardState.webGiftCardDescription ||
        !this.giftCardState.webLogoTypeSystemId ||
        !this.giftCardState.websiteImageSystemId ||
        !this.giftCardState.coverImageSystemId
      ) {
        return this.openWarningModal();
      }

      this.closeWarningModal();
      return this.saveGiftCard();
    },
    async saveGiftCard() {
      if (this.redirectToLogin()) {
        return;
      }

      const request = this.buildCommandRequest({
        input: { ...this.giftCardState },
      });

      const result = await this.executeGraphQlRequest(
        commands.addOrUpdateGiftCardToCart,
        request
      );

      if (!result?.row) {
        alert('Something went wrong while trying to add the giftcard');
        return;
      }

      this.$mitt.emit('giftCardEditor:closeEditorModal');

      if (!this.giftCardState.orderRowSystemId) {
        await this.navigate(this.$channel.rootPath);
      }
    },
    async discardGiftCard() {
      if (this.context === 'modal') {
        this.$emit('discard-changes');
      } else {
        await this.navigate(this.$channel.rootPath);
      }
    },
    redirectToLogin() {
      const organizationId = this.$user?.organization?.organizationId || '';

      if (!this.$user?.isAuthenticated || !organizationId) {
        this.$navigateToLoginPage();
        return true;
      }
      return false;
    },
    checkIfDesktopView() {
      if (resizeDebouncer) clearTimeout(resizeDebouncer);

      resizeDebouncer = setTimeout(() => {
        this.isDesktopView = window.innerWidth > deviceWidths.tabletMaxWidth;
      }, 100);
    },
    buildCommandRequest(args) {
      const request = {
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          {
            ...this.$route.query,
          },
          ''
        ),
        ...args,
      };

      return request;
    },
    async executeFetchRequest({ requestPath, method, requestBody }) {
      const requestData = {
        method,
        headers: {
          'litium-request-context': JSON.stringify(this.requestContext),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      if (method !== 'GET' && requestBody) {
        requestData.body = JSON.stringify({
          organizationId: this.giftCardState.organizationId || '',
          ...requestBody,
        });
      }

      const endpoint = `${config.litiumBaseUrl}/api/giftcard-editor/${requestPath}`;
      const response = await fetch(endpoint, requestData);
      if (!response.ok) {
        throw Error(response.statusText);
      }

      const result = await response.json();

      return result;
    },
    async executeGraphQlRequest(command, request) {
      try {
        this.requestPending = true;
        const response = await this.$invoke(command, request);
        return response;
      } catch (err) {
        console.error(err);
      } finally {
        this.requestPending = false;
      }
    },
  },
  async mounted() {
    this.isDesktopView = window.innerWidth > deviceWidths.tabletMaxWidth;
    window.addEventListener('resize', this.checkIfDesktopView);
    this.$mitt.on('giftCardEditor:saveProgressRequested', this.preSaveGiftCard);
    this.$mitt.on('giftCardEditor:saveProgressWithWarning', this.saveGiftCard);
    this.$mitt.on('giftCardEditor:openExitModal', this.openExitModal);
    this.$mitt.on('giftCardEditor:closeWarningModal', this.closeWarningModal);

    if (this.wizardSettings) {
      this.giftCardState.type = this.wizardSettings.type;
      this.giftCardState.giftCardId = this.wizardSettings.giftCardId;
       this.giftCardState.giftCardLocale = this.wizardSettings.locale;
      this.giftCardState.variantId = this.wizardSettings.variantId;
      this.giftCardState.quantity = this.wizardSettings.quantity;
      this.addNewGiftCard();
    }
  },
  beforeUnmount() {
    this.$mitt.off(
      'giftCardEditor:saveProgressRequested',
      this.preSaveGiftCard
    );
    this.$mitt.off('giftCardEditor:saveProgressWithWarning', this.saveGiftCard);
    this.$mitt.off('giftCardEditor:openExitModal', this.openExitModal);
    this.$mitt.off('giftCardEditor:closeWarningModal', this.closeWarningModal);
    window.removeEventListener('resize', this.checkIfDesktopView);
  },
};
</script>

<template>
  <div :bind="$attrs" class="gift-card-editor" v-if="giftCardLoaded">
    <div class="gift-card-editor__header-background"></div>
    <div class="gift-card-editor__container">
      <navigation-menu
        v-show="hideWhenPreviewOnPhoneOnly"
        class="gift-card-editor__navigation-menu"
        :isFirstItem="isCurrentItemFirst"
        :isLastItem="isCurrentItemLast"
        @movePrev="selectPrevious"
        @moveNext="selectNext"
        @exit="openExitModal"
      />
      <progress-menu
        class="gift-card-editor__progress-menu"
        :isFirstItem="isCurrentItemFirst"
        :currentMenuIndex="currentMenuIndex()"
        @movePrev="selectPrevious"
        @openPreview="openPreview"
        @closePreview="closePreview"
        @exit="openExitModal"
      />
      <main-menu
        ref="giftcardMenu"
        v-show="showWhenPreviewOnPhoneOnly"
        class="gift-card-editor__main-menu"
        :selectedItem="currentMenuItem"
        :showBack="showWhenPreviewOnPhoneOnly"
        :showSettings="hideWhenPreviewOnPhoneOnly"
        @selectItem="selectMenuItem"
      />
      <div class="gift-card-editor__side-menu" v-if="!hideSideMenu">
        <front-menu
          class="gift-card-editor__front-menu"
          v-show="openSideMenu('menu-front')"
          @uploadImages="uploadCoverImages"
          @deleteImage="deleteCoverImage"
        />
        <inside-menu
          class="gift-card-editor__inside-menu"
          v-show="openSideMenu('menu-inside')"
          @setLogoType="uploadGiftCardLogoType"
          @deleteLogoType="deleteGiftCardLogoType"
        />
        <web-menu
          class="gift-card-editor__web-menu"
          v-show="openSideMenu('menu-web')"
          @uploadImages="uploadWebsiteImages"
          @deleteImage="deleteWebsiteImage"
          @setLogoType="uploadWebsiteLogoType"
          @deleteLogoType="deleteWebLogoType"
        />
        <gift-card-settings
          class="gift-card-editor__settings-menu"
          v-if="openSideMenu('menu-settings')"
        />
      </div>
      <preview-area
        v-if="showWhenPreviewOnPhoneOnly"
        :class="[
          'gift-card-editor__preview-area',
          { 'gift-card-editor__preview-area--center': hideSideMenu },
        ]"
        :preview-type="previewType"
      />
    </div>
  </div>
  <div class="gift-card-editor_loading-container" v-show="requestPending">
    <loading-spinner class="gift-card-editor_loading-spinner" :text="$globalTexts.giftcard__loading || 'giftcard__loading'" />
  </div>
  <div class="gift-card-editor__exit" v-if="showExit">
    <div class="gift-card-editor__exit-container">
      <h3 class="gift-card-editor__exit-question">
        {{ $globalTexts.giftcard__exit_question || 'giftcard__exit_question' }}
      </h3>
      <p class="gift-card-editor__exit-info">
        {{ $globalTexts.giftcard__exit_info || 'giftcard__exit_info' }}
      </p>
      <button
        class="gift-card-editor__exit-stay primary-button gift-card-editor__exit-button"
        @click="closeExitModal()"
      >
        {{ $globalTexts.giftcard__continue_edit || 'giftcard__continue_edit' }}
      </button>
      <button
        class="gift-card-editor__exit-leave gift-card-editor__exit-button"
        @click="discardGiftCard()"
      >
        {{ $globalTexts.giftcard__leave_editor || 'giftcard__leave_editor' }}
      </button>
    </div>
  </div>
  <warning-modal v-if="showWarning" @selectItem="selectMenuItem" />
</template>

<style>
.gift-card-editor_loading-container,
.gift-card-editor {
  width: 100%;
  position: relative;
  height: 90vh;
}

.gift-card-editor::after {
  inset: 0;
  z-index: -2;
  content: '';
  position: absolute;
  background: var(--color-neutrals-10);
}

.gift-card-editor__header-background {
  position: absolute;
  z-index: -1;
  height: 134px;
  left: 0;
  right: 0;
  top: 0;
  background: white;
  box-shadow: var(--shadow-outer-05);
}

.gift-card-editor__container {
  max-width: calc(var(--site-max-width) - 210px);
  padding: 0 10px;
  margin: auto;
  height: 100%;
  display: grid;
  grid-template-areas:
    'a b b c'
    'a d d d';
  grid-template-rows: 134px 1fr;
  grid-template-columns: 334px 1fr 1fr 334px;
  overflow: hidden;
}

.gift-card-editor__side-menu {
  margin-top: 32px;
  height: calc(100% - 56px);
  background: var(--color-white);
  padding: 1rem;
  width: 313px;
  box-shadow: var(--shadow-outer-05);
  overflow: hidden;
  grid-area: a;
}

.gift-card-editor__main-menu {
  grid-area: b;
}

.gift-card-editor__navigation-menu {
  grid-area: c;
}

.gift-card-editor__preview-area {
  grid-area: d;
}

.gift-card-editor__preview-area--center {
  grid-column: 2 / 4;
}

.gift-card-editor__exit {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  display: grid;
  place-content: center;
  z-index: 101;
}

.gift-card-editor__exit-container {
  background: var(--color-white);
  box-shadow: var(--shadow-outer-10);
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  padding: 2rem;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift-card-editor__exit-info {
  margin-top: 0;
}

.gift-card-editor__exit-leave {
  text-decoration: underline;
  font-weight: 700;
}

.gift-card-editor__exit-button {
  margin: 0.5rem;
}

button.gift-card-editor__exit-stay {
  border: 1px solid var(--color-neutrals-100);
}

@media (--phoneAndTablet) {
  .gift-card-editor {
    position: fixed;
    z-index: 110;
    inset: 0;
    height: 100%;
    width: 100%;
    background: #fff;
  }

  .gift-card-editor__container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .gift-card-editor__side-menu {
    padding: 0;
    margin: 0;
    flex-grow: 1;
    width: 100%;
    box-shadow: none;
  }

  .gift-card-editor__front-menu,
  .gift-card-editor__inside-menu,
  .gift-card-editor__web-menu,
  .gift-card-editor__settings-menu {
    max-width: 400px;
    margin: auto;
  }

  .gift-card-editor__header-background {
    display: none;
  }

  .gift-card-editor__exit-container {
    position: fixed;
    inset: 0;
    z-index: 120;
  }
}

@media (--desktop) {
  .gift-card-editor__progress-menu {
    display: none;
  }
}

.gift-card-editor_loading-spinner {
  padding-top: 5rem;
}
</style>
