/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { fetchPage } from '@drapejs/core';
import { defineCommand, defineReceiver } from '@drapejs/invoker';
import { gql } from 'graphql-request';
import { request, query } from '@motillo/drapejs-litium';

export const commands = {
  fetchMyOrders: defineCommand<{
    url: string;
  }>('fetchMyOrders'),
};

export const receivers = {
  fetchMyOrders: defineReceiver(
    commands.fetchMyOrders,
    async function (command, data) {
      const result = await request(
        this.cache,
        query(gql`
          query fetchMyOrders($url: String!) {
            session(url: $url) {
              orders {
                number
                date
                amount
                currency
                isInvoice
                noteOfGoods
              }
            }
          }
        `),
        {
          url: `${(<any>command).protocol}//${(<any>command).host}${
            (<any>command).path
          }`,
        },
      );

      return result.session.orders;
    },
    'litium',
  ),

  fetchMyOrder: defineReceiver(fetchPage, async function (command, data) {
    if (data?.type !== 'OrderDetails') {
      return data;
    }

    if (!(<any>command).query.orderNo && !(<any>command).query.invoiceNo) {
      data.status = 404;
      await this.cache.setItem((<any>command).cacheKey, data);
      return data;
    }

    const result = await request(
      this.cache,
      query(gql`
        query fetchMyOrder(
          $url: String!
          $invoiceNo: String
          $orderNo: String
        ) {
          session(url: $url) {
            orderDetails(orderNo: $orderNo, invoiceNo: $invoiceNo) {
              unauthorized
              no
              date
              currency
              noteOfGoods
              customer {
                name
                name2
                address
                address2
                postCode
                city
                country
                contactName
                contactPhone
                contactEmail
              }
              shipping {
                date
                agent
                name
                name2
                address
                address2
                postCode
                city
                country
                contactName
                contactPhone
                contactEmail
              }
              rows {
                lineNumber
                sku
                imageSystemId
                description
                description2
                quantity
                price
                vat
              }
              price
              vat
            }
          }
        }
      `),
      {
        url: `${(<any>command).protocol}//${(<any>command).host}${
          (<any>command).path
        }`,
        orderNo: (<any>command).query.orderNo,
        invoiceNo: (<any>command).query.invoiceNo,
      },
    );

    data.orderDetails = result.session.orderDetails;
    if (data.orderDetails?.unauthorized) {
      data.status = 401;
    }

    await this.cache.setItem((<any>command).cacheKey, data);

    return data;
  }),
};
