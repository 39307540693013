<script>
export default {
  emits: ['open'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    open() {
      this.$emit('open', this.order);
    },
  },
};
</script>

<template>
  <div class="my-pages-order">
    <div class="my-pages-order__header">
      <div>
        <div class="my-pages-order__date">
          {{ $globalTexts.mypages__order_list_date_label }} {{ order.date }}
        </div>
        <div class="my-pages-order__note_of_goods" v-if="order.noteOfGoods">
          {{ order.noteOfGoods }}
        </div>
        <div class="my-pages-order__number">
          {{ $globalTexts.mypages__order_list_number_label }} {{ order.number }}
        </div>
      </div>

      <button class="secondary-button my-pages-order__button" @click="open">
        {{ $globalTexts.mypages__order_list_open_label }}
      </button>
    </div>

    <div class="my-pages-order__total">
      <span>{{ $globalTexts.mypages__order_list_total_label }}</span>
      <span>{{ order.amount }} {{ order.currency }}</span>
    </div>
  </div>
</template>

<style>
.my-pages-order {
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutrals-00);
  box-shadow: 4px 5px 20px rgba(184, 204, 214, 0.3);
  border-radius: 3px;
}

.my-pages-order__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1.5px solid #f2f2f2;
  padding: 10px 14px;
}

.my-pages-order__date,
.my-pages-order__note_of_goods {
  color: #686868;
  font-family: Mulish;
  font-size: 13px;
  line-height: 21px;
}

.my-pages-order__number {
  color: #000000;
  font-family: Mulish;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
}

.my-pages-order__button.secondary-button {
  padding: 13px 27px;
}

.my-pages-order__total {
  font-family: Mulish;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #181516;
  display: flex;
  justify-content: space-between;
  padding: 10px 14px;
}
</style>
