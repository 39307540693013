<script>
import ImageUpload from './ImageUpload.vue';

export default {
  components: {
    ImageUpload,
  },
  emits: ['setLogoType', 'deleteLogoType'],
  inject: {
    $giftCardStateReactive: '$giftCardStateReactive',
    $imageLimits: '$imageLimits',
  },
  computed: {
    logoTypeId() {
      return this.$giftCardStateReactive?.webLogoTypeSystemId || '';
    },
  },
};
</script>

<template>
  <div class="web-menu-logotype">
    <ImageUpload
      @setImage="$emit('setLogoType', $event)"
      @deleteImage="$emit('deleteLogoType', $event)"
      :imageId="logoTypeId"
      :sizeLimitInMB="$imageLimits.websiteLogotypeSizeLimit"
      :recommendation="$globalTexts.giftcard__logo_size_recommendation || 'giftcard__logo_size_recommendation'"
      :upload="$globalTexts.giftcard__upload_logotype_text || 'giftcard__upload_logotype_text'"
    />
  </div>
</template>
