<script>
export default {
  emits: ['closeTypeSelection'],
  inject: {
    giftCards: '$giftCardsReactive',
    $giftCardStateReactive: '$giftCardStateReactive',
    changeVariant: 'changeVariant',
  },
  data: () => ({
    selectedGiftCardType: null,
    selectedVariant: null,
  }),
  computed: {
    locale() {
      return this.$giftCardStateReactive?.giftCardLocale || '';
    },
    giftCardTypes() {
      return (this.giftCards || []).filter((g) => g.variants
        ?.filter((v) => v.locale === this.locale)?.length > 0) || [];
    },
    variants() {
      const giftCardType = this.selectedGiftCardType || this.giftCards?.find((g) => g.isSelected);
      return (
        giftCardType?.variants?.filter((v) => v.locale === this.locale) || []
      );
    },
    customOption() {
      return this.$globalTexts.giftcard__custom || 'giftcard__custom';
    },
  },
  methods: {
    isGiftCardSelected(giftCardType) {
      return (
        this.selectedGiftCardType?.giftCardTypeName
        === giftCardType.giftCardTypeName
      );
    },
    isVariantSelected(variant) {
      return this.selectedVariant?.variantId === variant.variantId;
    },
    formatVariantValue(variant) {
      return this.selectedGiftCardType?.giftCardTypeName === 'GetMyGift'
        ? variant.displayName
        : this.$formatPrice(variant.price, 2, variant.locale, variant.currencyId);
    },
    setGiftCardType(giftCardType) {
      this.selectedGiftCardType = giftCardType;
      this.setVariant(this.variants[0]);
    },
    setVariant(variant) {
      this.selectedVariant = variant;
    },
    async changeSelection() {
      await this.changeVariant(this.selectedVariant);
      this.$emit('closeTypeSelection');
    },
  },
  mounted() {
    this.selectedGiftCardType = this.giftCardTypes?.find((f) => f.isSelected);
    this.selectedVariant = this.selectedGiftCardType?.variants?.find((v) => v.isSelected);
  },
};
</script>

<template>
  <div class="type-selection">
    <h5 class="type-selection-title gift-card-settings__title">
      {{ $globalTexts.giftcard__change_type || 'giftcard__change_type' }}
    </h5>
    <div class="type-selection__tabs" ref="tabs">
      <span
        v-for="giftCardType in giftCardTypes"
        :key="giftCardType.giftCardTypeName"
        :class="[
          'type-selection__tab',
          { 'type-selection__tab--selected': isGiftCardSelected(giftCardType) },
        ]"
        @click="setGiftCardType(giftCardType)"
      >
        {{ giftCardType.giftCardTypeName }}
      </span>
    </div>
    <div class="type-selection__vh type-selection__options">
      <button
        v-for="variant in variants"
        :key="variant"
        :class="[
          'type-selection__option',
          'secondary-button',
          { 'type-selection__option--selected': isVariantSelected(variant) },
        ]"
        @click="setVariant(variant)"
      >
        {{ formatVariantValue(variant) }}
      </button>
      <!-- <button
        :class="[
          'type-selection__option',
          'secondary-button',
          {
            'type-selection__option--selected': customOption == selectedOption,
          },
        ]"
        @click="setOption(customOption)"
      >
        {{ customOption }}
      </button>
      <label v-if="customOption == selectedOption">
        {{ $globalTexts.giftcard__specify_custom_value }}
        <input
          class="type-selection__custom-value"
          type="number"
          v-model="customValue"
          min="1"
          max="9999"
          placeholder="500"
        />
      </label> -->
    </div>
    <button class="type-selection__back-button" @click="changeSelection()">
      <img
        class="type-selection__back-icon"
        src="../../../static/icons/arrow_left_icon.svg"
      />
      <span>{{ $globalTexts.global__save || 'global__save' }}</span>
    </button>
  </div>
</template>

<style>
.type-selection {
  padding: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: center;
  position: absolute;
  inset: 0;
  z-index: 1;
  overflow: hidden auto;
}

.type-selection__tabs {
  display: flex;
  gap: 10px;
  font-size: 14px;
  line-height: 22px;
  color: var(--color-neutrals-60);
  cursor: pointer;
}

.type-selection__tab--selected {
  color: var(--color-text-darker);
  position: relative;
  pointer-events: none;
}

.type-selection__tab--selected::after {
  position: absolute;
  content: "";
  left: 0;
  top: calc(100% + 1px);
  width: 100%;
  border-bottom: 1px solid var(--color-text-darker);
}

.type-selection__options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.type-selection__option {
  max-width: 50%;
  width: 160px;
}

button.type-selection__option--selected {
  color: var(--color-neutrals-00);
  background-color: var(--color-neutrals-100);
}

.type-selection__custom-value {
  text-align: center;
}

.type-selection__custom-value:invalid,
.type-selection__custom-value:focus:invalid {
  color: var(--color-feedback-error);
  border-color: var(--color-feedback-error);
}

.type-selection__custom-value::-webkit-outer-spin-button,
.type-selection__custom-value::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.type-selection__custom-value[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.type-selection__back-button {
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.type-selection__back-icon {
  width: 16px;
  margin-right: 10px;
}
</style>
