<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
    showCircle: {
      type: Boolean,
      default: true,
    },
  },
  inject: ["closeNavigation"],
  computed: {
    circleStyle() {
      const cssValue = `${this.size}px`;
      return {
        width: cssValue,
        height: cssValue,
      };
    },
    imageUrl() {
      return this.$toLitiumMediaUrl(this.category.imageId, {
        fillArea: { width: this.size, height: this.size },
      });
    },
    hasImage() {
      return this.category?.imageId;
    },
  },
});
</script>

<template>
  <router-link
    class="navigation-phone-menu-cicle"
    :to="category.url"
    @click="closeNavigation()"
  >
    <div v-if="showCircle">
      <img
        class="navigation-phone-menu-cicle__image"
        :src="imageUrl"
        :style="circleStyle"
        v-if="hasImage"
      />
      <img
        class="
          navigation-phone-menu-cicle__image
          navigation-phone-menu-cicle__image--missing
        "
        src="../../static/images/missing_image_phone.svg"
        :style="circleStyle"
        v-else
      />
    </div>

    <div class="navigation-phone-menu-cicle__label">{{ category.name }}</div>
  </router-link>
</template>

<style>
.navigation-phone-menu-cicle {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.navigation-phone-menu-cicle__label {
  font-size: 14px;
}

.navigation-phone-menu-cicle__image {
  border-radius: 50%;
  margin-bottom: 13px;
}

.navigation-phone-menu-cicle__image--missing {
  object-fit: cover;
}
</style>