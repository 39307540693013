<script>
import { fetchPageBuilder } from '@drapejs/core';
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import MyPagesBack from './MyPagesBack.vue';
import ErrorMessage from '../../forms/ErrorMessage.vue';
import PhoneInput from '../../forms/PhoneInput';

import { validateEmail } from '../../../utils.ts';

import * as user from '../../../../connectors/litium/user';

export default {
  components: {
    MyPagesBack,
    ErrorMessage,
    PhoneInput,
  },
  emits: ['saved', 'close-modal', 'back'],
  props: {
    contactData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      no: '',
      code: '',
      systemId: '',
      firstName: '',
      firstNameError: '',
      lastName: '',
      lastNameError: '',
      email: '',
      emailError: '',
      phone: '',
      phoneError: '',
      saveError: '',
      requestPending: false,
    };
  },
  computed: {
    phoneCountry() {
      if (this.phoneNumber) {
        const prefix = this.phoneNumber.split(' ')[0].replace('+', '');
        const matchingPrefix = this.$globalFields?.PhonePrefixes.find(
          (p) => p.prefix === prefix
        );

        if (matchingPrefix) {
          return matchingPrefix.country;
        }
      }

      return this.$cart.countryId;
    },
  },
  methods: {
    validate() {
      let isValid = true;

      if (!this.firstName) {
        this.firstNameError = this.$globalTexts.global__required_field;
        isValid = false;
      }

      if (!this.lastName) {
        this.lastNameError = this.$globalTexts.global__required_field;
        isValid = false;
      }

      if (!this.email) {
        this.emailError = this.$globalTexts.global__required_field;
        isValid = false;
      }

      if (!validateEmail(this.email)) {
        this.emailError = this.$globalTexts.global__email_invalid;
        isValid = false;
      }

      if (!this.phone) {
        this.phoneError = this.$globalTexts.global__required_field;
        isValid = false;
      }

      if (!isValid) {
        return false;
      }

      try {
        if (!isValidPhoneNumber(this.phone, this.phoneCountry)) {
          this.phoneError = this.$globalTexts.global__invalid_phone;
          return false;
        }
      } catch (err) {
        this.phoneError = this.$globalTexts.global__invalid_phone;
        return false;
      }

      return true;
    },
    async save() {
      this.saveError = '';

      if (!this.validate()) {
        return;
      }

      await this.addOrUpdateContact();
    },
    async addOrUpdateContact() {
      const requestArgs = this.buildCommandArgs({
        no: this.no,
        code: this.code,
        systemId: this.systemId,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        phone: this.phone,
      });

      const response = await this.executeCommand(
        user.commands.addOrUpdateContact,
        requestArgs,
      );

      switch (response.error) {
        case 'FAILED':
          this.saveError = this.$globalTexts.global__save_error;
          break;

        default:
          this.$emit('saved');
          break;
      }
    },
    closeModal() {
      this.$emit('close-modal');
    },
    onBack() {
      this.$emit('back');
    },
    clearError(error) {
      this[error] = '';
    },
    updatePhoneNumber(phoneNumber) {
      this.phone = phoneNumber;
      this.phoneError = '';
    },
    buildCommandArgs(formArgs) {
      return {
        ...formArgs,
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          '',
        ),
      };
    },
    async executeCommand(command, args) {
      try {
        this.requestPending = true;
        const response = await this.$invoke(command, args);
        return response;
      } catch (err) {
        return [];
      } finally {
        this.requestPending = false;
      }
    },
  },
  created() {
    this.no = this.contactData.no;
    this.code = this.contactData.code;
    this.systemId = this.contactData.systemId;
    this.firstName = this.contactData.firstName;
    this.lastName = this.contactData.lastName;
    this.email = this.contactData.email;
    this.phone = this.contactData.phone;
  },
};
</script>

<template>
  <div class="contact-edit">
    <div class="contact-edit__main">
      <my-pages-back @back="onBack()" @exit="closeModal()" />
      <div class="contact-edit__content">
        <div>
          <div class="contact-edit__title">
            {{ $globalTexts.mypages__contact }}
          </div>
          <div class="contact-edit__customer_contact_number_group">
            <div class="contact-edit__customer_contact_number">
              {{ $globalTexts.mypages__company_nav_customer_contact_number }}
            </div>
            <div class="contact-edit__customer_contact_number_value">
              {{ $globalTexts.mypages__company_nav_customer_contact_number ? this.code : "" }}
            </div>
          </div>
        </div>
        <div class="contact-edit__field-group">
          <label class="contact-edit__label" for="firstName">{{
            $globalTexts.global__first_name
          }}</label>
          <input
            type="text"
            id="firstName"
            class="contact-edit__input"
            v-model="firstName"
            @input="clearError('firstNameError')"
          />
          <error-message v-if="firstNameError" :message="firstNameError" />
        </div>

        <div class="contact-edit__field-group">
          <label class="contact-edit__label" for="lastName">{{
            $globalTexts.global__last_name
          }}</label>
          <input
            type="text"
            id="lastName"
            class="contact-edit__input"
            v-model="lastName"
            @input="clearError('lastNameError')"
          />
          <error-message v-if="lastNameError" :message="lastNameError" />
        </div>

        <div class="contact-edit__field-group">
          <label class="contact-edit__label" for="email">{{
            $globalTexts.global__email
          }}</label>
          <input
            type="text"
            id="email"
            class="contact-edit__input"
            v-model="email"
            @input="clearError('emailError')"
          />
          <error-message v-if="emailError" :message="emailError" />
        </div>

        <div class="contact-edit__field-group">
          <label class="contact-edit__label" for="phone">{{
            $globalTexts.global__phone
          }}</label>
          <phone-input
            class="contact-edit__phone-input"
            :value="phone"
            :selected-country="$cart.countryId"
            :placeholder="$globalTexts.global__phone"
            :error-message="phoneError"
            @input="updatePhoneNumber"
          />
        </div>
      </div>
    </div>

    <div class="contact-edit__content">
      <button
        class="primary-button contact-edit__button-wide"
        @click="save"
        :disabled="requestPending"
      >
        {{ $globalTexts.global__save }}
      </button>
      <error-message v-if="saveError" :message="saveError" />
    </div>
  </div>
</template>

<style>
.contact-edit {
  display: flex;
  flex-direction: column;
}

.contact-edit__main {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.contact-edit__content {
  padding: 0px 10px 10px;
}

.contact-edit__title {
  font-family: Mulish;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--color-black);
}

.contact-edit__customer_contact_number_group{
  display: flex;
  margin-bottom: 15px;
}

.contact-edit__customer_contact_number {
    font-size: 14px;
    line-height: 24px;
    color: #686868;
}

.contact-edit__customer_contact_number_value {
    font-size: 14px;
    line-height: 24px;
    color: #686868;
    padding-left: 5px;
}

.contact-edit__field-group {
  margin-bottom: 14px;
}

.contact-edit__label {
  font-family: Mulish;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #1a1a1a;
  margin-bottom: 6px;
}

.contact-edit__input {
  color: #333333;
  margin-top: 6px;
}

.contact-edit__phone-input {
  flex: unset;
  width: 100%;
}

.contact-edit__phone-input:nth-child(odd) {
  margin: 0 0 0.75rem 0;
}

.contact-edit__button-wide {
  width: 100%;
}

@media (min-width: 768px) {
  .contact-edit__content {
    padding: 0px 30px 20px;
  }
}
</style>
