<script>
export default {
  props: {
    isLastItem: {
      type: Boolean,
      required: true,
    },
    isFirstItem: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["movePrev", "moveNext", "exit"],
  inject: {
    $giftCardStateReactive: "$giftCardStateReactive",
    $giftCardsReactive: "$giftCardsReactive",
  },
  computed: {
    hasAnyGiftCards() {
      return this.$giftCardsReactive?.length > 0;
    },
    finishButtonText() {
      return !this.$giftCardStateReactive?.orderRowSystemId
        ? this.$globalTexts.global__add_to_cart || 'global__add_to_cart'
        : this.$globalTexts.giftcard__save_changes || 'giftcard__save_changes';
    },
    previousButtonText() {
      return this.isFirstItem
        ? this.$globalTexts.giftcard__exit || 'giftcard__exit'
        : this.$globalTexts.giftcard__previous || 'giftcard__previous';
    },
  },
  methods: {
    openSaveInEditor() {
      if(this.hasAnyGiftCards){
        this.$mitt.emit("giftCardEditor:saveProgressRequested");
      }      
    },
    previousOrExit() {
      if (this.isFirstItem) {
        this.$emit("exit");
      } else {
        this.$emit("movePrev");
      }
    },
  },
};
</script>
<template>
  <div class="navigation-menu">
    <div class="navigation-menu__prev-button" @click="previousOrExit()">
      <img
        src="../../static/icons/arrow_left_icon.svg"
        class="navigation-menu__prev-icon"
      />
      <span>{{ previousButtonText }}</span>
    </div>
    <div
      v-show="!isLastItem"
      class="primary-button navigation-menu__next-button"
      @click="$emit('moveNext')"
    >
      {{ $globalTexts.checkout__next || 'checkout__next' }}
      <img
        src="../../static/icons/scroll_arrow_white_right.svg"
        class="navigation-menu__next-icon"
      />
    </div>
    <button
      v-show="isLastItem"
      class="primary-button navigation-menu__add-to-cart-button"
      :disabled="!hasAnyGiftCards"
      @click="openSaveInEditor()"
    >
      {{ finishButtonText }}
    </button>
  </div>
</template>
<style>
.navigation-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;
  flex-shrink: 1;
  background: var(--color-white);
  user-select: none;
}
.navigation-menu__prev-button {
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
}
.navigation-menu__next-button {
  font-size: 16px;
  position: relative;
  width: 142px;
}
.navigation-menu__prev-icon {
  width: 16px;
  margin-right: 10px;
}
.navigation-menu__next-icon {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
}

@media (--phoneAndTablet) {
  .navigation-menu {
    padding: 1rem;
    border-bottom: 1px solid var(--color-border-50);
  }
  .navigation-menu__prev-button {
    display: none;
  }
  .navigation-menu__next-button,
  .navigation-menu__finish-button {
    width: 100%;
    max-width: 450px;
    margin: auto;
  }
}
</style>
