<script>
import { defineComponent } from 'vue';
import SiteFooterExpandLabel from './SiteFooterExpandLabel.vue';
import SiteFooterNewsletterSignup from './SiteFooterNewsletterSignup.vue';

const sectionKeys = {
  column1: 'column1',
  column2: 'column2',
  legal: 'legal',
  newsletter: 'newsletter',
};

export default defineComponent({
  data() {
    return {
      expandedSections: [],
      sectionKeys,
    };
  },
  components: {
    SiteFooterExpandLabel,
    SiteFooterNewsletterSignup,
  },
  computed: {
    rootPath() {
      return this.$channel?.rootPath;
    },
    logoUrl() {
      const imageId = this.frameworkContent.Logotype?.id;
      if (!imageId) return '';
      return this.$toLitiumMediaUrl(imageId, { maxHeight: 18 });
    },
    frameworkContent() {
      return this.$channel?.frameworkContentJson || {};
    },
    socialLinks() {
      const links = this.frameworkContent.SocialMediaLinks || [];
      return links.map((l) => ({
        name: l.SocialMediaName,
        url: l.SocialMediaUrl,
      }));
    },
    column1Title() {
      return this.frameworkContent.FooterColumn1Title;
    },
    column1Links() {
      return this.frameworkContent.FooterColumn1Links?.map(
        this.mapColumnLinkToLink
      );
    },
    column2Title() {
      return this.frameworkContent.FooterColumn2Title;
    },
    column2Links() {
      return this.frameworkContent.FooterColumn2Links?.map(
        this.mapColumnLinkToLink
      );
    },
    hasPushInfo() {
      return this.footerCtaTextHtml && this.footerCtaLabel && this.footerCtaUrl;
    },
    footerCtaTextHtml() {
      const content = this.frameworkContent.FooterCTAText;
      if (!content) return null;
      return this.$renderMarkdown(content);
    },
    footerCtaLabel() {
      return this.frameworkContent.FooterCTALabel || null;
    },
    footerCtaUrl() {
      return this.frameworkContent.CompositeLink_FooterCTAUrl?.url || null;
    },
    legalLinks() {
      return (
        this.frameworkContent?.LegalLinks?.map(this.mapColumnLinkToLink) || []
      );
    },
  },
  methods: {
    mapColumnLinkToLink(columnLink) {
      return {
        name: columnLink.fields._name,
        url: columnLink.url,
      };
    },
    isSectionExpanded(key) {
      return this.expandedSections.indexOf(key) !== -1;
    },
    toggleSection(key) {
      if (this.isSectionExpanded(key)) {
        this.expandedSections = this.expandedSections.filter((f) => f !== key);
      } else {
        this.expandedSections.push(key);
      }
    },
  },
});
</script>

<template>
  <div class="site-footer">
    <div class="site-footer__section">
      <div class="site-footer__header">
        <div class="size-footer__logo-container">
          <img class="size-footer__logo" v-if="logoUrl" :src="logoUrl" />
          <a
            class="size-footer__phone-social-link"
            v-for="link in socialLinks"
            :key="link.name"
            :href="link.url"
            target="_blank"
            >{{ link.name }}</a
          >
        </div>
        <div
          v-if="hasPushInfo"
          class="site-footer__push site-footer__phone-hide"
        >
          <div
            class="site-footer__push-content text-editor-content"
            v-html="footerCtaTextHtml"
          ></div>
          <div class="site-footer__push-cta">
            <router-link v-if="footerCtaUrl" class="secondary-button" :to="footerCtaUrl">{{
              footerCtaLabel
            }}</router-link>
            <div v-else class="secondary-button">{{
              footerCtaLabel
            }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="site-footer__content">
      <div class="site-footer__nav">
        <div class="site-footer__nav-column site-footer__nav-column1">
          <site-footer-expand-label
            v-if="column1Title"
            class="site-footer__phone-only"
            :label="column1Title"
            :active="isSectionExpanded(sectionKeys.column1)"
            @click="toggleSection(sectionKeys.column1)"
          />
          <div class="site-footer__nav-column-title site-footer__phone-hide">
            {{ column1Title }}
          </div>
          <ul
            :class="{
              'site-footer__phone-hide': !isSectionExpanded(
                sectionKeys.column1
              ),
            }"
          >
            <li v-for="link in column1Links" :key="link.name">
              <router-link v-if="link" :to="link.url">{{ link.name }}</router-link>
              <div v-else>{{ link.name }}</div>
            </li>
          </ul>
        </div>
        <div class="site-footer__nav-column site-footer__nav-column2">
          <site-footer-expand-label
            class="site-footer__phone-only"
            v-if="column2Title"
            :label="column2Title"
            :active="isSectionExpanded(sectionKeys.column2)"
            @click="toggleSection(sectionKeys.column2)"
          />
          <div class="site-footer__nav-column-title site-footer__phone-hide">
            {{ column2Title }}
          </div>
          <ul
            :class="{
              'site-footer__phone-hide': !isSectionExpanded(
                sectionKeys.column2
              ),
            }"
          >
            <li v-for="(link, idx) in column2Links" :key="idx">
              <router-link v-if="link.url" :to="link.url">{{
                link.name
              }}</router-link>
              <div v-else>{{ link.name }}</div>
            </li>
          </ul>
        </div>
        <div class="site-footer__nav-column site-footer__phone-hide">
          <div class="site-footer__nav-column-title">
            {{ $globalTexts.footer__social_title }}
          </div>
          <ul>
            <li v-for="(link, idx) in socialLinks" :key="idx">
              <a v-if="link.url" :href="link.url" target="_blank">{{
                link.name
              }}</a>
              <div v-else>{{ link.name }}</div>
            </li>
          </ul>
        </div>
        <div class="site-footer__nav-column site-footer__nav-column-legal">
          <site-footer-expand-label
            class="site-footer__phone-only"
            label="Legal"
            :active="isSectionExpanded(sectionKeys.legal)"
            @click="toggleSection(sectionKeys.legal)"
            v-if="legalLinks.length > 0"
          />
          <div class="site-footer__nav-column-title site-footer__phone-hide">
            {{ $globalTexts.footer__legal_title }}
          </div>
          <ul
            :class="{
              'site-footer__phone-hide': !isSectionExpanded(sectionKeys.legal),
            }"
          >
            <li v-for="(link, idx) in legalLinks" :key="idx">
              <a v-if="link.url" :href="link.url" target="_blank">{{
                link.name
              }}</a>
              <div v-else>{{ link.name }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="site-footer__newsletter">
        <site-footer-expand-label
          class="site-footer__phone-only"
          v-if="$globalTexts.footer__newsletter_title"
          :label="$globalTexts.footer__newsletter_title"
          :active="isSectionExpanded(sectionKeys.newsletter)"
          @click="toggleSection(sectionKeys.newsletter)"
        />
        <div
          :class="{
            'site-footer__phone-hide': !isSectionExpanded(
              sectionKeys.newsletter
            ),
          }"
        >
          <site-footer-newsletter-signup
            :description="frameworkContent.NewsletterDescription"
          />
        </div>
      </div>
      <div
        v-if="hasPushInfo"
        class="site-footer__push-phone site-footer__phone-only"
      >
        <div class="site-footer__push-content" v-html="footerCtaTextHtml"></div>
        <div class="site-footer__push-cta">
          <router-link
            class="secondary-button"
            v-if="footerCtaUrl"
            :to="footerCtaUrl"
            >{{ footerCtaLabel }}</router-link
          >
          <div v-else>{{ footerCtaLabel }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.site-footer {
  margin: 15px 0 42px;
}

.site-footer__header,
.site-footer__content {
  max-width: calc(var(--header-max-width) + 30px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.site-footer__header,
.site-footer__content {
  padding: 0 15px;
}

.size-footer__logo {
  display: block;
  width: 100px;
  margin-bottom: 11px;
}

.site-footer__nav {
  display: flex;
  flex-direction: column;
}

a.size-footer__phone-social-link {
  display: inline-block;
  color: #282828;
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
}

.size-footer__phone-social-link::after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 21px;
  margin: 0 10px;
  vertical-align: middle;
  background-color: #282828;
}

.size-footer__phone-social-link:last-child::after {
  display: none;
}

.site-footer__push-phone {
  border-top: 1px solid var(--color-neutrals-20);
  margin: 0 -15px;
  padding: 25px 15px 0;
}

.site-footer__push-phone .site-footer__push-content p {
  margin: 0;
  margin-bottom: 15px;
}

.site-footer__push-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.site-footer__push-phone .site-footer__push-cta a {
  width: 100%;
  margin-top: 15px;
}

.site-footer__section {
  margin-bottom: 18px;
}

.site-footer a {
  color: inherit;
  text-decoration: none;
}

.site-footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.site-footer__nav-column {
  font-size: 14px;
  flex-grow: 1;
}

.site-footer__nav-column a {
  padding: 15px 0;
  display: block;
}

.site-footer__nav-column-title {
  color: var(--color-neutrals-60);
  font-size: 14px;
  font-weight: bold;
}

@media (--phone) {
  .site-footer__phone-hide {
    display: none;
  }

  .size-footer__logo {
    height: 18px;
    width: 95px;
    margin-bottom: 20px;
  }

  .site-footer__nav-column,
  .site-footer__newsletter svg {
    margin-right: -2px;
  }
}

@media (--tabletAndDesktop) {
  .site-footer__header {
    align-items: center;
  }
  .site-footer__section {
    border-top: 1px solid var(--color-neutrals-20);
    border-bottom: 1px solid var(--color-neutrals-20);
    margin-bottom: 0px;
  }

  .size-footer__logo {
    margin-bottom: 0;
  }

  .size-footer__logo-container,
  .site-footer__newsletter {
    width: 35%;
    margin-right: 5%;
  }

  .site-footer__push,
  .site-footer__nav {
    width: 60%;
  }

  .site-footer__push {
    display: flex;
  }

  .site-footer__push-content {
    font-size: 14px;
    margin: 15px 0;
    line-height: 24px;
    color: #1a1a1a;
    /* flex-grow: 1; */
  }

  .site-footer__nav {
    flex-direction: row;
    order: 1;
  }

  .site-footer__newsletter {
    order: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: black;
  }
  .site-footer__newsletter p {
    margin: 0;
  }

  .site-footer__phone-only {
    display: none;
  }

  .site-footer__content,
  .site-footer__header {
    flex-direction: row;
  }

  .site-footer__content {
    padding-top: 42px;
  }

  a.size-footer__phone-social-link {
    display: none;
  }

  .site-footer__push-cta a {
    padding: 0 53px;
    white-space: nowrap;
    margin-left: 40px;
  }

  .site-footer__nav-column-title {
    margin-bottom: 7px;
  }

  .site-footer__nav-column {
    font-size: 12px;
  }

  .site-footer__nav-column a {
    padding: 5px 0;
    display: block;
  }

  .site-footer__push-content p {
    margin: 0;
  }
}
</style>
