import { inject } from 'vue';

import { invokeKey, routeKey, getItemKey } from '@drapejs/core';
import { sales } from '@motillo/drapejs-litium';
import * as vingaSales from '../../connectors/litium/sales';

export function useCartFunctions() {
  const $invoke = inject(invokeKey);
  const $route = inject(routeKey);
  const $getItem = inject(getItemKey);

  return {
    addItemToCart: async function (
      variantData: VariantData,
      printData: PrintData
    ) {
      var $cart = await $getItem?.('__cart');
      var beforeRows = Object.assign(
        {},
        ...($cart?.rows || []).map((x: { id: string }) => ({ [x.id]: x }))
      );

      const request = <any>{};

      request.articleNumber = variantData.articleNumber;
      request.quantity = variantData.quantity;
      request.variantSystemId = variantData.variantSystemId;
      request.variants = variantData.variants;
      request.url = `${$route?.protocol}//${$route?.host}${$route?.pathname}`;
      request.giftCardInfo = JSON.stringify(variantData.giftCardInfo);
      if (printData) {
        request.printVariantSystemId = printData.printVariantSystemId;
        request.printProductSystemId = printData.printProductSystemId;
        request.printImageSystemId = printData.printImageSystemId;
        request.printText = printData.printText;
        request.printAddons = JSON.stringify(printData.printAddons);
        request.printPosition = printData.printPosition;
        request.printPreviewDataUrl = printData.printPreviewDataUrl;
        request.desiredWidth = printData.desiredWidth;
        request.desiredHeight = printData.desiredHeight;
        request.colors = printData.colors;
      }

      const { row, error } = await $invoke?.(
        printData ? vingaSales.commands.addPrintToCart : sales.addToCart,
        request
      );

      $cart = await $getItem?.('__cart');

      var afterRows = Object.assign(
        {},
        ...($cart?.rows || []).map((x: { id: string }) => ({ [x.id]: x }))
      );

      var beforeRowIds = Object.getOwnPropertyNames(beforeRows);
      var afterRowIds = Object.getOwnPropertyNames(afterRows);
      var allRowIds = [...new Set(beforeRowIds.concat(afterRowIds))];

      for (var i = 0; i < allRowIds.length; i++) {
        var rowId = allRowIds[i];
        reportCartToDataLayer(
          $cart.currency.id,
          afterRows[rowId],
          beforeRows[rowId]
        );
      }

      return { row, error };
    },

    setQuantityOnRow: async function (rowId: string, quantity: number) {
      var $cart = await $getItem?.('__cart');
      var beforeRow = Object.assign(
        {},
        $cart?.rows?.find((r: { id: string }) => r.id == rowId)
      );

      const request = <any>{};

      request.rowId = rowId;
      request.quantity = quantity;
      request.url = `${$route?.protocol}//${$route?.host}${$route?.pathname}`;

      const result = await $invoke?.(sales.setRowQuantity, request);

      $cart = await $getItem?.('__cart');
      var afterRow = Object.assign(
        {},
        $cart.rows?.find((r: { id: string }) => r.id == rowId)
      );

      reportCartToDataLayer($cart.currency.id, afterRow, beforeRow);

      return result;
    },

    removeGroupFromCart: async function (groupId: string, rowId: string) {
      var $cart = await $getItem?.('__cart');
      var beforeRow = Object.assign(
        {},
        $cart?.rows?.find((r: { id: string }) => r.id == rowId)
      );

      const request = <any>{};

      request.groupId = groupId;
      request.url = `${$route?.protocol}//${$route?.host}${$route?.pathname}`;

      const result = await $invoke?.(
        vingaSales.commands.removeGroupFromCart,
        request
      );

      $cart = await $getItem?.('__cart');
      var afterRow = Object.assign(
        {},
        $cart.rows?.find((r: { id: string }) => r.id == rowId)
      );

      reportCartToDataLayer($cart.currency.id, afterRow, beforeRow);

      return result;
    }
  };
}

declare var dataLayer: any;

function reportCartToDataLayer(
  currency: string,
  afterRow: any,
  beforeRow: any
) {
  if (typeof dataLayer === 'undefined') {
    return;
  }

  const before = beforeRow?.quantity;
  const after = afterRow?.quantity;

  let diff = 0;
  if (before && !after) {
    diff = before * -1;
  } else if (before && after) {
    diff = after - before;
  } else if (!before && afterRow) {
    diff = after;
  }

  if (diff > 0) {
    var item = buildItemForGa(afterRow, diff);

    dataLayer.push({
      event: 'add_to_cart',
      currency: currency,
      value: afterRow.unitListPrice * diff,
      items: [item]
    });
  } else if (diff < 0) {
    var item = buildItemForGa(beforeRow, diff * -1);

    dataLayer.push({
      event: 'remove_from_cart',
      currency: currency,
      value: beforeRow.unitListPrice * (diff * -1),
      items: [item]
    });
  }
}

function buildItemForGa(row: any, quantity: number) {
  var item = {
    item_id: row.articleNumber,
    item_name: row.googleAnalyticsName,
    discount: (row.unitListPrice - row.unitPrice) * quantity,
    price: row.unitPrice,
    quantity: quantity
  };

  var categoryPath = row.googleAnalyticsCategoryPath || [];
  for (var i = 0; i < Math.min(5, categoryPath.length); i++) {
    if (i == 0) {
      (<any>item)['item_category'] = categoryPath[i];
    } else {
      (<any>item)[`item_category${i + 1}`] = categoryPath[i];
    }
  }

  return item;
}

interface VariantData {
  articleNumber: string;
  variantSystemId: string;
  quantity: number;
  variants: any;
  giftCardInfo: any;
}

interface PrintData {
  printVariantSystemId: string;
  printProductSystemId: string;
  printImageSystemId: string;
  printText: string;
  printAddons: any;
  printPosition: string;
  printPreviewDataUrl: string;
  desiredWidth: number;
  desiredHeight: number;
  colors: string;
}
