<script>
import { defineComponent } from "vue";
import NavigationPhone from "./NavigationPhone.vue";
import NavigationPhoneArrowIcon from './NavigationPhoneArrowIcon.vue';

export default defineComponent({
    components: {
        NavigationPhone,
        NavigationPhoneArrowIcon
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        hasChildren: {
            type: Boolean,
            default: false,
        },
    },
});
</script>

<template>
    <div class="navigation-phone-menu-item">
        {{label}}
        <navigation-phone-arrow-icon v-if="hasChildren" />
    </div>
</template>

<style>
.navigation-phone-menu-item {
    display: flex;
    justify-content: space-between;
    height: 42px;
    align-items: center;
    font-weight: bold;
    margin-left: 14px;
}
</style>