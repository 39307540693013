<script>
import { fetchPageBuilder, navigateKey } from '@drapejs/core';
import MyPagesBack from './components/MyPagesBack.vue';
import MyPagesOrder from './components/MyPagesOrder.vue';

import * as myPages from '../../../connectors/litium/my-pages';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default {
  components: {
    MyPagesBack,
    MyPagesOrder
  },
  emits: ['close-modal', 'back'],
  inject: {
    navigate: navigateKey
  },
  data() {
    return {
      searchText: '',
      selectedSort: 'recent',
      orderCache: [],
      orders: [],
      requestPending: false,
      dropdownOpen: false
    };
  },
  setup() {
    const { websiteText } = useWebsiteTexts();
    return { websiteText };
  },
  computed: {
    selectedSortName() {
      switch (this.selectedSort) {
        case 'recent':
          return this.$globalTexts.mypages__sort_order_date;

        case 'alpha':
          return this.$globalTexts.mypages__sort_order_id;

        default:
          return this.$globalTexts.mypages__sort_order_date;
      }
    },
    isRecentSortSelected() {
      return this.selectedSort === 'recent';
    },
    isAlphaSortSelected() {
      return this.selectedSort === 'alpha';
    },
    orderDetailsPageUrl() {
      return this.$channel?.orderDetailsPageUrl;
    }
  },
  watch: {
    searchText() {
      this.filterAndSortOrders();
    },
    selectedSort() {
      this.filterAndSortOrders();
    }
  },
  methods: {
    closeDropdown() {
      this.dropdownOpen = false;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeModal() {
      this.$emit('close-modal');
    },
    onBack() {
      this.$emit('back');
    },
    sortByRecent() {
      this.selectedSort = 'recent';
    },
    sortByAlpha() {
      this.selectedSort = 'alpha';
    },
    async fetchMyOrders() {
      const requestArgs = this.buildCommandArgs();

      const response = await this.executeCommand(
        myPages.commands.fetchMyOrders,
        requestArgs
      );
      if (typeof response === 'object') {
        return [...Object.values(response)];
      }

      return response;
    },
    filterAndSortOrders() {
      let result = this.orderCache;

      if (this.searchText) {
        result = result.filter(
          (r) =>
            (r.number || '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase()) ||
            (r.noteOfGoods || '')
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
        );
      }

      switch (this.selectedSort) {
        case 'recent':
          result = result.sort((a, b) =>
            a.date > b.date ? -1 : b.date > a.date ? 1 : 0
          );
          break;

        case 'alpha':
          result = result.sort((a, b) =>
            a.number > b.number ? 1 : b.number > a.number ? -1 : 0
          );
          break;
      }

      this.orders = result;
    },
    openOrder(order) {
      if (order.isInvoice) {
        this.navigate(`${this.orderDetailsPageUrl}?invoiceNo=${order.number}`);
      } else {
        this.navigate(`${this.orderDetailsPageUrl}?orderNo=${order.number}`);
      }
    },
    buildCommandArgs() {
      return {
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          ''
        )
      };
    },
    async executeCommand(command, args) {
      try {
        this.requestPending = true;
        const response = await this.$invoke(command, args);
        return response;
      } catch (err) {
        return [];
      } finally {
        this.requestPending = false;
      }
    }
  },
  async mounted() {
    this.orderCache = await this.fetchMyOrders();
    this.filterAndSortOrders();
  }
};
</script>

<template>
  <div class="my-pages-orders">
    <my-pages-back @back="onBack()" @exit="closeModal()" />
    <div class="my-pages-orders__header">
      <div class="my-pages-orders__header-title">
        {{ websiteText('mypages__orders').value }}
      </div>

      <div class="my-pages-orders__header-search-wrapper">
        <input
          :disabled="requestPending"
          v-model="searchText"
          class="my-pages-orders__header-search"
          :placeholder="$globalTexts.global__label_search"
        />
        <img
          class="my-pages-orders__header-search-icon"
          src="../../static/icons/search_icon.svg"
          @click="filterAndSortOrders()"
        />
      </div>

      <div class="my-pages-orders__header-sort">
        <span>{{ $globalTexts.global__sort_by }}</span>

        <div class="my-pages-orders__sort-wrapper">
          <div
            @click="toggleDropdown"
            class="my-pages-orders__sort custom-select"
          >
            {{ selectedSortName }}
          </div>
          <img
            :class="[
              'my-pages-orders__sort-icon',
              { 'my-pages-orders__sort-icon--up': dropdownOpen }
            ]"
            src="../../static/icons/select_chevron_down.svg"
          />
          <div
            v-if="dropdownOpen && !requestPending"
            class="my-pages-orders__sort-dropdown"
          >
            <div
              @click="
                sortByRecent();
                closeDropdown();
              "
              class="my-pages-orders__sort-option"
            >
              {{ $globalTexts.mypages__sort_order_date }}
            </div>
            <div
              @click="
                sortByAlpha();
                closeDropdown();
              "
              class="my-pages-orders__sort-option"
            >
              {{ $globalTexts.mypages__sort_order_id }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!requestPending" class="my-pages-orders__list">
      <div
        class="my-pages-orders__list-order"
        v-for="order in orders"
        :key="order.number"
      >
        <my-pages-order :order="order" @open="openOrder" />
      </div>
    </div>
  </div>
</template>

<style>
.my-pages-orders {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-neutrals-10);
}

.my-pages-orders__header {
  padding: 20px 10px 10px;
}

.my-pages-orders__header-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

.my-pages-orders__header-search-wrapper {
  position: relative;
}

.my-pages-orders__header-search-icon {
  position: absolute;
  top: 10px;
  height: 20px;
  right: 14px;
  cursor: pointer;
}

.my-pages-orders__header-search {
  border: 1px solid var(--color-border-50);
  width: 100%;
  height: 40px;
  padding: 0 14px;
  border-radius: 2px;
  outline: none;
}

.my-pages-orders__header-search:focus {
  border-color: #5379ff;
}

.my-pages-orders__header-search::placeholder {
  color: var(--color-neutrals-60);
}

.my-pages-orders__header-search:-ms-input-placeholder {
  color: var(--color-neutrals-60);
}

.my-pages-orders__header-search::-ms-input-placeholder {
  color: var(--color-neutrals-60);
}

.my-pages-orders__sort-wrapper {
  position: relative;
  user-select: none;
  cursor: default;
  margin-top: 6px;
}

.my-pages-orders__sort {
  display: flex;
  align-items: center;
}

.my-pages-orders__sort-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.my-pages-orders__sort-icon--up {
  transform: rotate(180deg);
}

.my-pages-orders__sort-dropdown {
  top: calc(100% - 1px);
  position: absolute;
  background: var(--color-neutrals-00);
  left: 0;
  right: 0;
  border-radius: 2px;
  border-color: #9d9fa6;
  border-width: 1px;
  border-style: solid;
  line-height: 16px;
  color: var(--color-neutrals-100);
  font-size: 16px;
  box-shadow: 2px 10px 20px rgba(0, 0, 0, 0.08);
}

.my-pages-orders__sort-option {
  padding: 10px 15px;
  border-top: 1px solid var(--color-border-50);
}

.my-pages-orders__sort-option:first-child {
  border: none;
}

.my-pages-orders__sort-option:hover {
  background: var(--color-neutrals-15);
}

.my-pages-orders__header-sort {
  margin-top: 1rem;
  color: #000000;
  font-size: 12px;
}

.my-pages-orders__list {
  display: flex;
  flex-direction: column;
  padding: 0 10px 25px;
  overflow-y: auto;
  overflow-y: overlay;
}

.my-pages-orders__list-order {
  padding-bottom: 10px;
}

@media (min-width: 768px) {
  .my-pages-orders__header {
    padding: 30px 30px 20px;
  }

  .my-pages-orders__header-title {
    margin-bottom: 10px;
  }

  .my-pages-orders__list {
    padding: 0 30px 51px;
  }
}
</style>
