<script>
import ErrorMessage from "./ErrorMessage.vue";

export default {
  components: {
    ErrorMessage,
  },
  data: () => ({
    inputType: "",
  }),
  props: {
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: [String, Number],
    },
    placeholder: {
      type: String,
    },
    errorMessage: {
      type: String,
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    min: {
      type: Number,
    },
    autoselect: {
      type: Boolean,
    },
    rows: {
      type: Number,
      default: 2,
    },
    maxlength: {
      type: Number,
      default: 500,
    },
  },
  emits: [
    "update:modelValue",
    "input",
    "focus",
    "blur",
    "click",
    "keydown",
    "enter-key-pressed",
  ],
  computed: {
    isValid() {
      return !this.errorMessage;
    },
    isPasswordInput() {
      return this.type === "password";
    },
    isPasswordHidden() {
      return this.inputType === "password";
    },
    isInput() {
      return this.type !== "textarea";
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.inputType = this.inputType === this.type ? "text" : this.type;
    },
    focus() {
      this.$refs.input?.focus();
    },
    updateModelValue(value) {
      this.$emit("update:modelValue", value);
      this.$emit("input", value);
    },
    onFocus() {
      if (this.autoselect) {
        this.$refs.input?.select();
      }
    },
  },
  created() {
    this.inputType = this.type;
  },
};
</script>

<template>
  <div class="text-input">
    <div class="text-input-label">
      <label v-if="label" :for="id">{{ label }}</label>
      <img
        v-if="isPasswordInput && isPasswordHidden"
        src="../../static/icons/show-password-icon.svg"
        class="text-input__toggle-password-visibility"
        @click="togglePasswordVisibility()"
      />
      <img
        v-if="isPasswordInput && !isPasswordHidden"
        src="../../static/icons/hide-password-icon.svg"
        class="text-input__toggle-password-visibility"
        @click="togglePasswordVisibility()"
      />
    </div>
    <input
      :id="id"
      ref="input"
      v-if="isInput"
      class="text-input__input"
      :class="{ 'text-input__input--error': !isValid }"
      :type="inputType"
      :value="modelValue"
      :placeholder="placeholder"
      :maxlength="maxlength"
      oninput="if(type == 'number') { return validity.valid||(value='');}"
      @input="updateModelValue($event.target.value)"
      :min="min"
      @focus="
        $emit('focus');
        onFocus();
      "
      @blur="$emit('blur')"
      @click="$emit('click', $event)"
      @keydown="$emit('keydown', $event)"
      @keydown.enter="$emit('enter-key-pressed')"
    />
    <textarea
      v-else
      ref="input"
      class="text-input__input"
      :class="{ 'text-input__input--error': !isValid }"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :rows="rows"
      :maxlength="maxlength"
      @input="updateModelValue($event.target.value)"
      @focus="
        $emit('focus');
        onFocus();
      "
      @blur="$emit('blur')"
      @click="$emit('click', $event)"
      @keydown="$emit('keydown', $event)"
      @keydown.enter="$emit('enter-key-pressed')"
    />
    <error-message v-if="showErrorMessage" :message="errorMessage" />
  </div>
</template>

<style>
.text-input-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1a1a1a;
}

.text-input__toggle-password-visibility {
  margin-left: auto;
}

.text-input__toggle-password-visibility {
  cursor: pointer;
  width: 15px;
  height: 15px;
}

textarea.text-input__input--error,
input[type="text"].text-input__input--error,
input[type="number"].text-input__input--error {
  border-color: var(--color-feedback-error);
  color: var(--color-feedback-error);
  transition: color 0.2s ease, border-color 0.2s ease;
}

textarea.text-input__input--error:focus,
input[type="number"].text-input__input--error:focus,
input[type="text"].text-input__input--error:focus {
  border-color: gray;
  transition: border-color 0.2s ease;
}
</style>
