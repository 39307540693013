<script>
import GiftCardEditor from './GiftCardEditor.vue';

export default {
  components: { GiftCardEditor },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    editGiftCard(giftCardInput) {
      this.showModal = true;
      document.body.style.overflow = 'hidden';

      this.$nextTick(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs?.giftCardEditor?.editGiftCard(giftCardInput);
      });
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = 'initial';
    },
    openExitInEditor(e) {
      if (e.target === e.currentTarget) {
        this.$mitt.emit('giftCardEditor:openExitModal');
      }
    },
  },
  mounted() {
    this.$mitt.on('giftCardEditor:closeEditorModal', this.closeModal);
  },
  beforeUnmount() {
    this.$mitt.off('giftCardEditor:closeEditorModal', this.closeModal);
  },
};
</script>

<template>
  <div class="editor-modal__wrapper">
    <div v-if="showModal" class="editor-modal__modal" @click="openExitInEditor">
      <gift-card-editor
        class="editor-modal__gift-card-editor"
        context="modal"
        ref="giftCardEditor"
        @discard-changes="closeModal"
      />
    </div>
  </div>
</template>

<style>
.editor-modal__modal {
  position: fixed;
  z-index: 110;
  inset: 0;
  padding: 3%;
  background: rgba(0, 0, 0, 0.8);
}

.editor-modal__gift-card-editor {
  border-radius: 2px;
  overflow: hidden;
}
</style>
