import { inject, ref, computed } from 'vue';
import { pageKey, routeKey, navigateKey, getItemKey } from '@drapejs/core';

import { buildUrlPathWithQuery } from '@/utils';

export default function () {
  const navigate = inject(navigateKey, ref<any>(<any>{}));
  const route = inject(routeKey, ref<any>(<any>{}));
  const getItem = inject(getItemKey, async () => null);

  const cartReactive = inject('$cartReactive', ref<any>(<any>{}));
  const cart = computed(() => cartReactive.value.data || {});

  const channelReactive = inject('$channelReactive', ref<any>(<any>{}));
  const channel = computed(() => channelReactive.value.data || {});

  const userReactive = inject('$userReactive', ref<any>(<any>{}));
  const user = computed(() => userReactive.value.data || {});

  function formatPrice(
    value: number,
    decimals?: number,
    locale?: string,
    currencyId?: string,
    formatStyle?: string,
  ) {
    if (!decimals) {
      decimals = 2;
    }
    if (value !== 0 && !value) {
      return '';
    }
    if (!cart?.value) {
      return value;
    }
    return new Intl.NumberFormat(locale || channel.value?.locale || 'sv-SE', {
      style: formatStyle ?? 'currency',
      currency: currencyId || cart.value.currency?.id || 'SEK',
      maximumFractionDigits: decimals,
      minimumFractionDigits: 0,
    }).format(value);
  }

  function navigateToLoginPage(query = null) {
    const search = query || route?.query || {};

    const completeQuery = {
      ...search,
      redirect: encodeURIComponent(route?.pathname || ''),
    };

    getItem('__channel').then((data) => {
      if (data?.loginPageUrl) {
        const loginUrl = buildUrlPathWithQuery(
          data.loginPageUrl,
          completeQuery
        );
        navigate(loginUrl);
      } else if (data?.rootPath) {
        const rootPageUrl = buildUrlPathWithQuery(data.rootPath, completeQuery);
        navigate(rootPageUrl);
      }
    });
  }

  function getCurrencySymbol(locale = null, currency = null) {
    return (0).toLocaleString(locale || channel.value?.locale, {
      style: 'currency',
      currency: currency || cart.value.currency?.id,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).replace(/\d/g, '').trim();
  }

  return {
    page: inject(pageKey, ref()),
    route,
    cart,
    channel,
    user,
    formatPrice,
    navigateToLoginPage,
    isAuthorized: () =>
      user.value?.isAuthenticated && user.value?.organization?.organizationId,
    getCurrencySymbol,
  };
}
