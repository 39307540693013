<script>
import { meta, routeKey, getItemKey, pageKey } from '@drapejs/core';
import { watch, inject, nextTick, ssrContextKey } from 'vue';

import SiteFooter from './SiteFooter/SiteFooter.vue';
import SiteHeader from './SiteHeader';
import QuickCart from './QuickCart.vue';
import ChannelSelect from './ChannelSelect.vue';
import useContext from '@/composables/useContext';
import { setCookieInformationScript } from '../functions/cookie.functions';

let openModals = [];
function updateScroll() {
  if (openModals.length) {
    document.body.style.marginRight =
      window.innerWidth - document.body.offsetWidth;
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.marginRight = null;
    document.body.style.overflow = null;
  }
}

export default {
  components: {
    SiteHeader,
    SiteFooter,
    QuickCart,
    ChannelSelect
  },
  data() {
    return {
      previousUrl: {
        value: null
      },
      pendingPageEvent: null,
      isQuickCartOpen: false,
      isChannelSelectOpen: false
    };
  },
  provide() {
    return {
      previousUrl: this.previousUrl,
      registerModal(key) {
        openModals = openModals.filter((k) => k !== key);
        openModals.push(key);
        updateScroll();
      },
      unregisterModal(key) {
        openModals = openModals.filter((k) => k !== key);
        updateScroll();
      },
      openChannelSelect: () => {
        this.isChannelSelectOpen = true;
      }
    };
  },
  computed: {
    isCheckoutPage() {
      return this.$page?.type?.indexOf('Checkout') > -1;
    }
  },
  setup() {
    let routeChanged = false;

    const route = inject(routeKey, {});
    const getItem = inject(getItemKey);
    const ssrContext = inject(ssrContextKey);
    const page = inject(pageKey);
    const { channel } = useContext();

    watch(
      () => route.pathname,
      () => {
        nextTick(() => {
          routeChanged = true;
        });
      }
    );

    watch(channel, (newVal, _) => {
      if (newVal != undefined) {
        setCookieInformationScript(newVal.language);
      }
    });

    watch(
      meta,
      async () => {
        if (routeChanged) {
          routeChanged = false;

          if (typeof dataLayer === 'undefined' || !route.pathname) {
            return;
          }

          const channel = (await getItem('__channel')) || {};

          const pageLocation = `${route.pathname}${route.search || ''}`;
          dataLayer.push({
            event: 'page_view',
            channel: channel?.systemId,
            page_location: pageLocation,
            page_title: meta.value.title
          });
        }
      },
      { deep: true }
    );

    // TODO: Remove when sure that extra fastly caching is not needed
    // if (ssrContext && page?.value?.systemId) {
    //   ssrContext.addSurrogateKey(page.value.systemId);
    // }
  }
};
</script>

<template>
  <div>
    <site-header v-if="!isCheckoutPage" />
    <router-page />
    <site-footer v-if="!isCheckoutPage" />
    <quick-cart />
    <channel-select
      v-if="isChannelSelectOpen"
      @close="isChannelSelectOpen = false"
    />
  </div>
</template>

<style></style>
