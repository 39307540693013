<script>
import { defineComponent } from 'vue';
import NavigationDesktopAllProducts from './NavigationDesktopAllProducts.vue';
import NavigationDesktopCircles from './NavigationDesktopCircles.vue';

export default defineComponent({
  components: {
    NavigationDesktopAllProducts,
    NavigationDesktopCircles
  },
  inject: [
    '$headerCategoriesReactive',
    '$headerCategoryListingLinksReactive',
    '$productCategoriesRootReactive',
    '$newsCategoryReactive',
    '$selectedTopItemReactive',
    'closeNavigation'
  ],
  data() {
    return {
      selectedItem: null
    };
  },
  computed: {
    allProductsIsSelected() {
      return (
        this.isCategorySelected &&
        this.selectedItemOrFirst.category.id === this.productCategoriesRoot.id
      );
    },
    headerCategoryListingLinks() {
      return this.$headerCategoryListingLinksReactive;
    },
    selectedTopItem() {
      return this.$selectedTopItemReactive;
    },
    selectedItemOrFirst() {
      return this.selectedItem || this.selectedTopItem;
    },
    productCategoriesRoot() {
      return this.$productCategoriesRootReactive;
    },
    isCategorySelected() {
      return this.selectedItemOrFirst?.type === 'category';
    },
    isLinkSelected() {
      return this.selectedItemOrFirst?.type === 'link';
    },
    isSubLinkSelected() {
      return this.selectedItemOrFirst?.type === 'sub-link';
    },
    newsCategoryUrl() {
      return this.$newsCategoryReactive?.url;
    },
    callToAction() {
      return this.selectedItemOrFirst?.category?.callToAction || '';
    },
    blocks() {
      if (!(this.selectedItemOrFirst?.link?.menuBlocksJson?.length > 0))
        return null;
      return {
        Main: [...this.selectedItemOrFirst.link.menuBlocksJson]
      };
    }
  },
  watch: {
    selectedTopItem(item) {
      this.selectedItem = item;
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    showCircleImages(item) {
      return item?.category?.showCircleImages || false;
    },
    hasAnyChildrenWithoutImage(item) {
      return (
        (item?.category?.subCategories || []).find((c) => !c.imageId) || true
      );
    }
  }
});
</script>

<template>
  <div class="navigation-desktop">
    <div class="navigation-desktop__content">
      <div
        class="navigation-desktop__side-bar"
        :class="{ 'navigation-desktop__side-bar--blocks-only': isLinkSelected }"
      >
        <router-link
          v-if="newsCategoryUrl"
          class="navigation-desktop__side-bar-option navigation-desktop__news navigation-desktop__side-bar-option-container"
          :to="newsCategoryUrl"
          @click="closeNavigation()"
        >
          {{ $globalTexts.global__label_news_category }}
        </router-link>
        <div
          class="navigation-desktop__side-bar-option navigation-desktop__side-bar-option-container"
          v-if="productCategoriesRoot"
          :class="{
            'navigation-desktop__side-bar-option--active':
              isCategorySelected &&
              selectedItemOrFirst.category.id == productCategoriesRoot.id
          }"
          @click="
            selectItem({ type: 'category', category: productCategoriesRoot })
          "
        >
          {{ productCategoriesRoot.name }}
        </div>
        <div
          v-for="category in $headerCategoriesReactive"
          :key="category.id"
          class="navigation-desktop__side-bar-option-container"
        >
          <div
            v-if="category.subCategories.length"
            class="navigation-desktop__side-bar-option"
            :class="{
              'navigation-desktop__side-bar-option--active':
                isCategorySelected &&
                category.name &&
                selectedItemOrFirst.category.name == category.name
            }"
            @click="selectItem({ type: 'category', category })"
          >
            {{ category.name }}
          </div>
          <router-link
            v-else
            :to="category.url"
            class="navigation-desktop__side-bar-option"
            @click="closeNavigation()"
          >
            {{ category.name }}
          </router-link>
        </div>
        <template
          v-for="subLink in headerCategoryListingLinks"
          :key="subLink.id"
        >
          <div
            v-if="subLink.menuBlocksJson"
            class="navigation-desktop__side-bar-option"
            :class="{
              'navigation-desktop__side-bar-option--active':
                isSubLinkSelected && selectedItemOrFirst.link.id == subLink.id
            }"
            @click="selectItem({ type: 'sub-link', link: subLink })"
          >
            {{ subLink.label }} ma-ta
          </div>
          <router-link
            v-else
            :to="subLink.url"
            class="navigation-desktop__side-bar-option"
            @click="closeNavigation()"
          >
            {{ subLink.label }}
          </router-link>
        </template>
      </div>
      <div
        class="navigation-desktop__content-current"
        :class="{
          'navigation-desktop__content-current--blocks': isLinkSelected
        }"
      >
        <navigation-desktop-all-products
          v-if="allProductsIsSelected"
          :category="selectedItemOrFirst.category"
        />
        <div
          v-if="
            selectedItemOrFirst && isCategorySelected && !allProductsIsSelected
          "
        >
          <navigation-desktop-circles
            v-if="
              showCircleImages(selectedItemOrFirst) ||
              !hasAnyChildrenWithoutImage(selectedItemOrFirst)
            "
            :category="selectedItemOrFirst.category"
          />
          <navigation-desktop-all-products
            v-else
            :category="selectedItemOrFirst.category"
          />
        </div>

        <div v-if="isLinkSelected || isSubLinkSelected">
          <blocks-repeater v-if="blocks" :blocks="blocks.Main" />
        </div>
      </div>
    </div>
    <div
      class="navigation-desktop__push"
      v-if="callToAction && callToAction.link"
    >
      <div class="navigation-desktop__push-content">
        <a
          class="navigation-desktop__push-content-link"
          :href="callToAction.link"
        >
          {{ callToAction.text ?? callToAction.link }}
          <img src="../../static/icons/arrow_right_white_icon.svg" />
        </a>
      </div>
    </div>
  </div>
</template>

<style>
.navigation-desktop {
  background-color: var(--color-neutrals-00);
}

.navigation-desktop__content,
.navigation-desktop__push-content {
  max-width: calc(var(--header-max-width) + 2rem);
  margin: 0 auto;
  display: flex;
  padding: 33px 1rem;
}

.navigation-desktop__side-bar-option-container {
  margin-bottom: 30px;
}

.navigation-desktop__push-content {
  padding: 0px 33px;
  display: flex;
  align-items: center;
  height: 42px;
}

.navigation-desktop__push-content-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
}

.navigation-desktop__push {
  background-color: #333333;
  color: white;
}

.navigation-desktop__side-bar {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: var(--color-neutrals-60);
  font-weight: bold;
  min-width: 86px;
  width: 86px;
  padding-top: 3px;
}

.navigation-desktop__side-bar--blocks-only {
  display: none;
}

.navigation-desktop__side-bar-option {
  cursor: pointer;
  font-size: 14px;
  line-height: 19px;
}

.navigation-desktop__news {
  font-size: 16px;
  line-height: 24px;
}

a.navigation-desktop__side-bar-option {
  color: var(--color-neutrals-60);
  text-decoration: none;
}

.navigation-desktop__side-bar-option--active {
  color: #1a1a1a;
}

.navigation-desktop__content-current {
  margin-left: 11%;
  flex-grow: 1;
  padding-bottom: 15px;
}

.navigation-desktop__content-current--blocks {
  margin-left: 0px;
}

@media (--phone) {
  .navigation-desktop {
    display: none;
  }
}
</style>
