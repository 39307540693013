<script>
export default {
  inject: ["openChannelSelect"],
  computed: {
    currentChannelName(){
      if(!this.currentChannel?.name) return "";
      var trimmed = this.currentChannel.name.trim();
      return trimmed;
    },
    currentChannel() {
      return (this.$availableChannels || []).find((f) => f.current);
    },
    canChangeChannel() {
      return (this.$availableChannels || []).length > 0;
    },
  }
};
</script>

<template>
  <div v-if="currentChannel" class="channel-switcher" @click="openChannelSelect()">
    <span>{{ currentChannelName }}</span>
    <span v-if="currentChannel.currencySymbol"
      >({{ currentChannel.currencySymbol }})</span
    >

    <span v-if="canChangeChannel">|</span>
    <span
      v-if="canChangeChannel"
      >{{ $globalTexts.global__label_change_country }}</span
    >
  </div>
</template>

<style>
.channel-switcher > span {
  padding-left: 3px;
}

.channel-switcher {
  cursor: pointer;
}
</style>