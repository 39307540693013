<script>
import { defineComponent, ref, inject } from 'vue';

import useWebsiteTexts from '@/composables/useWebsiteTexts';
import { configuratorStateKey } from '../../ProductConfigurator/ProductConfiguratorKeys';
import ConfiguratorChevron from '../../ProductConfigurator/ConfiguratorChevron.vue';
import RadioButton from '../../forms/RadioButton.vue';

export default defineComponent({
  components: {
    RadioButton,
    ConfiguratorChevron
  },
  props: {
    addon: {}
  },
  data() {
    return {
      choice: null
    };
  },
  emits: ['addAddonSelection', 'removeAddonSelection'],
  setup() {
    const { websiteText } = useWebsiteTexts();
    const toggleState = ref(false);
    const addBox = ref(false);
    const { configuratorState } = inject(configuratorStateKey);
    return { websiteText, toggleState, addBox, configuratorState };
  },
  methods: {
    setBoxAddonChoice(choice) {
      this.choice = choice;
      this.$emit('addAddonSelection', 'Addon-Box', this.choice, null);
    },
    accordianToggle() {
      this.toggleState = !this.toggleState;
    },
    getPossibleAddons() {
      return this.addon.variants.filter((v) => !this.cantAddAddon(v));
    },
    toggleAddon() {
      this.addBox = !this.addBox;
      if (this.addBox) {
        this.choice = (
          this.getPossibleAddons().filter((v) => v.isDefaultVariant)[0] ||
          this.getPossibleAddons()[0]
        ).id;
        this.$emit('addAddonSelection', 'Addon-Box', this.choice, null);
      } else {
        this.$emit('removeAddonSelection', 'Addon-Box');
      }
    },
    cantAddAddon(variant) {
      const summaryContainsPreprintAddons =
        this.configuratorState.selections.selectedAddons?.some(
          (a) => a.id !== this.addon.id && a.variant.isPreprint
        );
      const summaryCanContainPreprintAddons =
        !this.configuratorState.selections.selectedAddons?.some(
          (a) => a.id !== this.addon.id && !a.variant.canCombineWithPreprint
        );
      return (
        (summaryContainsPreprintAddons && !variant.canCombineWithPreprint) ||
        (!summaryCanContainPreprintAddons && variant.isPreprint)
      );
    },
    resetAddon() {
      this.addBox = false;
      this.toggleState = false;
      this.$emit('removeAddonSelection', 'Addon-Box');
    }
  },
  computed: {
    getPreselectedAddon() {
      return this.configuratorState.selections.selectedAddons?.find(
        (a) => a.id === this.addon.id
      );
    }
  },
  watch: {
    getPreselectedAddon(addon) {
      if (addon?.variant?.id && addon.variant.id !== this.choice) {
        this.addBox = true;
        this.toggleState = true;
        this.setBoxAddonChoice(addon.variant.id);
      }
    }
  }
});
</script>

<template>
  <div ref="decorator" class="addon-box">
    <div
      tabindex="0"
      class="addon-box__main"
      @click="accordianToggle()"
      @keypress.space.prevent="accordianToggle()"
    >
      <p class="addon-box__title">
        {{ websiteText('giftcard__addon_box_title').value }}
      </p>
      <ConfiguratorChevron :open="toggleState"></ConfiguratorChevron>
    </div>
    <div :open="toggleState" class="addon-box__content">
      <div v-if="!addBox" class="addon-box__add-button" @click="toggleAddon()">
        <img src="@/static/icons/white_plus.svg" />
      </div>
      <div
        v-if="addBox"
        class="addon-box__choices"
        v-for="variant in this.addon.variants"
      >
        <radio-button
          name="boxAddonRadioButtons"
          @change="setBoxAddonChoice(variant.id)"
          :text="
            this.$replaceTokens(this.$globalTexts[variant.websiteText], {
              amount: `${variant.minimumQuantity}`
            }) || ''
          "
          :description="
            cantAddAddon(variant)
              ? $globalTexts.giftcard__addon_combination_error
              : variant.description
          "
          :modelValue="choice === variant.id"
          :isError="
            choice === variant.id &&
            variant.minimumQuantity > this.configuratorState.sumQuantity
          "
          :disabled="cantAddAddon(variant)"
        />
      </div>
      <button
        class="addon-box__cancel-button"
        v-if="addBox"
        @click="toggleAddon()"
      >
        <img
          class="decoration-bottom-menu__back-button-arrow"
          src="@/static/icons/arrow_left_icon.svg"
        />
        {{ websiteText('giftcard__addon_cancel').value }}
      </button>
    </div>
  </div>
</template>

<style>
.addon-box {
  padding: 13px 20px 13px;
  border: 1px solid var(--color-neutrals-50);
  border-radius: 3px;
}

.addon-box__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.addon-box__title {
  margin: 0;
  font-weight: bold;
  user-select: none;
}

.addon-box__content {
  padding: 13px 0 7px;
  display: none;
}

.addon-box__content[open='true'] {
  display: block;
}

.addon-box__add-button {
  border-radius: 3px;
  background-color: var(--color-neutrals-100);
  height: 51px;
  width: 51px;
  display: grid;
  place-items: center;
  user-select: none;
  cursor: pointer;
}

.addon-box__choices {
  display: block;
}

.addon-box__choices > .radio-button > .radio-button__label {
  white-space: normal;
  padding-bottom: 0.5rem;
}

.addon-box__cancel-button {
  margin-top: 1rem;
  height: 40px;
  display: flex;
  align-items: center;
}

.addon-box__choices .radio-button__description {
  white-space: normal;
  padding: 0rem 0rem 0.5rem 1.75rem;
}

.addon-box__quantity_error .radio-button__label {
  color: var(--color-feedback-error);
}
</style>
