<script>
import TemplateMenu from './TemplateMenu.vue';

const messageCharacterLimit = 150;

export default {
  components: { TemplateMenu },
  props: {
    textSuggestions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['setMessage', 'overflowY'],
  data() {
    return {
      message: '',
      showTextTemplates: false,
      colorInverted: false,
    };
  },
  inject: {
    $giftCardStateReactive: '$giftCardStateReactive',
    invertWebsiteTextColor: 'invertWebsiteTextColor',
  },
  computed: {
    messageWithTotal() {
      return `${this.messageInputCount}/${messageCharacterLimit}`;
    },
    aboveMessageLimit() {
      return this.messageInputCount > messageCharacterLimit ? '' : undefined;
    },
    showSuggestions() {
      return this.textSuggestions.length > 0;
    },
    messageInputCount() {
      return this.message.length;
    },
    messageSuggestions() {
      return this.textSuggestions.filter((t) => t.value === 'message');
    },
    currentMessage() {
      return { message: this.message };
    },
  },
  methods: {
    setMessageText(template) {
      this.message = template.message;
      this.$emit('setMessage', template.message);
    },
    updateMessage(e) {
      this.message = e.target.value;
      this.$emit('setMessage', this.message);
    },
    openTextTemplates() {
      this.showTextTemplates = true;
      this.$emit('overflowY', 'hidden');
    },
    closeTextTemplates() {
      this.showTextTemplates = false;
      this.$emit('overflowY', 'auto');
    },
    setColor() {
      this.invertWebsiteTextColor(this.colorInverted);
    },
  },
  mounted() {
    this.message = this.$giftCardStateReactive?.webGiftCardDescription || '';
    this.colorInverted =
      this.$giftCardStateReactive?.websiteTextColorInverted || false;
  },
};
</script>

<template>
  <div class="web-menu-message">
    <label
      :warning="aboveMessageLimit"
      class="web-menu-message__textarea-label"
    >
      {{
        $globalTexts.giftcard__personal_message || 'giftcard__personal_message'
      }}
      <textarea
        @input="updateMessage"
        v-model="message"
        name="web-menu-message"
        :placeholder="
          $globalTexts.giftcard__message_placeholder ||
          'giftcard__message_placeholder'
        "
        cols="30"
        rows="8"
        maxlength="150"
      ></textarea>
      <div class="web-menu-message__character-count">
        {{ messageWithTotal }}
      </div>
    </label>
    <label class="web-menu-message__color-check-label">
      <input type="checkbox" v-model="colorInverted" @change="setColor" />
      {{ $globalTexts.giftcard__invert_color || 'giftcard__invert_color' }}
    </label>
    <template v-if="showSuggestions">
      <button
        class="web-menu-message__text web-menu-message__text-button"
        @click="openTextTemplates"
      >
        {{
          $globalTexts.giftcard__message_suggestions ||
          'giftcard__message_suggestions'
        }}
      </button>
      <div class="web-menu-message__text-templates" v-show="showTextTemplates">
        <template-menu
          :templates="messageSuggestions"
          :currentTemplates="currentMessage"
          @setText="setMessageText"
          @close="closeTextTemplates"
        />
      </div>
    </template>
  </div>
</template>

<style>
.web-menu-message__text {
  line-height: 24px;
  font-size: 16px;
  padding: 0;
}

.web-menu-message__textarea-label {
  max-width: 300px;
  display: block;
}

.web-menu-message__textarea-label textarea {
  border-radius: 2px;
  resize: vertical;
}

.web-menu-message__character-count {
  text-align: right;
  color: var(--color-border-50);
  font-size: 12px;
  line-height: 14px;
}

.web-menu-message__textarea-label[warning],
.web-menu-message__textarea-label[warning] .web-menu-message__character-count {
  color: red;
}

.web-menu-message__textarea-label[warning] textarea {
  border-color: red;
}

.web-menu-message__color-check-label {
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.web-menu-message__text-button {
  text-decoration: underline;
  display: block;
}

.web-menu-message__text-templates {
  position: absolute;
  inset: 0 1rem 0 0;
  z-index: 1;
  background: var(--color-white);
}

@media (--phoneAndTablet) {
  .web-menu-message__text-templates {
    inset: 0;
  }
}
</style>
