<script>
import { defineComponent } from 'vue';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

import Certificates from './Certificates.vue';
import CertificateCo2 from './CertificateCO2.vue';
import ProductInformationText from './ProductInformationText.vue';

export default defineComponent({
  props: {
    selectedVariant: {
      type: Object,
      default: {}
    }
  },
  components: {
    Certificates,
    CertificateCo2,
    ProductInformationText
  },
  setup() {
    const { websiteText } = useWebsiteTexts();
    return { websiteText };
  },
  computed: {
    supplierText() {
      if (this.selectedVariant.auditedProducer) {
        return this.websiteText('pdp__info_supplier_text_yes').value;
      }
      return this.websiteText('pdp__info_supplier_text_no').value;
    },
    countryOfOrigin() {
      return this.selectedVariant.fields?.CountryOfOrigin;
    },
    showCountryOfOrigin() {
      return this.countryOfOrigin?.length > 0;
    },
    complianceText() {
      return this.selectedVariant.compliance;
    },
    showCompliance() {
      return this.complianceText?.length > 0;
    }
  }
});
</script>

<template>
  <div class="product-info">
    <CertificateCo2 :selectedVariant="selectedVariant"> </CertificateCo2>
    <Certificates :selectedVariant="selectedVariant"> </Certificates>
    <ProductInformationText
      :selectedVariant="selectedVariant"
      :title="websiteText('pdp__info_supplier_title').value"
      :text="supplierText"
      :show="true"
    >
    </ProductInformationText>
    <ProductInformationText
      :selectedVariant="selectedVariant"
      :title="websiteText('pdp__info_production_country').value"
      :text="countryOfOrigin"
      :show="showCountryOfOrigin"
    >
    </ProductInformationText>
    <ProductInformationText
      :selectedVariant="selectedVariant"
      :title="websiteText('pdp__info_compliance').value"
      :text="complianceText"
      :show="showCompliance"
    >
    </ProductInformationText>
  </div>
</template>

<style></style>
