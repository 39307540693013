<script>
import { defineComponent } from 'vue';
import { sales } from '@motillo/drapejs-litium';

export default defineComponent({
  props: {
    description: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      email: '',
      submittedEmail: null,
      showingError: false,
      submitted: false
    };
  },
  computed: {
    newsletterDescriptionHtml() {
      if (!this.description) return null;
      return this.$renderMarkdown(this.description);
    },
    successMessage() {
      return this.$replaceTokens(
        this.$globalTexts.footer__newsletter_signup_success,
        { email: this.submittedEmail }
      );
    }
  },
  watch: {
    submittedEmail(submittedEmail) {
      this.submitted = !!submittedEmail;
    },
    showingError(active) {
      this.submitted = active;
    }
  },
  methods: {
    async submit() {
      if (!this.email) {
        this.$refs.emailField.focus();
        return;
      }
      this.submitted = true;

      try {
        await this.$invoke(sales.subscribeToNewsletter, {
          url: window.location.href,
          email: this.email
        });

        if (typeof dataLayer !== 'undefined') {
          dataLayer.push({
            event: 'subscribe_to_newsletter',
            email: this.email
          });
        }

        this.submittedEmail = this.email;
        this.email = '';
      } catch (e) {
        console.error(e);
        this.showingError = true;
        setTimeout(() => {
          this.showingError = false;
        }, 2000);
      }
    }
  }
});
</script>

<template>
  <div class="site-footer-newsletter-signup">
    <div
      class="site-footer-newsletter-signup__description text-editor-content"
      v-html="newsletterDescriptionHtml"
    ></div>
    <div class="site-footer-newsletter-signup_controls">
      <input
        class="site-footer-newsletter-signup__email-input"
        type="email"
        :placeholder="$globalTexts.footer__newsletter_placeholder"
        v-model="email"
        ref="emailField"
      />
      <button
        class="site-footer-newsletter-signup__button secondary-button"
        @click="submit"
        :disabled="submitted"
      >
        {{ $globalTexts.footer__newsletter_label_cta }}
      </button>
    </div>
    <div
      class="site-footer-newsletter-signup__message"
      :class="{
        'site-footer-newsletter-signup__message--active': submittedEmail
      }"
    >
      {{ successMessage }}
    </div>
    <div
      class="site-footer-newsletter-signup__message site-footer-newsletter-signup__message--error"
      :class="{
        'site-footer-newsletter-signup__message--active': showingError
      }"
    >
      {{ $globalTexts.footer__newsletter_signup_error }}
    </div>
  </div>
</template>

<style>
.site-footer-newsletter-signup {
  position: relative;
}

.site-footer-newsletter-signup__message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: 0;
  pointer-events: none;
  transition: opacity linear 100ms;
}

.site-footer-newsletter-signup__message.site-footer-newsletter-signup__message--error {
  background-color: rgb(255, 142, 142);
}

.site-footer-newsletter-signup__message--active {
  opacity: 0.9;
  pointer-events: all;
}

.site-footer-newsletter-signup__description {
  max-width: 238px;
  margin-bottom: 24px;
}

input[type='email'].site-footer-newsletter-signup__email-input {
  height: 45px;
  border: 1px solid var(--color-border-50);
  padding: 10px 14px;
  vertical-align: bottom;
  width: 245px;
  margin-bottom: 16px;
}

.site-footer-newsletter-signup button {
  padding: 0 30px;
  height: 45px;
}

.site-footer-newsletter-signup__description p {
  margin: 0;
}

.site-footer-newsletter-signup_controls {
  display: flex;
  flex-wrap: wrap;
}

@media (--tabletAndDesktop) {
  input[type='email'].site-footer-newsletter-signup__email-input {
    margin-right: 11px;
  }

  .site-footer-newsletter-signup button {
    flex-grow: 1;
    max-width: 245px;
  }
}

@media (--phone) {
  .site-footer-newsletter-signup {
    margin-bottom: 25px;
  }

  .site-footer-newsletter-signup__description {
    margin-bottom: 10px;
    font-weight: bold;
    margin-top: 18px;
  }

  input[type='email'].site-footer-newsletter-signup__email-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .site-footer-newsletter-signup button {
    width: 100%;
  }
}
</style>
