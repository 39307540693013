<script>
import ModalContainer from "../ModalContainer.vue";
import SearchViewInput from "./SearchViewInput.vue";
import SearchViewPhoneResults from "./SearchViewPhoneResults.vue";

export default {
  components: {
    ModalContainer,
    SearchViewInput,
    SearchViewPhoneResults,
  },
  props: {
    searchResult: {
      type: Object,
    },
    searchString: {
      type: String,
    },
  },
  methods: {
    closeIfClickIsOutsideOfSearchOverlay(e) {
      if (!this.$refs?.searchViewPhoneContent?.isSameNode(e.target)) return;
      const children = [
        ...this.$refs?.searchViewPhoneContent?.getElementsByTagName("*"),
      ];
      if (
        children.filter((e) => !e.classList.contains("jsPhoneNoSearchResults"))
          .length > 0
      )
        return;

      this.$emit("close-search");
    },
  },
  mounted() {
    window.addEventListener("click", this.closeIfClickIsOutsideOfSearchOverlay);
  },
  beforeUnmount() {
    window.removeEventListener(
      "click",
      this.closeIfClickIsOutsideOfSearchOverlay
    );
  },
};
</script>

<template>
  <div class="search-view-phone">
    <search-view-input
      @update:searchPhrase="$emit('performQuickSearch', $event)"
      @fullSearch="$emit('performFullSearch', $event)"
      :search-string="searchString"
      class="search-view-phone__input"
    />
    <div class="search-view-phone__content" ref="searchViewPhoneContent">
      <search-view-phone-results
        :search-result="searchResult"
        @applyFacetSuggestion="$emit('applyFacetSuggestion', $event)"
        @fullSearch="$emit('performFullSearch', $event)"
      />
    </div>
  </div>
</template>

<style>
.search-view-phone {
  overflow: hidden;
  z-index: 10000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
}

.search-view-phone__caption {
  margin: 1rem;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

.search-view-phone__content {
  width: 100%;
  height: 100%;
}

.search-view-phone .search-view-input {
  margin: 0 auto;
  padding-bottom: 1rem;
}
</style>