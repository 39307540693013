<script>
import ModalContainer from "./ModalContainer.vue";
import Dropdown from "./forms/Dropdown.vue";

import { fetchPageBuilder } from '@drapejs/core';
import * as user from "../../connectors/litium/user.ts";

import { buildChannelSwitchUrlPathWithQuery } from "../utils";

export default {
  components: {
    ModalContainer,
    Dropdown,
  },
  inject: ["registerModal", "unregisterModal"],
  data: () => ({
    error: "",
    countryId: "",
    isCountrySelectOpened: false,
  }),
  computed: {
    currentChannel() {
      return (this.$availableChannels || []).find((c) => c.current);
    },
    channels() {
      const channels = [...(this.$availableChannels || [])];
      channels.forEach((c) => {
        c.value = c.countryId;
        c.text = (c.name || "").trim();
      });
      return channels;
    },
    countries() {
      return [];
    },
  },
  emits: ['close'],
  methods: {
    close() {
      this.isCountrySelectOpened = false;
      this.unregisterModal("CountrySelect");

      this.$emit("close");
    },
    open() {
      const currentChannel = this.channels.find((f) => f.current);
      if (currentChannel) {
        this.countryId = currentChannel.countryId;
      }

      this.isCountrySelectOpened = true;
      this.registerModal("CountrySelect");
    },
    buildRedirectUrl(redirectUrl) {
      try {
        const query = {
          ...(this.$route.query || {}),
        };
        return buildChannelSwitchUrlPathWithQuery(redirectUrl, query);
      } catch (err) {
        console.error(err);
        return redirectUrl;
      }
    },
    async changeChannel() {
      if (this.countryId == this.currentChannel?.countryId) {
        return this.close();
      }

      try {
        const request = fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          {
            ...this.$route.query,
          },
          ""
        );

        request.countryId = this.countryId;
        request.pageId = this.$page?.systemId || "";

        const { redirectUrl } = await this.$invoke(
          user.commands.changeChannel,
          request
        );
        this.close();

        if (redirectUrl) {
          const redirect = this.buildRedirectUrl(redirectUrl);
          window.location.assign(redirect);
        } else {
          window.location.reload();
        }
      } catch (err) {
        this.error = err;
      }
    }
  },
  mounted() {
    this.open();
  },
};
</script>

<template>
  <teleport to="body">
    <modal-container
      @close-modal="close"
      v-if="isCountrySelectOpened"
      :show-exit-button="true"
      position="center"
      class="channel-select__modal"
    >
      <div class="channel-select">
        <div class="channel-select__caption">
          {{ $globalTexts.global__choose_country }}
        </div>
        <div class="channel-select__form">
          <dropdown
            :options="channels"
            v-model="countryId"
            :error-message="error"
            class="channel-select__form-item"
          >
            <template v-slot:selected="slotProps">
              <div class="channel-select__selected">
                {{ slotProps.selected.text }} ({{
                  slotProps.selected.currencySymbol
                }})
              </div>
            </template>
            <template v-slot:option="slotProps">
              <div class="channel-select__option">
                <div>{{ slotProps.option.text }}</div>
                <div>{{ slotProps.option.currencySymbol }}</div>
              </div>
            </template>
          </dropdown>

          <button
            class="primary-button channel-select__submit"
            @click="changeChannel()"
          >
            {{ $globalTexts.global__change_country_submit }}
          </button>
        </div>
      </div>
    </modal-container>
  </teleport>
</template>

<style>
.channel-select__modal .modal-container {
  overflow: initial;
}

.channel-select__modal .dropdown__select {
  max-height: 270px;
  overflow-y: auto;
}

.channel-select {
  width: 100%;
}

.channel-select__caption {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  margin-top: 2.75rem;
}

.channel-select__form {
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.channel-select__option {
  display: flex;
  justify-content: space-between;
}

.channel-select .dropdown__option--selected {
  background-color: rgba(184, 204, 214, 0.25);
  font-weight: 400;
}

.channel-select .dropdown__option:first-child,
.channel-select .dropdown__option,
.channel-select .dropdown__option:last-child {
  padding: 0.5rem 1rem 0.5rem 0.75rem;
}

.channel-select__submit {
  width: 100%;
  margin-top: 0.75rem;
}

.channel-select__form-error {
  text-align: center;
  color: var(--color-feedback-error);
  padding: 0 1rem;
}

@media (--tabletAndDesktop) {
  .channel-select__submit {
    margin-bottom: 0.5rem;
  }
}
</style>