<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        category: {
            type: Object,
            required: true,
        },
    },
    inject: [
        "closeNavigation"
    ],
});
</script>

<template>
    <div class="navigation-phone-category-listing">
        <router-link
            class="navigation-phone-category-listing__link"
            :to="category.url"
            @click="closeNavigation()">{{$globalTexts.global__label_products_menu_see_all}}</router-link>
        <router-link
            class="navigation-phone-category-listing__link"
            v-for="sub in category.subCategories"
            :key="sub.id"
            :to="sub.url"
            @click="closeNavigation()"
            >
            {{sub.name}}
        </router-link>
    </div>
</template>


<style>
.navigation-phone-category-listing {
    display: flex;
    flex-direction: column;
    padding-top: 14px;

    flex-wrap: nowrap;
    overflow: auto;
    max-height: 100vh;
    padding-bottom: 200px; /* padding to ensure bottom items in menu are clickable on mobile devices */
}

.navigation-phone-category-listing__link {
    display: flex;
    height: 42px;
    align-items: center;
    margin-left: 14px;
    padding: 1rem 0;
    font-size: 16px;
}
</style>