<script>
import CompanyInformation from './components/CompanyInformation.vue';
import AddressEdit from './components/AddressEdit.vue';
import ContactEdit from './components/ContactEdit.vue';

export default {
  components: {
    CompanyInformation,
    AddressEdit,
    ContactEdit,
  },  
  emits: ['close-modal', 'back'],
  data() {
    return {
      uiState: 'company-information',
      currentAddress: null,
      currentAddressType: '',
      currentContact: null,
    };
  },
  computed: {
   showCompanyInformation() {
     return this.uiState == 'company-information';
   },
   showAddressEdit() {
     return this.uiState == 'address-edit';
   },
   showContactEdit() {
     return this.uiState == 'contact-edit';
   },
  },  
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
    onBack() {
      if(this.showCompanyInformation) {
        this.$emit('back');
      }
      else {
        this.uiState = 'company-information';
      }
    },
    onEditAddress(addressType, address) {
      this.currentAddress = address;
      this.currentAddressType = addressType;
      this.uiState = 'address-edit';
    },
    onAddressSaved() {
      this.uiState = 'company-information';
    },
    onEditContact(contact) {
      this.currentContact = contact;
      this.uiState = 'contact-edit';
    },
    onContactSaved() {
      this.uiState = 'company-information';
    },
  }
};
</script>

<template>
  <div class="organization-profile">
    <company-information 
      v-if="showCompanyInformation"
      @close-modal="closeModal"
      @back="onBack"
      @edit-address="onEditAddress"
      @edit-contact="onEditContact"
    />

    <address-edit 
      v-if="showAddressEdit" 
      @close-modal="closeModal"
      @back="onBack"
      @saved="onAddressSaved"
      :address-type="currentAddressType"
      :address-data="currentAddress"
    />

    <contact-edit 
      v-if="showContactEdit" 
      @close-modal="closeModal"
      @back="onBack"
      @saved="onContactSaved"
      :contact-data="currentContact"
    />
  </div>
</template>

<style>
  .organization-profile {
    height: auto;
    min-height: 100%;    
  }
</style>
