<script>
import { fetchPageBuilder } from '@drapejs/core';
import MyPagesBack from './MyPagesBack.vue';
import ErrorMessage from '../../forms/ErrorMessage.vue';
import Dropdown from '../../forms/Dropdown.vue';

import * as user from '../../../../connectors/litium/user';
import { addressTypes } from '../../../constants';

export default {
  components: {
    MyPagesBack,
    ErrorMessage,
    Dropdown,
  },
  emits: ['saved', 'close-modal', 'back'],
  props: {
    addressType: {
      type: String,
      required: false,
    },
    addressData: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      no: '',
      code: '',
      systemId: '',
      name: '',
      nameError: '',
      address: '',
      address2: '',
      addressError: '',
      postCode: '',
      city: '',
      zipCityError: '',
      country: '',
      saveError: '',
      requestPending: false,
    };
  },
  computed: {
    title() {
      return this.addressType == addressTypes.billing
        ? this.$globalTexts.orderdetails__invoice_address
        : this.$globalTexts.checkout__delivery_address;
    },
    countries() {
      return this.addressType === addressTypes.billing ?
        this.$user?.organization?.countries || [] : 
        this.$user?.organization?.deliveryCountries || [];
    },
  },
  methods: {
    validate() {
      if (!this.name) {
        this.nameError = this.$globalTexts.global__required_field;
        return false;
      }

      if (!this.address) {
        this.addressError = this.$globalTexts.global__required_field;
        return false;
      }

      if (!this.postCode || !this.city) {
        this.zipCityError = this.$globalTexts.global__required_field;
        return false;
      }

      return true;
    },
    async save() {
      this.saveError = '';

      if (!this.validate()) {
        return;
      }

      if (this.addressType == addressTypes.billing) {
        await this.updateBillingAddress();
      } else if (this.addressType == addressTypes.shipping) {
        await this.addOrUpdateShippingAddress();
      }
    },
    async updateBillingAddress() {
      const requestArgs = this.buildCommandArgs({
        no: this.no,
        name: this.name,
        address: this.address,
        address2: this.address2,
        postCode: this.postCode,
        city: this.city,
        country: this.country,
      });

      const response = await this.executeCommand(
        user.commands.updateBillingAddress,
        requestArgs,
      );

      switch (response.error) {
        case 'FAILED':
          this.saveError = this.$globalTexts.global__save_error;
          break;

        default:
          this.$emit('saved');
          break;
      }
    },
    async addOrUpdateShippingAddress() {
      const requestArgs = this.buildCommandArgs({
        no: this.no,
        code: this.code,
        systemId: this.systemId,
        name: this.name,
        address: this.address,
        address2: this.address2,
        postCode: this.postCode,
        city: this.city,
        country: this.country,
      });

      const response = await this.executeCommand(
        user.commands.addOrUpdateShippingAddress,
        requestArgs,
      );

      switch (response.error) {
        case 'FAILED':
          this.saveError = this.$globalTexts.global__save_error;
          break;

        case 'INVALID':
          this.saveError = this.$globalTexts.global_delivery_address_invalid;
          break;

        default:
          this.$emit('saved');
          break;
      }
    },
    closeModal() {
      this.$emit('close-modal');
    },
    emitBack() {
      this.$emit('back');
    },
    clearError(error) {
      this[error] = '';
    },
    buildCommandArgs(formArgs) {
      return {
        ...formArgs,
        ...fetchPageBuilder(
          this.$route.protocol,
          this.$route.host,
          this.$route.pathname,
          { ...this.$route.query },
          '',
        ),
      };
    },
    async executeCommand(command, args) {
      try {
        this.requestPending = true;
        const response = await this.$invoke(command, args);
        return response;
      } catch (err) {
        return [];
      } finally {
        this.requestPending = false;
      }
    },
  },
  created() {
    this.no = this.addressData.no;
    this.code = this.addressData.code;
    this.systemId = this.addressData.systemId;
    this.name = this.addressData.name;
    this.address = this.addressData.address;
    this.address2 = this.addressData.address2;
    this.postCode = this.addressData.postCode;
    this.city = this.addressData.city;
    this.country = this.addressData.country;
  },
};
</script>

<template>
  <div class="address-edit">
    <div class="address-edit__main">
      <my-pages-back
        @back="emitBack()"
        @exit="closeModal()"
      />

      <div class="address-edit__content">
        <div class="address-edit__title">
          {{ title }}
        </div>

        <div class="address-edit__field-group">
          <label class="address-edit__label" for="name">{{ $globalTexts.global_delivery_address_company }}</label>
          <input type="text" id="name" class="address-edit__input" v-model="name" @input="clearError('nameError')" />
          <error-message v-if="nameError" :message="nameError" />
        </div>

        <div class="address-edit__field-group">
          <label class="address-edit__label" for="address">{{ $globalTexts.global_delivery_address_address }}</label>
          <input type="text" id="address" class="address-edit__input" v-model="address" @input="clearError('addressError')" />
          <error-message v-if="addressError" :message="addressError" />
          <input type="text" id="address2" class="address-edit__input" v-model="address2" @input="clearError('address2Error')" />
        </div>

        <div class="address-edit__field-split-container">
          <div class="address-edit__field-split">
            <div class="address-edit__field-split-group address-edit__field-shrink">
              <label class="address-edit__label" for="postalCode">{{ $globalTexts.global_delivery_address_postal_code }}</label>
              <input type="text" id="postalCode" class="address-edit__input" v-model="postCode" @input="clearError('zipCityError')" />
            </div>
            <div class="address-edit__field-split-group">
              <label class="address-edit__label" for="city">{{ $globalTexts.global_delivery_address_city }}</label>
              <input type="text" id="city" class="address-edit__input" v-model="city" @input="clearError('zipCityError')" />
            </div>
          </div>

          <error-message v-if="zipCityError" :message="zipCityError" />
        </div>

        <div class="address-edit__field-group">
          <dropdown
            class="address-edit__input"
            :label="$globalTexts.global_delivery_address_country"
            :options="countries"
            placeholder="-"
            v-model="country"
          />
          <error-message :message="$globalTexts.global_delivery_address_nocountry" v-if="countries.length == 0" />
        </div>
      </div>
    </div>

    <div class="address-edit__content">
      <button class="primary-button address-edit__button-wide" @click="save" :disabled="requestPending || countries.length == 0">{{ $globalTexts.global__save }}</button>
      <error-message v-if="saveError" :message="saveError" />
    </div>
  </div>
</template>

<style>
  .address-edit {
    display: flex;
    flex-direction: column;
  }

  .address-edit__main {
    height: auto;
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .address-edit__content {
    padding: 0px 10px 10px;
  }

  .address-edit__title {
    font-family: Mulish;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: var(--color-black);
    margin-bottom: 15px;
  }

  .address-edit__field-group {
    margin-bottom: 14px;
  }

  .address-edit__field-split-container {
    margin-bottom: 14px;
  }

  .address-edit__field-split {
    display: flex;
    justify-content: space-between;
  }

  .address-edit__field-split-group {
    margin-bottom: 0;
  }

  .address-edit__field-shrink {
    flex-shrink: 12;
    margin-right: 12px;
  }

  .address-edit__label {
    font-family: Mulish;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1A1A1A;
    margin-bottom: 6px;
  }

  .address-edit__input {
    color: #333333;
    margin-top: 6px;
  }

  .address-edit__button-wide {
    width: 100%;
  }

  .address-edit .dropdown__selected-value {
    font-weight: 400;
  }

  @media (min-width: 768px) {
    .address-edit__content {
      padding: 0px 30px 20px;
    }
  }
</style>
