<script>

export default {
  emits: ['setImage', 'deleteImage'],
  props: {
    sizeLimitInMB: {
      type: Number,
      default: 1,
    },
    imageId: {
      type: String,
      default: '',
    },
    recommendation: {
      type: String,
      default: '',
    },
    upload: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      image: {},
      hasImageSizeWarning: false,
      imageLoading: false,
    };
  },
  computed: {
    imageUrl() {
      return this.$toLitiumMediaUrl(this.imageId || '', { maxWidth: 135 });
    },
    uploadWarningMessage() {
      return this.$replaceTokens(this.$globalTexts.giftcard__image_too_large, {
        size: `${this.sizeLimitInMB}MB`,
      }) || `Use an image that is smaller than ${this.sizeLimitInMB}MB`;
    },
  },
  methods: {
    handleImage(e) {
      const imageFiles = e.target.files || [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < imageFiles.length; i++) {
        const image = imageFiles[i];
        const imgSize = ((image.size / 1024) / 1024).toFixed(5);

        if (imgSize > this.sizeLimitInMB) {
          this.hasImageSizeWarning = true;
          return;
        }

        this.convertToBase64Image(image);
        this.hasImageSizeWarning = false;
      }
    },
    convertToBase64Image(fileImage) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          this.image = {
            name: fileImage.name,
            type: fileImage.type,
            src: img.src,
          };

          this.$emit('setImage', this.image);
          this.imageLoading = true;
        };
      };
      reader.readAsDataURL(fileImage);
    },
    removeImage() {
      this.imageLoading = false;
      this.$emit('deleteImage', this.imageId);
    },
  },
};
</script>

<template>
  <div class="upload-image">
    <label
      v-if="!imageId"
      for="upload-image__file-upload"
      class="secondary-button upload-image__upload-label"
    >
      <input
        type="file"
        id="upload-image__file-upload"
        class="upload-image__upload-button"
        accept="image/*"
        @change="handleImage"
      />
      <img src="../../../static/icons/upload_icon.svg" alt="upload-icon" />
      {{ upload }}
    </label>
    <p class="upload-image__upload-suggestion" v-if="!imageId">
      {{ recommendation }}
    </p>
    <p class="upload-image__upload-no-file" v-if="!imageId && !hasImageSizeWarning && !imageLoading">
      {{ $globalTexts.giftcard__no_file || 'giftcard__no_file' }}
    </p>
    <p class="upload-image__upload-loading" v-if="!imageId && imageLoading">
      {{ $globalTexts.giftcard__image_uploading || 'giftcard__image_uploading' }}
    </p>
    <p class="upload-image__upload-warning" v-if="hasImageSizeWarning">
      {{ uploadWarningMessage }}
    </p>
    <div v-if="imageId" class="upload-image__image-container">
      <div class="upload-image__image-spacer"></div>
      <img
        class="upload-image__image"
        :src="imageUrl"
        :alt="image.name"
      />
    </div>
    <button
      v-if="imageId"
      class="secondary-button upload-image__remove-image"
      @click="removeImage()"
    >
      <img
        class="upload-image__remove-icon"
        src="../../../static/icons/trash_icon.svg"
        alt="remove-icon"
      />
      {{ $globalTexts.global__remove || 'global__remove' }}
    </button>
  </div>
</template>

<style>
label.upload-image__upload-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.upload-image__upload-button {
  display: none;
}

.upload-image__upload-suggestion {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
}

.upload-image__upload-no-file,
.upload-image__upload-loading {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  margin: 5px 0;
  font-style: italic;
  color: var(--color-neutrals-30);
}

.upload-image__upload-warning {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  background: #FF5042;
  border-radius: 5px;
  color: white;
}

.upload-image__image-container {
  width: 250px;
  max-width: 100%;
  margin: auto;
  position: relative;
}

.upload-image__image-spacer {
  padding-top: 50%;
}

.upload-image__image {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
}

button.upload-image__remove-image {
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
}

.upload-image__remove-icon {
  filter: brightness(0) saturate(100%);
}
</style>
