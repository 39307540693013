<script>
import WebMenuBackground from './WebMenuBackground.vue';
import WebMenuLogotype from './WebMenuLogotype.vue';
import WebMenuMessage from './WebMenuMessage.vue';

export default {
  components: {
    WebMenuBackground,
    WebMenuMessage,
    WebMenuLogotype,
  },
  emits: [
    'setWebBackground',
    'setWebMessage',
    'setWebLogotype',
    'setLogoType',
    'deleteLogoType',
  ],
  data() {
    return {
      openTab: 0,
      overflowY: 'auto',
    };
  },
  inject: {
    $textSuggestionsReactive: '$textSuggestionsReactive',
    changeWebsiteTextContent: 'changeWebsiteTextContent',
  },
  computed: {
    textSuggestions() {
      const suggestions = [];
      if (this.$textSuggestionsReactive?.websiteSuggestions?.length > 0) {
        suggestions.push({
          value: 'message',
          name: this.$globalTexts.giftcard__messages || 'giftcard__messages',
          options: this.$textSuggestionsReactive.websiteSuggestions,
        });
      }

      return suggestions;
    },
    tabNames() {
      return [
        this.$globalTexts.global__background || 'global__background',
        this.$globalTexts.global__message || 'global__message',
        this.$globalTexts.giftcard__logotype || 'giftcard__logotype',
      ];
    },
  },
  methods: {
    setMessage(text) {
      this.changeWebsiteTextContent(text);
    },
    setOpenTab(e) {
      this.openTab = [...this.$refs.tabs.children].indexOf(e.target);
    },
    setOverflowY(state) {
      this.overflowY = state;
    },
  },
};
</script>

<template>
  <div class="web-menu__wrapper">
    <div class="web-menu">
      <h5 class="web-menu__heading">
        {{ $globalTexts.giftcard__web_title || 'giftcard__web_title' }}
      </h5>
      <p class="web-menu__info">
        {{ $globalTexts.giftcard__web_message || 'giftcard__web_message' }}
      </p>
      <div class="web-menu__tabs" ref="tabs">
        <span
          v-for="(tab, idx) in tabNames"
          :key="tab"
          :class="[
            'web-menu__tab',
            { 'web-menu__tab--selected': openTab === idx },
          ]"
          @click="setOpenTab"
        >
          {{ tab }}
        </span>
      </div>
      <web-menu-background
        v-show="openTab === 0"
        @setBackground="$emit('set-background', $event)"
        @upload-images="$emit('upload-images', $event)"
        @delete-website-image="$emit('delete-image', $event)"
      />
      <web-menu-message
        v-show="openTab === 1"
        :textSuggestions="textSuggestions"
        @setMessage="setMessage"
        @overflowY="setOverflowY"
      />
      <web-menu-logotype
        v-show="openTab === 2"
        @setLogoType="$emit('setLogoType', $event)"
        @deleteLogoType="$emit('deleteLogoType', $event)"
      />
    </div>
  </div>
</template>

<style>
.web-menu__wrapper {
  height: 100%;
  overflow-x: hidden;
  overflow-y: v-bind(overflowY);
  margin-right: -1rem;
  position: relative;
}

.web-menu {
  /* add padding only when scrollbar is not present */
  padding-right: calc(100% - 280px);
}

.web-menu__heading {
  line-height: 35px;
  font-size: 20px;
  color: var(--color-text-dark);
  margin: 0 0 16px;
  font-weight: normal;
}

.web-menu__info {
  margin: 0 0 26px;
  height: 36px;
}

.web-menu__tabs {
  display: flex;
  gap: 10px;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 26px;
  color: var(--color-neutrals-60);
  cursor: pointer;
  user-select: none;
}

.web-menu__tab--selected {
  color: var(--color-text-darker);
  position: relative;
  pointer-events: none;
}

.web-menu__tab--selected::after {
  position: absolute;
  content: '';
  left: 0;
  top: calc(100% + 1px);
  width: 100%;
  border-bottom: 1px solid var(--color-text-darker);
}

@media (--phoneAndTablet) {
  .web-menu__wrapper {
    padding: 1rem;
    margin-right: 0;
  }

  .web-menu {
    padding-right: 0;
  }
}
</style>
