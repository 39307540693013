import { defineCommand, defineReceiver } from '@drapejs/invoker';
import { request, query, sales } from '@motillo/drapejs-litium';
import { gql } from 'graphql-request';
import { del } from 'idb-keyval';

export const commands = {
  fetchGiftCardEditor: defineCommand<{
    url: string;
    giftCardId: string;
    variantId: string;
    locale: string;
    quantity: string;
    type: string;
  }>('fetchGiftCardEditor'),
  addOrUpdateGiftCardToCart: defineCommand<{
    url: string;
    input: any;
  }>('addOrUpdateGiftCardToCart'),
};

export const receivers = {
  fetchGiftCardEditor: defineReceiver(commands.fetchGiftCardEditor, async function (command, data) {
    const result = await request(
      this.cache,
      query(gql`
          query giftCardEditor(
            $url: String!
            $giftCardId: String!
            $variantId: String!
            $locale: String!
            $quantity: Int!
            $type: String
          ) {
            session(url: $url) {
              giftCardEditor(giftCardId: $giftCardId) {
                coverImages
                websiteImages
                logoType(giftCardId: $giftCardId) {
                  id
                  name
                }
                textSuggestions(locale: $locale) {
                  titleSuggestions
                  messageSuggestions
                  websiteSuggestions
                }
                imageLimits {
                  coverImageSizeLimit
                  coverImageAspectRatioLimit
                  websiteImageSizeLimit
                  websiteImageAspectRatioLimit
                  websiteLogotypeSizeLimit
                  insideLogotypeSizeLimit
                }
                settings(
                  locale: $locale
                  variantId: $variantId
                  quantity: $quantity
                  giftCardId: $giftCardId
                  type: $type
                ) {
                  giftCardTypes {
                    giftCardType
                    giftCardTypeName
                    isSelected
                    variants {
                      locale
                      variantId
                      variantSystemId
                      currencyId
                      localeDisplayName
                      price
                      displayName
                      insideCoverImageId
                      backCoverImageId
                      channelSystemId
                      isSelected
                      quantity
                      fees {
                        name
                        displayName
                        price
                        totalPrice
                        quantity
                        variantId
                        variantSystemId
                      }
                    }
                  }
                  fees {
                    name
                    displayName
                    price
                    totalPrice
                    quantity
                    variantId
                    variantSystemId
                  }
                  giftCardId
                  grandTotal
                }
              }
            }
          }
        `),
      {
        url: `${(<any>command).protocol}//${(<any>command).host}${
          (<any>command).path
        }`,
        giftCardId: command.giftCardId || '',
        variantId: command.variantId || '',
        locale: command.locale || '',
        quantity: command.quantity ? +command.quantity : 1,
        type: command.type || '',
      },
    );
    return result.session.giftCardEditor;
  }, 'litium'),
  addOrUpdateGiftCardToCart: defineReceiver(commands.addOrUpdateGiftCardToCart, async function (command, data) {
    const result = await request(this.cache, query(gql`
      mutation addOrUpdateGiftCardToCart($url: String!, $input: AddGiftCardToCartInput) {
          session(url: $url) {
            addOrUpdateGiftCardToCart(input: $input) {
              error
              row {
                ...RowFields
              }
              cart {
                ...CartFields
              }
            }
          }
        }   
        `, ...sales.withCartFields()), {
      url: `${(<any>command).protocol}//${(<any>command).host}${(<any>command).path}`,
      input: command.input,
    });

    await this.cache.setItem('__cart', result.session.addOrUpdateGiftCardToCart.cart);

    try {
      del(`giftcard:settings:${command.input.giftCardId}`);
    } catch (err) {
      console.warn('Failed to remove the cache key for input', command.input);
    }

    return {
      error: result.session.addOrUpdateGiftCardToCart.error,
      row: result.session.addOrUpdateGiftCardToCart.row,
    };
  }, 'litium'),
};
