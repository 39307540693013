<script>
export default {
  inject: {
    $giftCardStateReactive: '$giftCardStateReactive',
  },
  emits: ['selectItem'],
  computed: {
    insideFields() {
      return {
        sectionTitle: this.$globalTexts.giftcard__inside || 'giftcard__inside',
        fields: {
          title: !this.$giftCardStateReactive.giftCardTitle ? this.$globalTexts.giftcard__text_title || 'giftcard__text_title' : '',
          description: !this.$giftCardStateReactive.giftCardDescription ? this.$globalTexts.giftcard__text_description || 'giftcard__text_description' : '',
          logo: !this.$giftCardStateReactive.giftCardLogoTypeSystemId ? this.$globalTexts.giftcard__logotype || 'giftcard__logotype' : '',
        },
        active: (!this.$giftCardStateReactive.giftCardDescription
        || !this.$giftCardStateReactive.giftCardLogoTypeSystemId
        || !this.$giftCardStateReactive.giftCardTitle),
      };
    },
    coverFields() {
      return this.$giftCardStateReactive.coverImageSystemId;
    },
    webFields() {
      return {
        sectionTitle: this.$globalTexts.giftcard__web || 'giftcard__web',
        fields: {
          description: !this.$giftCardStateReactive.webGiftCardDescription ? this.$globalTexts.giftcard__text_description || 'giftcard__text_description' : '',
          logo: !this.$giftCardStateReactive.webLogoTypeSystemId ? this.$globalTexts.giftcard__logotype || 'giftcard__logotype' : '',
          background: !this.$giftCardStateReactive.websiteImageSystemId ? this.$globalTexts.global__background || 'global__background' : '',
        },
        active: (!this.$giftCardStateReactive.webGiftCardDescription
        || !this.$giftCardStateReactive.webLogoTypeSystemId
        || !this.$giftCardStateReactive.websiteImageSystemId),
      };
    },
    strictMessage() {
      if (!this.coverFields) {
        return this.$globalTexts.giftcard__warning_message_strict || 'giftcard__warning_message_strict';
      }

      return '';
    },
    warningMessage() {
      return this.warnings ? this.$globalTexts.giftcard__warning_message || 'giftcard__warning_message' : '';
    },
    warnings() {
      return [this.insideFields, this.webFields].map((section) => {
        return section.active ? {
          title: section.sectionTitle,
          fields: Object.fromEntries(Object.entries(section.fields).filter(([_, v]) => v)),
        } : '';
      });
    },
  },
  methods: {
    add() {
      if (this.insideFields.active) {
        this.$emit('selectItem', 'menu-inside');
      } else if (this.webFields.active) {
        this.$emit('selectItem', 'menu-web');
      }

      this.$mitt.emit('giftCardEditor:closeWarningModal');
    },
    close() {
      this.$mitt.emit('giftCardEditor:closeWarningModal');
    },
    save() {
      if (this.coverFields) {
        this.$mitt.emit('giftCardEditor:saveProgressWithWarning');
      }
    },
  },
};
</script>

<template>
  <div class="gift-card-editor__warning" @click="close()">
    <div class="gift-card-editor__warning-container">
      <h2 class="gift-card-editor__warning-title">
        {{ $globalTexts.giftcard__warning_title || 'giftcard__warning_title' }}
      </h2>
      <p v-if="strictMessage" class="gift-card-editor__strict-message">
        {{ strictMessage }}
      </p>
      <p v-if="warningMessage" class="gift-card-editor__warning-message">
        {{ warningMessage }}
      </p>
      <div class="gift-card-editor__warning-list-container">
        <ul class="gift-card-editor__warning-list"
          v-for="warning in warnings"
          :key="warning.section"
        >
          <li class="gift-card-editor__warning-list-section">
            {{warning.title}}
          </li>
          <li
            v-for="field in warning.fields"
            :key="field"
            class="gift-card-editor__warning-list-field"
          >
            {{field}}
          </li>
        </ul>
      </div>
      <button
        class="
          gift-card-editor__warning-stay
          primary-button
          gift-card-editor__warning-button
        "
        @click="add()"
      >
        {{ $globalTexts.giftcard__warning_button_action || 'giftcard__warning_button_action' }}
      </button>
      <button
        v-if="coverFields"
        class="gift-card-editor__warning-leave gift-card-editor__warning-button"
        @click="save()"
      >
        {{ $globalTexts.global__add_to_cart || 'global__add_to_cart' }}
      </button>
    </div>
  </div>
</template>

<style>
.gift-card-editor__warning {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  display: grid;
  place-content: center;
  z-index: 110;
  overflow: hidden;
}

.gift-card-editor__warning-container {
  background: var(--color-white);
  box-shadow: var(--shadow-outer-10);
  border-radius: 4px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  padding: 2rem;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift-card-editor__strict-message,
.gift-card-editor__warning-message {
  margin-top: 0;
  white-space: pre;
  line-height: 24px;
}

.gift-card-editor__warning-list-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.gift-card-editor__warning-list {
  text-align: left;
  padding-left: 20px;
  margin: 0 15px;
}

.gift-card-editor__warning-list-section {
  font-weight: 700;
  margin-left: -16px;
  padding-left: 0;
}

.gift-card-editor__warning-list-field {
  list-style-type: "▸ ";
  font-size: 14px;
}

.gift-card-editor__warning-leave {
  text-decoration: underline;
  font-weight: 700;
}

.gift-card-editor__warning-button {
  margin: 0.5rem;
}

button.gift-card-editor__warning-stay {
  border: 1px solid var(--color-neutrals-100);
}
</style>
