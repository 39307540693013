<script>
import ProductCard from '../ProductCard.vue';

export default {
  components: {
    ProductCard,
  },
  props: {
    searchResult: {
      type: Object,
    },
  },
  inject: ['closeSearch'],
  computed: {
    products() {
      return this.searchResult?.productSearch?.products || [];
    },
    productHits() {
      return this.searchResult?.productSearch?.hits || 0;
    },

    pages() {
      return this.searchResult?.pageSearch?.pages || [];
    },
    pageHits() {
      return this.searchResult?.pageSearch?.hits || 0;
    },
    facetSuggestions() {
      return this.searchResult?.productSearch?.facetSuggestions || [];
    },
    searchString() {
      return this.searchResult?.searchString || '';
    },
    productHitsLabel() {
      return this.$replaceTokens(this.$globalTexts.search__see_all, {
        hits: this.productHits,
      });
    },
    hasAnySearchResults() {
      return (
        this.products.length > 0
        || this.facetSuggestions.length > 0
        || this.pages.length > 0
      );
    },
  },
  methods: {
    performFullSearch() {
      this.$emit('fullSearch', this.searchString);
      this.$nextTick(() => {
        this.closeSearch();
      });
    },
  },
};
</script>

<template>
  <div class="search-view-results" v-if="searchResult">
    <div class="search-view-results__links">
      <div class="search-view-results__facets">
        <div class="search-view-results__facet-suggestions">
          <template
            v-for="facet in facetSuggestions"
            :key="facet.id"
            class="search-view-results__facet-suggestion"
          >
            <div
              v-for="facetValue in facet.values"
              :key="facetValue.id"
              class="search-view-results__facet-value-suggestion"
              @click="
                $emit('applyFacetSuggestion', {
                  facetId: facet.id,
                  facetValueId: facetValue.id,
                })
              "
            >
              {{ facetValue.text }}
            </div>
          </template>
        </div>

        <div
          class="search-view-results__all-product-hits"
          v-if="productHits"
          @click="performFullSearch()"
        >
          {{ productHitsLabel }}
        </div>
      </div>
      <div class="search-view-results__pages" v-if="pageHits">
        <div class="search-view-results__page-caption">
          {{ $globalTexts.search__page_results_title }}
        </div>
        <div class="search-view-results__page-links">
          <router-link
            v-for="(page, idx) in pages"
            :key="idx"
            :to="page.url"
            class="search-view-results__page-suggestion"
          >
            <span class="search-view-results__page-suggestion-text">{{
              page.pageName
            }}</span>
            <span class="search-view-results__page-suggestion-spacer">|</span>
          </router-link>
        </div>
      </div>
    </div>
    <div
      class="search-view-results__products-wrapper"
      v-if="products.length > 0"
    >
      <div class="search-view-results__products">
        <product-card
          class="search-view-results__product"
          v-for="(product, idx) in products"
          :key="idx"
          :product="product"
        />
      </div>
    </div>
    <div class="search-view-results__no-result" v-if="!hasAnySearchResults">
      {{ $globalTexts.search__no_hits }}
    </div>
  </div>
</template>

<style>
.search-view-results {
  background-color: white;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}

.search-view-results__page {
  padding-right: 0.5rem;
}

.search-view-results__all-product-hits {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
}

.search-view-results__facets {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1190px;
}

.search-view-results__facet-suggestions {
  display: flex;
  max-width: 75%;
  flex-wrap: wrap;
}

.search-view-results__facet-suggestion {
  display: flex;
  flex-wrap: wrap;
}

.search-view-results__facet-value-suggestion {
  color: white;
  background-color: black;
  padding: 9px 20px;
  border-radius: 1.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.search-view-results__pages {
  margin: 1.5rem auto 0.5rem;
  max-width: 1185px;
}

.search-view-results__page-suggestion,
.search-view-results__page-suggestion-text {
  padding-right: 0.75rem;
}

.search-view-results__page-suggestion-spacer {
  color: var(--color-border-50);
}

.search-view-results__page-links
  > a:nth-last-child(1)
  span.search-view-results__page-suggestion-spacer {
  display: none;
}

.search-view-results__page-caption {
  font-size: 16px;
  font-weight: 700;
  color: var(--color-neutrals-60);
  line-height: 24px;
}

.search-view-results__page-suggestion {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1a1a1a;
}

.search-view-results__no-result {
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

@media (--tabletAndDesktop) {
  .search-view-results__products {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 1rem;
  }

  .search-view-results__products-wrapper {
    height: 100%;
    max-width: 1210px;
    width: 100%;
    margin: 0 auto;
  }

  .search-view-results__product {
    width: 20%;
    margin-bottom: 10px;
  }
}
</style>
