<script>
import GiftCardPreview from './GiftCardPreview.vue';
import GiftCardSettings from './GiftCardSettings.vue';
import WebPreview from './WebPreview.vue';

export default {
  components: {
    GiftCardPreview,
    WebPreview,
    GiftCardSettings,
  },
  props: {
    previewType: {
      type: String,
      required: true,
    },
  },
  inject: {
    $giftCardStateReactive: '$giftCardStateReactive',
  },
  computed: {
    type() {
      return this.previewType;
    },
    coverImageUrl() {
      return this.$toLitiumMediaUrl(this.coverImage, { maxWidth: 436 });
    },
    coverImage() {
      return this.$giftCardStateReactive?.coverImageSystemId || '';
    },   
    backCoverImageUrl() {
      return this.$toLitiumMediaUrl(this.backCoverImage || '', { maxWidth: 436 });
    },
    backCoverImage() {
      return this.$giftCardStateReactive?.backCoverImageSystemId || '';
    },
    isImageFrontContent() {
      return this.type === 'menu-front';
    },
    isImageBackContent() {
      return this.type === 'menu-back';
    },
    isGiftCardContent() {
      return this.type === 'menu-inside';
    },
    isWebContent() {
      return this.type === 'menu-web';
    },
    isSettings() {
      return this.type === 'menu-settings';
    },
  },
};
</script>

<template>
  <div class="preview-area">
    <div :class="['preview-area__content', type]">
      <div :class="['preview-area__content-spacer', type]"></div>
      <img
        v-if="isImageFrontContent && coverImage"
        class="preview-area__content-image"
        :src="coverImageUrl"
      />
      <img
        v-if="isImageBackContent && backCoverImage"
        class="preview-area__content-image"
        :src="backCoverImageUrl"
      />
      <gift-card-preview
        v-if="isGiftCardContent"
        class="preview-area__gift-card-preview"
      />
      <web-preview
        v-if="isWebContent"
        class="preview-area__web-preview"
      />
      <gift-card-settings
        v-if="isSettings"
        class="preview-area__gift-card-settings"
      />
    </div>
  </div>
</template>

<style>
.preview-area {
  display: grid;
  place-items: center;
  max-width: 960px;
  overflow: hidden;
  padding: 0 10px;
  flex-grow: 1;
  overflow-y: auto;
}

.preview-area__content {
  position: relative;
  margin: 20px 10px;
  width: 100%;
  box-shadow: var(--shadow-outer-05);
}

.preview-area__content-spacer {
  background: var(--color-neutrals-45);
}

.preview-area__content-image,
.preview-area__gift-card-preview,
.preview-area__gift-card-settings {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.preview-area__content.menu-front,
.preview-area__content.menu-back {
  max-width: 80%;
}

.preview-area__content.menu-web {
  margin-top: 50px;
}

.preview-area__content-spacer.menu-front,
.preview-area__content-spacer.menu-back {
  padding-top: 144.1%
}

.preview-area__content-spacer.menu-inside {
  padding-top: 72%;
}

.preview-area__content-spacer.menu-web {
  display: none;
}

.preview-area__content-spacer.menu-settings {
  padding-top: 150%;
}

@media (--phoneAndTablet) {
  .preview-area__content.menu-web {
    position: static;
  }
}

@media (--desktop) {
  .preview-area {
    min-width: 385px;
    padding: 0;
  }

  .preview-area__content {
    margin: 30px 30px 20px;
  }

  .preview-area__web-preview {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
  }

  .preview-area__content.menu-front,
  .preview-area__content.menu-back {
    max-width: 346px;
  }

  .preview-area__content.menu-inside {
    max-width: 700px;
  }

  .preview-area__content.menu-web {
    max-width: 925px;
  }

  .preview-area__content.menu-settings {
    max-width: 500px;
  }

  .preview-area__content-spacer.menu-web {
    display: block;
    padding-top: 78%;
  }
}

</style>
