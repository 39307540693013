<script>
import { routeKey } from '@drapejs/core';

export default {
  props: {
    searchString: {
      type: String,
    },
  },
  data: () => ({
    searchStringModel: '',
    searchTimeout: null,
  }),
  emits: ['update:searchPhrase', 'fullSearch'],
  inject: {
    route: routeKey,
  },
  watch: {
    'route.href'() {
      this.searchStringModel = this.$route.query?.search || '';
    },
    searchStringModel(newValue, oldValue) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        this.$emit('update:searchPhrase', newValue);
      }, 300);
    },
  },
  methods: {
    selectText() {
      if (this.$refs.searchInput) {
        this.$refs.searchInput.select();
      }
    },
    cancelSearch() {
      this.searchStringModel = '';
      this.$refs.searchInput.focus();
    },
    triggerFullSearch() {
      if (!this.searchStringModel) return;
      this.$emit('fullSearch', this.searchStringModel);
    },
  },
  mounted() {
    this.$refs.searchInput.focus();
    this.searchStringModel = this.searchString;
  },
};
</script>

<template>
  <div class="search-view-input">
    <input
      type="text"
      class="search-view-input__input"
      ref="searchInput"
      v-model="searchStringModel"
      :placeholder="$globalTexts.global__search__placeholder"
      @focus="selectText()"
      @keyup.enter="$emit('fullSearch', $event.target.value)"
    />
    <!-- @blur="$emit('update:searchPhrase', $event.target.value)" -->
    <div
      class="search-view-input__search-input-icon-wrapper"
      :class="{
        'search-view-input__search-input-icon-wrapper--toggled':
          searchStringModel,
      }"
    >
      <img
        class="search-view-input__delete-icon"
        src="../../static/icons/cancel_black_icon.svg"
        @click="cancelSearch()"
        v-if="searchStringModel"
      />
      <img
        class="search-view-input__search-input-icon"
        src="../../static/icons/search_icon_white.svg"
        @click="triggerFullSearch()"
      />
    </div>
  </div>
</template>

<style>
.search-view-input {
  padding: 0 1rem 0 1rem;
  background-color: white;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 1rem;
}

input[type='text'].search-view-input__input {
  padding: 0.65rem 4rem 0.55rem 1rem;
  border: none;
  outline: none;
  display: block;

  border-radius: 4px;
  letter-spacing: 0.52px;
  font-size: 16px;
  border: 1px solid black;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

input[type='text'].search-view-input__input::placeholder {
  font-size: 16px;
  letter-spacing: 0.52px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.search-view-input__search-input-icon-wrapper {
  position: absolute;
  right: 15px;
  top: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
  background-color: black;
  color: var(--color-neutrals-00);
}

.search-view-input__search-input-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  color: var(--color-neutrals-00);
}

.search-view-input__delete-icon {
  position: absolute;
  top: 14px;
  right: 50px;
  width: 12px;
  height: 12px;
  cursor: pointer;
}

@media (--tabletAndDesktop) {
  .search-view-input {
    padding: 0.25rem 4rem 0 4rem;
    background-color: white;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto 1.75rem;
  }

  .search-view-input__search-input-icon-wrapper {
    position: absolute;
    right: 65px;
    top: 4px;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }
}
</style>
