<script>
import { watchEffect } from 'vue';

export default {
  data() {
    return {
      selectedCategoryName: '',
      selectedPreviewImage: '',
      customCovers: [],
      setCoverWatch: null,
      hasImageSizeWarning: false,
      hasImageDimensionWarning: false,
    };
  },
  inject: {
    $giftCardStateReactive: '$giftCardStateReactive',
    $coverImagesReactive: '$coverImagesReactive',
    changeCoverImage: 'changeCoverImage',
    $imageLimits: '$imageLimits',
  },
  emits: ['deleteImage', 'upload-images'],
  computed: {
    categories() {
      return this.$coverImagesReactive;
    },
    covers() {
      const covers = this.categories[
        this.categories.map((c) => c.name).indexOf(this.currentCategory?.name)
      ]?.covers;
      return covers;
    },
    currentCategory() {
      return (
        this.categories.find((c) => c.name === this.selectedCategoryName)
        || this.categories?.[0]
        || {}
      );
    },
    currentPreviewImage() {
      return (
        this.selectedPreviewImage || this.currentCategory?.covers?.[0] || ''
      );
    },
    showUpload() {
      return this.currentCategory?.value?.toLowerCase() === 'custom';
    },
    uploadSizeWarning() {
      return this.$replaceTokens(this.$globalTexts.giftcard__image_too_large, {
        size: `${this.$imageLimits.coverImageSizeLimit}MB`,
      }) || `Use an image that is smaller than ${this.$imageLimits.coverImageSizeLimit}MB`;
    },
    uploadDimensionWarning() {
      return this.$replaceTokens(this.$globalTexts.giftcard__image_aspect_ratio, {
        ratio: `${this.$imageLimits.coverImageAspectRatioLimit}`,
      }) || `Use an image with a ${this.$imageLimits.coverImageAspectRatioLimit} aspect ratio`;
    },
  },

  methods: {
    setSelectedCategory(categoryName) {
      this.selectedPreviewImage = '';
      this.selectedCategoryName = categoryName;
    },
    checkSelectedCategory(categoryName) {
      return this.currentCategory?.name === categoryName ? '' : undefined;
    },
    getCoverImage(coverId) {
      return this.$toLitiumMediaUrl(coverId, { maxWidth: 135 });
    },
    setSelectedPreviewImage(cover) {
      this.selectedPreviewImage = cover;
      this.changeCoverImage(cover);
    },
    handleImage(e) {
      const images = e.target.files || [];
      this.hasImageSizeWarning = false;
      this.hasImageDimensionWarning = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < images.length; i++) {
        this.convertToBase64Image(images[i], i === images.length - 1);
      }
    },
    convertToBase64Image(fileImage, isLast) {
      const imgSize = ((fileImage.size / 1024) / 1024).toFixed(5);

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const imageDimensions = img.width / img.height;
          let currentUploadSizeWarning = false;
          let currentUploadDimensionWarning = false;

          if (imgSize > this.$imageLimits.coverImageSizeLimit) {
            this.hasImageSizeWarning = true;
            currentUploadSizeWarning = true;
          }

          if (imageDimensions > this.$imageLimits.coverImageAspectRatioLimit) {
            this.hasImageDimensionWarning = true;
            currentUploadDimensionWarning = true;
          }

          if (!currentUploadSizeWarning && !currentUploadDimensionWarning) {
            this.customCovers.push({
              name: fileImage.name,
              type: fileImage.type,
              src: img.src,
            });
          }

          if (isLast && this.customCovers.length > 0) {
            this.$emit('upload-images', [...this.customCovers]);
            this.customCovers = [];
          }
        };
      };
      reader.readAsDataURL(fileImage);
    },
    removeSelected() {
      this.$emit('deleteImage', this.currentPreviewImage);
    },
    setDefaultSelectedImage() {
      this.selectedPreviewImage = '';
      if (this.currentPreviewImage) {
        this.setSelectedPreviewImage(this.currentPreviewImage);
      } else {
        this.selectedCategoryName = '';
        this.setSelectedCategory(this.currentCategory.name);
        this.setSelectedPreviewImage(this.currentPreviewImage);
      }
    },
    isImageSelected(cover) {
      return (
        this.currentPreviewImage
        && this.currentPreviewImage.name === cover.name
        && this.currentPreviewImage.id
          === this.$giftCardStateReactive.coverImageSystemId
      );
    },
  },
  mounted() {
    this.setCoverWatch = watchEffect(() => {
      if (this.currentPreviewImage) {
        if (this.$giftCardStateReactive?.coverImageSystemId) {
          const category = this.categories.find((c) => (c.covers || []).find(
            (s) => s.id === this.$giftCardStateReactive.coverImageSystemId,
          ));
          if (category) {
            this.setSelectedCategory(category.name);

            const image = (category.covers || []).find(
              (s) => s.id === this.$giftCardStateReactive.coverImageSystemId,
            );
            if (image) {
              this.setSelectedPreviewImage(image);
            }
          }
        }
        if (this.setCoverWatch) {
          this.setCoverWatch();
        }
      }
    });

    this.$mitt.on(
      'giftCardEditor:coverImagesUploaded',
      this.setSelectedPreviewImage,
    );
    this.$mitt.on(
      'giftCardEditor:coverImageDeleted',
      this.setDefaultSelectedImage,
    );
  },
  beforeUnmount() {
    this.$mitt.off(
      'giftCardEditor:coverImagesUploaded',
      this.setSelectedPreviewImage,
    );
    this.$mitt.off(
      'giftCardEditor:coverImageDeleted',
      this.setDefaultSelectedImage,
    );

    if (this.setCoverWatch) {
      this.setCoverWatch();
    }
  },
};
</script>

<template>
  <div class="front-menu">
    <h5 class="front-menu__heading">
      {{ $globalTexts.giftcard__cover_title || 'giftcard__cover_title' }}
    </h5>
    <div v-if="categories.length > 0" class="front-menu__select">
      <label>
        {{ $globalTexts.category_facets_category || 'category_facets_category' }}
        <select @change="setSelectedCategory($event.currentTarget.value)">
          <option
            v-for="category in categories"
            :key="category.name"
            :selected="checkSelectedCategory(category.name)"
            :value="category.name"
          >
            {{ category.name }}
          </option>
        </select>
      </label>
    </div>
    <template v-if="showUpload">
      <label
        for="front-menu__file-upload"
        class="secondary-button front-menu__upload-label"
      >
        <input
          type="file"
          id="front-menu__file-upload"
          class="front-menu__upload-button"
          accept="image/*"
          multiple="true"
          @change="handleImage"
        />
        <img src="../../../static/icons/upload_icon.svg" alt="upload-icon" />
        {{ $globalTexts.giftcard__upload_image_text || 'giftcard__upload_image_text' }}
      </label>
      <p class="front-menu__upload-suggestion">
        {{ $globalTexts.giftcard__size_recommendation || 'giftcard__size_recommendation' }}
      </p>
      <p
        class="front-menu__upload-filename"
        v-if="covers.length == 0 && !hasImageSizeWarning && !hasImageDimensionWarning"
      >
        {{ $globalTexts.giftcard__no_file || 'giftcard__no_file' }}
      </p>
      <div
        class="front-menu__upload-warning"
        v-if="hasImageSizeWarning || hasImageDimensionWarning"
      >
        <p v-if="hasImageSizeWarning">
          {{ uploadSizeWarning }}
        </p>
        <p v-if="hasImageDimensionWarning">
          {{ uploadDimensionWarning }}
        </p>
      </div>
    </template>
    <div class="front-menu__cover-list">
      <div
        v-for="cover in covers"
        :key="cover.name"
        class="front-menu__cover"
        :class="{'front-menu__cover--selected': isImageSelected(cover)}"
        @click="setSelectedPreviewImage(cover)"
      >
        <div class="front-menu__cover-spacer"></div>
        <img
          class="front-menu__cover-image"
          :src="getCoverImage(cover.id)"
          :alt="cover.name"
        />
      </div>
    </div>
    <button
      v-if="currentPreviewImage && showUpload"
      class="secondary-button front-menu__remove-cover"
      @click="removeSelected"
    >
      <img
        class="front-menu__remove-icon"
        src="../../../static/icons/trash_icon.svg"
        alt="remove-icon"
      />
      {{ $globalTexts.giftcard__remove_selected || 'giftcard__remove_selected' }}
    </button>
  </div>
</template>

<style>
.front-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.front-menu__heading {
  line-height: 35px;
  font-size: 20px;
  color: var(--color-text-dark);
  margin: 0 0 26px;
  font-weight: normal;
}

.front-menu__select {
  margin-bottom: 26px;
  text-transform: none;
}

.front-menu__cover-list {
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -1rem;
  display: grid;
  gap: 12px 10px;
  grid-template-columns: 135px 135px;
}

.front-menu__cover {
  position: relative;
}

.front-menu__cover--selected::after {
  box-shadow: inset 0 0 0 1px #000;
  content: "";
  position: absolute;
  inset: 0;
}

.front-menu__cover-spacer {
  padding-top: 133.3333%;
  background: var(--color-neutrals-45);
}

.front-menu__cover-image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

label.front-menu__upload-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.front-menu__upload-button {
  display: none;
}

.front-menu__upload-suggestion {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
}

.front-menu__upload-filename {
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  margin: 5px 0;
  font-style: italic;
  color: var(--color-neutrals-30);
}

.front-menu__upload-warning {
  font-size: 14px;
  line-height: 24px;
  padding: 6px;
  margin: 0 0 20px;
  text-align: center;
  background: #FF5042;
  border-radius: 5px;
  color: white;
}

.front-menu__upload-warning p{
  margin: 0;
}

button.front-menu__remove-cover {
  margin: 1rem auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: fit-content;
}

.front-menu__remove-icon {
  filter: brightness(0) saturate(100%);
}

@media (--phoneAndTablet) {
  .front-menu {
    padding: 1rem 0;
  }

  .front-menu__heading,
  .front-menu__select {
    padding: 0 1rem;
  }

  .front-menu__cover-list {
    padding: 0 1rem;
    margin-right: 0;
    grid-template-columns: 1fr 1fr;
  }

  .front-menu__upload-label,
  .front-menu__upload-warning {
    margin: 0 1rem;
  }

  .front-menu__remove-cover {
    margin-bottom: 0;
  }
}
</style>
