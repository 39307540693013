import MarkdownIt from 'markdown-it';

function createMd() {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true,
  });

  md.renderer.rules.table_open = () => '<table class="markdown-table">\n';

  return md;
}

const markdown = createMd();

export default function () {
  return {
    renderMarkdown(text: string) {
      if (!text) {
        return '';
      }

      return markdown.render(text);
    },
  };
}
