<script>
import TypeSelection from "./TypeSelection.vue";

export default {
  components: { TypeSelection },
  data() {
    return {
      selectedLanguage: '',
      selectedQuantity: 0,
      showTypeSelection: false,
      showNotEnoughStockWarning: false,
    };
  },
  inject: {
    $giftCardsReactive: "$giftCardsReactive",
    $feesReactive: "$feesReactive",
    $grandTotalReactive: "$grandTotalReactive",
    $requestPendingReactive: "$requestPendingReactive",
    changeQuantity: "changeQuantity",
    changeLocale: "changeLocale",
  },
  computed: {
    hasAnyGiftCards(){
      return this.$giftCardsReactive?.length > 0;
    },
    selectedGiftCard() {
      return this.$giftCardsReactive?.find((e) => e.isSelected) || {};
    },
    selectedVariant() {
      return this.selectedGiftCard?.variants?.find((v) => v.isSelected) || {};
    },
    fees() {
      return this.$feesReactive?.filter((f) => f.name !== "GiftCard") || [];
    },
    selectedGiftCardName() {
      return (
        this.selectedGiftCard.giftCardTypeName ||
        this.selectedVariant?.displayName ||
        ""
      );
    },
    selectedGiftCardLanguages() {
      if (!this.selectedGiftCard?.variants) return [];
      const languages = this.selectedGiftCard.variants.map((v) => ({
        val: v.locale,
        name: v.localeDisplayName,
      }));
      const languageIds = languages.map((e) => e.val);
      const filteredLanguages = languages.filter(
        ({ val }, index) => !languageIds.includes(val, index + 1)
      );

      return filteredLanguages;
    },
    selectedGiftCardLanguage() {
      return this.selectedVariant?.locale || "";
    },
    giftcardPrice() {
      return this.giftCardFee?.price || 0;
    },
    giftcardTotalPrice() {
      return this.giftCardFee?.totalPrice || 0;
    },
    giftCardFee() {
      return this.$feesReactive?.find((f) => f.name === "GiftCard");
    },
    shippingPrice() {
      return 0; // Math.abs(this.prices.shipping * this.roundUpQuantity);
    },
    showSetupPrice() {
      return 0; // this.roundUpQuantity < 50;
    },
    total() {
      return this.giftCardEditor?.grandTotal || 0;
    },
    formattedOption() {
      if (!this.selectedVariant) return undefined;
      const value = this.selectedVariant.displayName || '';

      return `- ${value}`;
    },
    showTypeSelectionStyle() {
      return this.showTypeSelection ? "hidden" : "auto";
    },
    notEnoughStockError() {
      return this.$replaceTokens(
        this.$globalTexts?.giftcard__not_enough_stock || "",
        {
          count: this.selectedQuantity,
        }
      );
    },
  },
  methods: {
    openTypeSelection() {
      this.showTypeSelection = true;
    },
    closeTypeSelection() {
      this.showTypeSelection = false;
    },
    checkSelectedLanguage(lang) {
      return this.selectedLanguage === lang ? "" : undefined;
    },
    async setSelectedLanguage(e) {
      const locale = e.currentTarget.value;
      await this.changeLocale(locale);
      this.selectedLanguage = locale;
    },
    async updateSelectedQuantity() {
      this.showNotEnoughStockWarning = false;

      if (this.selectedQuantity > 999999) {
        this.selectedQuantity = 999999;
      }
      const quantity = await this.changeQuantity(this.selectedQuantity);
      if (quantity < this.selectedQuantity) {
        this.selectedQuantity = quantity;
        this.showNotEnoughStockWarning = true;
      }
    },
  },
  mounted() {
    this.selectedLanguage = this.selectedVariant?.locale || "";
    this.selectedQuantity = this.selectedVariant?.quantity || 1;
  },
};
</script>

<template>
  <div class="gift-card-settings">
    <h5 class="gift-card-settings__title">
      {{ $globalTexts.giftcard__summary || 'giftcard__summary' }}
    </h5>
    <div class="gift-card-settings__type" v-show="hasAnyGiftCards">
      <p class="gift-card-settings__current-type">
        {{ selectedGiftCardName }} {{ formattedOption }}
      </p>
      <button
        class="gift-card-settings__change-type-button secondary-button"
        @click="openTypeSelection"
      >
        {{ $globalTexts.giftcard__change_type || 'giftcard__change_type' }}
      </button>
      <type-selection
        v-if="showTypeSelection"
        :current="selectedGiftCard"
        :locale="selectedGiftCardLanguage"
        @closeTypeSelection="closeTypeSelection"
      />
    </div>
    <label class="gift-card-settings__language" v-show="hasAnyGiftCards">
      {{ $globalTexts.global__language || 'global__language' }}
      <select
        class="gift-card-settings__language-select"
        @change="setSelectedLanguage"
      >
        <option
          v-for="lang in selectedGiftCardLanguages"
          :key="lang.val"
          :selected="checkSelectedLanguage(lang.val)"
          :value="lang.val"
        >
          {{ lang.name }}
        </option>
      </select>
    </label>
    <label class="gift-card-settings__quanity" v-show="hasAnyGiftCards">
      {{ $globalTexts.global__quantity || 'global__quantity' }}
      <input
        class="gift-card-settings__quanity-input"
        type="number"
        max="999999"
        min="1"
        v-model="selectedQuantity"
        @change="updateSelectedQuantity()"
      />
    </label>
    <div
      class="gift-card-settings__stock-warning"
      v-if="showNotEnoughStockWarning"
    >
      {{ notEnoughStockError }}
    </div>
    <div class="gift-card-settings__summary" v-show="hasAnyGiftCards">
      <div class="gift-card-settings__summary-gift-card">
        <span class="gift-card-settings-summary-text">
          {{ $globalTexts.giftcard__giftcard || 'giftcard__giftcard' }}
          ({{ $formatPrice(giftcardPrice, 2) }})
        </span>
        <span class="gift-card-settings-summary-value">
          {{ $formatPrice(giftcardTotalPrice, 2) }}
        </span>
      </div>

      <div
        v-for="fee in fees"
        :key="fee.id"
        class="gift-card-settings__summary-setup"
      >
        <span class="gift-card-settings-summary-text">
          {{ fee.displayName }}
          ({{ $formatPrice(fee.price, 2) }})
        </span>
        <span class="gift-card-settings-summary-value">
          {{ $formatPrice(fee.totalPrice, 2) }}
        </span>
      </div>

      <div class="gift-card-settings__summary-total">
        <span class="gift-card-settings-summary-text">
          {{ $globalTexts.cart__total || 'cart__total' }}
        </span>
        <span class="gift-card-settings-summary-value">
          {{ $formatPrice($grandTotalReactive, 2) }}
        </span>
      </div>
    </div>
    <div v-if="!hasAnyGiftCards">
      No eligible gift card types found in the current config
    </div>
  </div>
</template>

<style>
.gift-card-settings {
  padding: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: center;
  position: relative;
}

.gift-card-settings__title {
  margin: 0;
  font-size: 20px;
  line-height: 35px;
  font-weight: normal;
}

.gift-card-settings__current-type {
  margin: 0 0 11px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.gift-card-settings__change-type-button {
  margin: auto;
  display: block;
}

.gift-card-settings__language {
  text-align: center;
}

.gift-card-settings__language-select {
  display: block;
  width: 256px;
}

.gift-card-settings__quanity {
  text-align: center;
  width: 95px;
}

.gift-card-settings__quanity-input {
  text-align: center;
}

.gift-card-settings__quanity-input:invalid,
.gift-card-settings__quanity-input:focus:invalid {
  border-color: var(--color-feedback-error);
  color: var(--color-feedback-error);
}

.gift-card-settings__quanity-input::-webkit-outer-spin-button,
.gift-card-settings__quanity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gift-card-settings__quanity-input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.gift-card-settings__summary {
  border: 1px solid var(--color-border-50);
  width: 100%;
  padding: 40px;
  border-radius: 3px;
  color: var(--color-black);
  max-width: 450px;
}

.gift-card-settings__summary-gift-card,
.gift-card-settings__summary-shipping,
.gift-card-settings__summary-setup,
.gift-card-settings__summary-total {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 26px;
}

.gift-card-settings-summary-value {
  font-weight: 700;
}

.gift-card-settings__summary-total {
  font-weight: 700;
  padding-top: 14px;
  border-top: 1px solid var(--color-border-50);
  margin-top: 14px;
}

.gift-card-settings__stock-warning {
  color: var(--color-feedback-error);
}

@media (--phoneAndTablet) {
  .gift-card-settings {
    height: 100%;
    overflow-y: v-bind(showTypeSelectionStyle);
    overflow-x: hidden;
  }

  .gift-card-settings__summary {
    padding: 10px;
  }
}
</style>
