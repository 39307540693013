<script>
import { defineComponent } from "vue";
import NavigationPhoneMenuItem from "./NavigationPhoneMenuItem.vue";
import NavigationPhoneMenuItemWithCircles from "./NavigationPhoneMenuItemWithCircles.vue";
import NavigationPhoneArrowIcon from "./NavigationPhoneArrowIcon.vue";
import NavigationPhoneCategoryListing from "./NavigationPhoneCategoryListing.vue";
import NavigationPhoneMenuLinkItem from "./NavigationPhoneMenuLinkItem.vue";
import SearchView from "../SearchView";
import ChannelSwitcher from "../ChannelSwitcher";

export default defineComponent({
  components: {
    NavigationPhoneMenuItem,
    NavigationPhoneMenuItemWithCircles,
    NavigationPhoneArrowIcon,
    NavigationPhoneCategoryListing,
    NavigationPhoneMenuLinkItem,
    SearchView,
    ChannelSwitcher,
  },
  inject: [
    "closeNavigation",
    "$headerCategoriesReactive",
    "$topMenuLinksReactive",
    "$productCategoriesRootReactive",
    "$newsCategoryReactive",
    "productsRootItem",
    "$headerCategoryListingLinksReactive",
    "registerModal",
    "unregisterModal",
  ],
  data() {
    return {
      subNavStack: [],
    };
  },
  computed: {
    headerCategories() {
      return this.$headerCategoriesReactive || [];
    },
    rootPath() {
      return this.$channel?.rootPath;
    },
    activePage() {
      if (!this.subNavStack.length) return null;
      return this.subNavStack[this.subNavStack.length - 1];
    },
    activePageIsCategory() {
      return this.activePage?.type === "category";
    },
    activePageIsLink() {
      return this.activePage?.type === "link";
    },
    productCategoriesRoot() {
      return this.$productCategoriesRootReactive;
    },
    newsCategoryUrl() {
      return this.$newsCategoryReactive?.url;
    },
    isCategoriesRoot() {
      return this.activePage?.type === "productsRoot";
    },
    blocks() {
      if (!this.activePage?.link?.menuBlocksJson?.length > 0) return null;
      return {
        Main: [...this.activePage.link.menuBlocksJson],
      };
    },
  },
  methods: {
    selectItem(item) {
      this.subNavStack.push(item);
    },
    goBack() {
      this.subNavStack.pop();
    },
    openMyPages() {
      this.$emit("open-mypages");
    },
  },
});
</script>

<template>
  <div class="navigation-phone">
    <div class="navigation-phone__header">
      <div class="navigation-phone__back" v-if="activePage" @click="goBack()">
        <navigation-phone-arrow-icon direction="left" /> Back
      </div>
      <div v-else class="navigation-phone__menu-label">
        {{ $globalTexts.global__label_header_menu }}
      </div>
      <div class="navigation-phone__close" @click="closeNavigation()">
        &times;
      </div>
    </div>
    <div class="navigation-phone__page">
      <div v-if="isCategoriesRoot" class="navigation-phone__categories-root">
        <navigation-phone-menu-link-item
          v-if="newsCategoryUrl"
          :label="$globalTexts.global__label_news_category"
          :to="newsCategoryUrl"
        />
        <navigation-phone-menu-item
          :label="productCategoriesRoot.name"
          :hasChildren="true"
          @click="
            selectItem({ type: 'category', category: productCategoriesRoot })
          "
        />
        <template v-for="category in headerCategories" :key="category.id">
          <navigation-phone-menu-item-with-circles
            v-if="category.subCategories.length"
            :label="category.name"
            :children="category.subCategories"
            :show-circle-images="category.showCircleImages"
          />
          <navigation-phone-menu-link-item
            v-else
            :label="category.name"
            :to="category.url"
          />
        </template>
        <template
          v-for="item in $headerCategoryListingLinksReactive"
          :key="item.id"
        >
          <navigation-phone-menu-item
            v-if="item.menuBlocksJson"
            :label="item.labelPhoneOverride || item.label"
            :hasChildren="true"
            @click="selectItem({ type: 'link', link: item })"
          />
          <navigation-phone-menu-link-item
            v-else
            :label="item.labelPhoneOverride || item.label"
            :to="item.url"
          />
        </template>
      </div>
      <div v-else-if="activePageIsCategory">
        <navigation-phone-category-listing :category="activePage.category" />
      </div>
      <div v-else-if="activePageIsLink">
          <blocks-repeater class="navigation-phone__blocks" v-if="blocks" :blocks="blocks.Main" />
      </div>
      <div v-else>
        <div class="navigation-phone__shortcuts">
          <div v-if="newsCategoryUrl" class="navigation-phone__shortcut">
            <router-link
              class="navigation-phone__item"
              :to="newsCategoryUrl"
              @click="closeNavigation()"
            >
              <img
                class="navigation-phone__item-icon"
                src="../../static/icons/star_outline_icon.svg"
              />
              <span class="navigation-phone__menu-link">{{
                $globalTexts.global__label_news_category
              }}</span>
            </router-link>
          </div>
          <div class="navigation-phone__shortcut">
            <div class="navigation-phone__item" @click="openMyPages()">
              <img
                class="navigation-phone__item-icon"
                src="../../static/icons/user_icon.svg"
              />
              <span class="navigation-phone__menu-link">My pages</span>
            </div>
          </div>
        </div>
        <navigation-phone-menu-link-item
          v-if="rootPath"
          :label="$globalTexts.global__label_header_home"
          :to="rootPath"
        />
        <navigation-phone-menu-item
          :label="$globalTexts.global__label_products_menu"
          :hasChildren="true"
          @click="selectItem(productsRootItem)"
        />
        <template v-for="item in $topMenuLinksReactive" :key="item.id">
          <navigation-phone-menu-item
            v-if="item.menuBlocksJson"
            :label="item.labelPhoneOverride || item.label"
            :hasChildren="true"
            @click="selectItem({ type: 'link', link: item })"
          />
          <navigation-phone-menu-link-item
            v-else
            :label="item.labelPhoneOverride || item.label"
            :to="item.url"
          />
        </template>
        <div class="navigation-phone__channel-select">
          <channel-switcher />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.navigation-phone {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 100;
}

.navigation-phone a {
  color: inherit;
  text-decoration: none;
}

.navigation-phone__header {
  display: flex;
  justify-content: space-between;
  height: 44px;
  align-items: center;
  border-bottom: 1px solid var(--color-neutrals-10);
}

.navigation-phone__shortcuts {
  height: 88px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--color-neutrals-10);
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.navigation-phone__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navigation-phone__item-icon {
  width: 20px;
  height: 20px;
}

.navigation-phone__menu-link {
  margin-top: 0.5rem;
}

.navigation-phone__back {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.navigation-phone__menu-label {
  margin-left: 16px;
  font-size: 16px;
  font-weight: bold;
}

.navigation-phone__close {
  font-size: 26px;
  padding: 0 10px;
  margin-right: 3px;
}

.navigation-phone__categories-root {
  margin-top: 14px;
  max-height: 100vh;
  overflow: auto;
}

.navigation-phone__channel-select {
  padding: 1.25rem 0.75rem;
}

.navigation-phone__blocks {
  padding: 0 1.25rem;
}

@media (--desktop) {
  .navigation-phone {
    display: none;
  }
}
</style>
