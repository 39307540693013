<script>
import { defineComponent, ref, inject } from 'vue';

import useWebsiteTexts from '@/composables/useWebsiteTexts';
import useContext from '@/composables/useContext';
import { configuratorStateKey } from '../../ProductConfigurator/ProductConfiguratorKeys';
import ConfiguratorChevron from '../../ProductConfigurator/ConfiguratorChevron.vue';
import RadioButton from '../../forms/RadioButton.vue';
import PrintessModal from './PrintessModal.vue';

export default defineComponent({
  components: {
    RadioButton,
    ConfiguratorChevron,
    PrintessModal
  },
  props: {
    addon: {},
    current: null
  },
  data() {
    return {
      choice: null
    };
  },
  created() {
    this.choice =
      (
        this.addon.variants
          .filter((v) => !this.cantAddAddon(v))
          .filter((v) => v.isDefaultVariant)[0] || this.addon.variants[0]
      )?.id || null;
  },
  emits: ['addAddonSelection', 'removeAddonSelection', 'navigateToLogin'],
  setup() {
    const { websiteText } = useWebsiteTexts();
    const { isAuthorized } = useContext();
    const { configuratorState } = inject(configuratorStateKey);
    return { websiteText, isAuthorized, configuratorState };
  },
  methods: {
    navigateToLogin() {
      this.$emit('navigateToLogin');
    },
    setDesignAddonChoice(choice) {
      this.choice = choice;
      this.$emit('addAddonSelection', 'Addon-Design', this.choice, null);
    },
    setPrintessInfo(info) {
      this.$emit('addAddonSelection', 'Addon-Design', this.choice, info);
    },
    cantAddAddon(variant) {
      const summaryContainsPreprintAddons =
        this.configuratorState.selections.selectedAddons?.some(
          (a) => a.id !== this.addon.id && a.variant.isPreprint
        );
      const summaryCanContainPreprintAddons =
        !this.configuratorState.selections.selectedAddons?.some(
          (a) => a.id !== this.addon.id && !a.variant.canCombineWithPreprint
        );
      return (
        (summaryContainsPreprintAddons && !variant.canCombineWithPreprint) ||
        (!summaryCanContainPreprintAddons && variant.isPreprint)
      );
    },
    resetAddon() {
      this.choice =
        (
          this.addon.variants
            .filter((v) => !this.cantAddAddon(v))
            .filter((v) => v.isDefaultVariant)[0] || this.addon.variants[0]
        )?.id || null;
      this.$emit('addAddonSelection', 'Addon-Design', this.choice, null);
    }
  },
  computed: {
    getPreselectedAddon() {
      return this.configuratorState.selections.selectedAddons?.find(
        (a) => a.id === this.addon.id
      );
    }
  },
  mounted() {
    this.$emit('addAddonSelection', 'Addon-Design', this.choice);
  },
  watch: {
    getPreselectedAddon(addon) {
      this.choice = addon.variant.id;
    }
  }
});
</script>

<template>
  <div ref="decorator" class="addon-design">
    <div tabindex="0" class="addon-design__main">
      <p class="addon-design__title">
        {{ websiteText('giftcard__addon_design_title').value }}
      </p>
    </div>
    <div class="addon-design__content">
      <div class="addon-design__choices" v-for="variant in this.addon.variants">
        <radio-button
          name="designAddonRadioButtons"
          @change="setDesignAddonChoice(variant.id)"
          :text="
            this.$replaceTokens(this.$globalTexts[variant.websiteText], {
              amount: `${variant.minimumQuantity}`
            }) || ''
          "
          :description="
            cantAddAddon(variant)
              ? $globalTexts.giftcard__addon_combination_error
              : variant.description
          "
          :modelValue="choice === variant.id"
          :isError="
            choice === variant.id &&
            variant.minimumQuantity > this.configuratorState.sumQuantity
          "
          :disabled="cantAddAddon(variant)"
        />
      </div>
      <button
        v-if="choice === 'Addon-Design-Printess' && !isAuthorized()"
        class="giftcard__addon_design_login_redirect secondary-button"
        @click="navigateToLogin()"
      >
        {{ websiteText('giftcard__addon_design_printess_login').value }}
      </button>
      <PrintessModal
        :current="current"
        v-if="choice === 'Addon-Design-Printess' && isAuthorized()"
        @setPrintessInfo="setPrintessInfo"
      >
      </PrintessModal>
    </div>
  </div>
</template>

<style>
.addon-design {
  padding: 13px 20px 13px;
  border: 1px solid var(--color-neutrals-50);
  border-radius: 3px;
}

.addon-design__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.addon-design__title {
  margin: 0;
  font-weight: bold;
  user-select: none;
}

.addon-design__content {
  padding: 13px 0 7px;
  display: block;
}

.addon-design__add-button {
  border-radius: 3px;
  background-color: var(--color-neutrals-100);
  height: 51px;
  width: 51px;
  display: grid;
  place-items: center;
  user-select: none;
  cursor: pointer;
}

.addon-design__choices {
  display: block;
}

.addon-design__choices > .radio-button > .radio-button__label {
  white-space: normal;
  padding-bottom: 0.5rem;
}

.giftcard__addon_design_login_redirect {
  margin-top: 1rem;
}

.addon-design__choices .radio-button__description {
  white-space: normal;
  padding: 0rem 0rem 0.5rem 1.75rem;
}

.addon-design__quantity_error .radio-button__label {
  color: var(--color-feedback-error);
}
</style>
