import { enUS, sv, nb, fi, de, nl } from 'date-fns/locale';

export const dateLocales = {
  'en-US': enUS,
  'en-GB': enUS,
  'sv-SE': sv,
  'nb-NO': nb,
  'fi-FI': fi,
  'de-DE': de,
  'nl-NL': nl,
};

export function validateEmail(email: string) {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export function getParameterByName(name: string, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function buildQueryParams(query: any) {
  const querySegments = [];

  for (const parameter in query) {
    const value = query[parameter];
    if (value) {
      querySegments.push(`${parameter}=${value}`);
    }
  }

  return querySegments.join('&');
}

export function buildUrlPathWithQuery(path: string, query: any) {
  try {
    const queryParams = buildQueryParams(query);
    return queryParams ? `${path}?${queryParams}` : path;
  } catch (err) {
    console.error(err);
    return path;
  }
}

const whitelistedParams = ['color', 'size', 'action', 'utm', 'gclid', 'fbclid'];

export function buildChannelSwitchUrlPathWithQuery(path: string, query: any) {
  try {
    const querySegments = [];
    for (const parameter in query) {
      if (isWhitelistedParameter(parameter)) {
        const value = query[parameter];
        querySegments.push(`${parameter}=${value}`);
      }
    }

    const queryParams = querySegments.join('&');
    return queryParams ? `${path}?${queryParams}` : path;
  } catch (err) {
    console.error(err);
    return path;
  }

  function isWhitelistedParameter(parameter: string) {
    return whitelistedParams.find((p) => p === parameter);
  }
}
