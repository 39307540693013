<script>
import { computed, defineComponent, watchEffect } from 'vue';
import { navigateKey, OptionsComponent, routeKey } from '@drapejs/core';
import NavigationDesktop from '../NavigationDesktop';
import NavigationPhone from '../NavigationPhone';
import SiteHeaderRibbon from './SiteHeaderRibbon.vue';
import SiteHeaderLogotype from './SiteHeaderLogotype.vue';
import SiteHeaderMenuIcon from './SiteHeaderMenuIcon.vue';
import SearchView from '../SearchView';
import MyPages from '../MyPages/MyPages.vue';
import EmailValidationMessage from '../EmailValidationMessage.vue';
import ChannelSwitcher from '../ChannelSwitcher';

const productsRootItem = {
  type: 'productsRoot',
};

export default defineComponent({
  extends: OptionsComponent,
  components: {
    NavigationDesktop,
    NavigationPhone,
    SiteHeaderMenuIcon,
    SiteHeaderRibbon,
    SearchView,
    SiteHeaderLogotype,
    MyPages,
    EmailValidationMessage,
    ChannelSwitcher,
  },
  inject: {
    navigate: navigateKey,
    route: routeKey,
  },
  data() {
    return {
      selectedTopItem: null,
      isNavigationOpen: true,
      isSearchOpen: false,
      isMyPagesOpen: false,
      myPagesMenuItem: '',
      headerContentTopPosition: 0,
      isFixed: false,
    };
  },
  provide() {
    return {
      closeNavigation: () => this.closeNavigation(),
      $topMenuLinksReactive: computed(() => this.topMenuLinks),
      $headerCategoriesReactive: computed(() => this.headerCategories),
      $headerCategoryListingLinksReactive: computed(
        () => this.headerCategoryListingLinks
      ),
      $productCategoriesRootReactive: computed(
        () => this.productCategoriesRoot
      ),
      $newsCategoryReactive: computed(() => this.newsCategory),
      productsRootItem,
      $selectedTopItemReactive: computed(() => this.selectedTopItem),
    };
  },
  computed: {
    isAuthenticated() {
      return this.$user?.isAuthenticated || false;
    },
    pageWantTransparentHeader() {
      return this.$page?.fields?.HeaderTransparent || false;
    },
    pageHeaderForeground() {
      return this.$page?.fields?.HeaderForeground?.value || null;
    },
    headerCategories() {
      return this.$channel?.headerCategories || [];
    },
    productCategoriesRoot() {
      return this.$channel?.productCategoriesRoot;
    },
    newsCategory() {
      return this.$channel?.newsCategory;
    },
    topMenuLinks() {
      return this.$channel?.headerMenuLinks || [];
    },
    headerCategoryListingLinks() {
      return this.$channel?.headerCategoryListingLinks || [];
    },
    rootPath() {
      return this.$channel?.rootPath;
    },
    logoUrl() {
      const imageId = this.frameworkContent.Logotype?.id;
      if (!imageId) return '';
      return this.$toLitiumMediaUrl(imageId, { maxHeight: 26 });
    },
    frameworkContent() {
      return this.$channel?.frameworkContentJson || {};
    },
    menuRootItem() {
      return {
        type: 'root',
        id: 'root',
      };
    },
    productsRootItem() {
      return productsRootItem;
    },
    isATopMenuLinkSelected() {
      if (!this.selectedTopItem) return false;
      if (this.selectedTopItem.id == this.menuRootItem.id) return false;
      return true;
    },
    headerClasses() {
      const classes = [];
      if (this.isFixed && !this.isSearchOpen) {
        classes.push('site-header--fixed');
      }

      if (this.pageWantTransparentHeader) {
        classes.push('site-header--transparent');
      }

      if (this.isATopMenuLinkSelected) {
        classes.push('site-header--active');
      }

      if (
        this.pageHeaderForeground &&
        !this.isATopMenuLinkSelected &&
        !this.isFixed
      ) {
        classes.push(`site-header--foreground-${this.pageHeaderForeground}`);
      }

      return classes;
    },
    cartItemCount() {
      return (this.$cart?.rows || []).reduce(
        (acc, curr) => acc + +curr.quantity,
        0
      );
    },
  },
  watch: {
    headerContentTopPosition(pos) {
      this.isFixed = window.scrollY > pos;
    },
    'route.href'() {
      if (sessionStorage.getItem('my-pages')) {
        sessionStorage.removeItem('my-pages');

        this.$nextTick(() => {
          this.openMyPages();
        });
      }

      if (sessionStorage.getItem('quickcart')) {
        sessionStorage.removeItem('quickcart');

        this.$nextTick(() => {
          this.openQuickCart();
        });
      }
    },
  },
  methods: {
    onUrlChange() {
      console.warn('url changed, via popstate');
    },
    closeNavigation() {
      this.closeSearchOverlay();
      this.selectTopItem(null);
    },
    closeTopItemIfSelected(item) {
      this.closeSearchOverlay();
      if (
        this.selectedTopItem &&
        item &&
        JSON.stringify(this.selectedTopItem) === JSON.stringify(item)
      ) {
        this.selectedTopItem = null;
      } else {
        this.selectTopItem(item);
      }
    },
    selectTopItem(item) {
      this.closeSearchOverlay();
      this.selectedTopItem = item;
    },
    toggleSearchOverlay() {
      this.isSearchOpen = !this.isSearchOpen;
      this.selectedTopItem = null;
    },
    closeSearchOverlay() {
      this.isSearchOpen = false;
    },
    openMyPages() {
      if (this.$user.isAuthenticated) {
        this.myPagesMenuItem = '';
        this.isMyPagesOpen = true;
      } else {
        this.closeNavigation();

        const search = this.$route.query || {};
        const query = {
          ...search,
          action: 'my-pages',
        };
        this.$navigateToLoginPage(query);
      }
    },
    openSearchOverlay() {
      this.$emit('open-search');
    },
    closeMyPages() {
      this.myPagesMenuItem = '';
      this.isMyPagesOpen = false;
    },
    updateHeaderSizeState() {
      this.headerContentTopPosition =
        this.$refs.ribbon?.$el.getBoundingClientRect().bottom || 0;
    },
    updateHeaderScrollState() {
      this.isFixed = window.scrollY > this.headerContentTopPosition;
    },
    closeIfClickIsOutsideOfMenu(e) {
      if (
        this.$refs.siteHeader.contains(e.target) ||
        !document.contains(e.target) ||
        this.isSearchOpen
      ) {
        return;
      }

      this.closeNavigation();
    },
    isSelectedTopItem(item) {
      if (!item) return false;
      return (
        item.id ===
        (this.selectedTopItem?.link?.id || this.selectedTopItem?.category?.id)
      );
    },
    openMyPagesOrderList() {
      this.myPagesMenuItem = 'orders';
      this.isMyPagesOpen = true;
    },
    openQuickCart() {
      this.$mitt.emit('openQuickCart');
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateHeaderScrollState);
    window.addEventListener('resize', this.updateHeaderSizeState);
    window.addEventListener('mouseover', this.closeIfClickIsOutsideOfMenu);
    window.addEventListener('mypages-orderlist', this.openMyPagesOrderList);

    this.$nextTick(() => {
      this.headerContentTopPosition =
        this.$refs.ribbon?.$el.getBoundingClientRect().bottom || 0;
    });

    window.addEventListener('popstate', this.onUrlChange);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateHeaderScrollState);
    window.removeEventListener('resize', this.updateHeaderSizeState);
    window.removeEventListener('mouseover', this.closeIfClickIsOutsideOfMenu);
    window.removeEventListener('mypages-orderlist', this.openMyPagesOrderList);
  },
});
</script>

<template>
  <header class="site-header" ref="siteHeader" :class="headerClasses">
    <email-validation-message />
    <SiteHeaderRibbon ref="ribbon" />
    <div class="site-header__spacer"></div>
    <div class="site-header__content">
      <div class="site-header__top-menu">
        <div class="site-header__top-menu-left">
          <router-link v-if="rootPath" :to="rootPath"
            ><site-header-logotype class="site-header__logo"
          /></router-link>
          <div class="site-header__hide-on-phone">
            <span
              class="site-header__menu-link site-header__products"
              :class="{
                'site-header__menu-link--selected': isSelectedTopItem(
                  productCategoriesRoot
                ),
              }"
              :text="$globalTexts.global__label_products_menu"
              @click="
                closeTopItemIfSelected({
                  type: 'category',
                  category: productCategoriesRoot,
                })
              "
              >{{ $globalTexts.global__label_products_menu }}</span
            >
            <template v-for="item in topMenuLinks" :key="item.id">
              <span
                v-if="item.menuBlocksJson"
                class="site-header__menu-link"
                :class="{
                  'site-header__menu-link--selected': isSelectedTopItem(item),
                }"
                @click="closeTopItemIfSelected({ type: 'link', link: item })"
                >{{ item.label }}</span
              >
              <router-link
                v-else
                class="site-header__menu-link"
                :to="item.url"
                @click="closeNavigation()"
              >
                {{ item.label }}
              </router-link>
            </template>
          </div>
        </div>
        <div class="site-header__top-menu-right">
          <div class="site-header__hide-on-phone">
            <div class="site-header__item" @click="toggleSearchOverlay()">
              <img
                class="site-header__item-icon"
                src="../../static/icons/search_icon_black.svg"
              />
              <span class="site-header__menu-link">{{
                $globalTexts.global__label_search
              }}</span>
            </div>
          </div>
          <div class="site-header__hide-on-phone">
            <div class="site-header__user" @click="openMyPages()">
              <img
                class="site-header__user-icon"
                src="../../static/icons/user_icon.svg"
              />
              <span class="site-header__menu-link">{{
                $globalTexts.global__label_my_pages
              }}</span>
            </div>
          </div>
          <div class="site-header__hide-on-phone">
            <div
              class="site-header__item"
              @click="openQuickCart()"
              v-show="isAuthenticated"
            >
              <span
                v-if="cartItemCount"
                class="site-header__cart-button-count"
                >{{ cartItemCount }}</span
              >
              <img
                class="site-header__item-icon"
                src="../../static/icons/shopping-bag.svg"
              />
              <span class="site-header__menu-link">{{
                $globalTexts.global__label_cart
              }}</span>
            </div>
          </div>
          <div class="site-header__phone-only">
            <div class="site-header__phone-bag" @click="toggleSearchOverlay()">
              <img
                class="site-header__phone-bag-icon site-header__search-icon"
                src="../../static/icons/search_icon_black.svg"
              />
            </div>

            <div
              class="site-header__phone-bag"
              @click="
                closeSearchOverlay();
                openQuickCart();
              "
            >
              <span
                v-if="cartItemCount"
                class="site-header__cart-button-count"
                >{{ cartItemCount }}</span
              >
              <img
                class="site-header__phone-bag-icon"
                src="../../static/icons/shopping-bag.svg"
              />
            </div>

            <site-header-menu-icon
              class="site-header__menu-icon"
              @click="selectTopItem(menuRootItem)"
            />
          </div>
        </div>
      </div>

      <div class="site-header__desktop-nav">
        <navigation-desktop v-if="isATopMenuLinkSelected" />
        <search-view v-if="isSearchOpen" @close="closeSearchOverlay()" />

        <div
          class="site-header__desktop-shadow"
          v-if="isATopMenuLinkSelected"
        ></div>
      </div>
    </div>

    <navigation-phone v-if="selectedTopItem" @open-mypages="openMyPages" />
    <my-pages
      v-if="isMyPagesOpen"
      :menu-item="myPagesMenuItem"
      @close="closeMyPages()"
    />
  </header>
</template>

<style>
.site-header {
  z-index: 1;
}

.site-header--transparent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 0;
}

.site-header__spacer {
  display: none;
  height: 57px;
}

.site-header--fixed .site-header__spacer {
  display: block;
}

.site-header__content {
  position: relative;
  transition: background-color linear 0.2s;
  height: 57px;
  display: flex;
  padding: 0 15px;
  z-index: 100;
  box-shadow: 0px 5px 20px rgba(0, 81, 121, 0.07);
}

.site-header--fixed .site-header__content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.site-header--transparent.site-header--fixed .site-header__content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
}

.site-header--active.site-header--transparent .site-header__content {
  background-color: white;
}

.site-header__top-menu {
  font-size: 14px;
}

.site-header__top-menu a {
  text-decoration: none;
  color: inhert;
}

.site-header__top-menu-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.site-header__top-menu-left a {
  height: 18px;
  width: 95px;
}

.site-header__top-menu-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.site-header__menu-link {
  cursor: pointer;
  padding-left: 0.15rem;
  user-select: none;
}

.site-header__desktop-nav {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(99%);
  z-index: 100;
}

.site-header__desktop-shadow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: -9000px;
  box-shadow: 4px 5px 20px 0px rgba(184, 204, 214, 0.3);
  pointer-events: none;
}

.site-header__logo {
  height: 18px;
  width: auto;
}

.site-header__content {
  background-color: white;
}

.site-header--transparent .site-header__content {
  background-color: transparent;
}

.site-header--transparent.site-header--active .site-header__content {
  background-color: white;
}

.site-header__top-menu {
  max-width: var(--header-max-width);
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.site-header__top-menu-right .site-header__menu-link {
  margin-right: 0px;
}

.site-header__top-menu-right .site-header__item,
.site-header__top-menu-right .site-header__user {
  margin-left: 20px;
}

.site-header--foreground-white .site-header__menu-link {
  color: var(--color-neutrals-00);
}

.site-header__phone-only {
  display: flex;
  align-items: center;
}

.site-header__cart-button-count {
  position: absolute;
  top: -2px;
  left: 9px;
  background: #000000;
  border-radius: 100%;
  min-width: 16px;
  color: #fff;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  font-weight: 400;
  font-size: 9px;
  text-align: center;
}

.site-header__phone-bag-icon.site-header__search-icon {
  margin-right: 0.25rem;
  height: 26px;
  width: 26px;
}

.site-header__menu-icon {
  margin-left: 0.25rem;
}

@media (--desktop) {
  .site-header__top-menu-left a {
    width: auto;
  }

  .site-header__phone-only {
    display: none;
  }

  .site-header__spacer {
    height: 75px;
  }

  .site-header__content {
    height: 75px;
    z-index: 100;
  }

  .site-header--fixed .site-header__content {
    box-shadow: 4px 5px 20px 0px rgba(184, 204, 214, 0.3);
  }

  .site-header__logo {
    height: 21px;
    margin-right: 104px;
  }

  .site-header__menu-link {
    margin-right: 20px;
  }

  .site-header__products {
    display: inline-block;
    height: 18px;
    text-align: center;
  }

  /* Reserve space for bold font in case tab gets selected */
  .site-header__products::before {
    display: block;
    content: attr(text);
    font-weight: bold;
    height: 0px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }

  .site-header__menu-link--selected {
    font-weight: bold;
    position: relative;
  }

  .site-header__menu-link--selected::after {
    position: absolute;
    content: '';
    bottom: -7px;
    border-bottom: 1px solid black;
    margin-left: 0.25rem;
    left: 0;
    right: 0;
  }

  .site-header__item {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  .site-header__item-icon {
    padding-right: 0.25rem;
    width: 25px;
    height: 25px;
  }

  .site-header__user {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .site-header__user-icon {
    padding-right: 0.25rem;
    width: 25px;
    height: 25px;
  }
}

@media (--phoneAndTablet) {
  .site-header__phone-bag {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 0.5rem;
  }

  .site-header__phone-bag-icon {
    padding-right: 0.25rem;
    width: 24px;
    height: 24px;
  }

  .site-header__hide-on-phone {
    display: none;
  }

  .site-header__top-menu-right {
    margin-right: -10px;
  }
}
</style>
