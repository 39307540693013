<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  inject: ["closeNavigation"],
  computed: {
    subCategories() {
      return this.category.subCategories;
    },
  },
  methods: {
    getImage(subCategory) {
      return this.$toLitiumMediaUrl(subCategory.imageId, {
        fillArea: { width: 90, height: 90 },
      });
    },
  },
});
</script>

<template>
  <div class="navigation-desktop-circles">
    <router-link
      class="navigation-desktop-circles__item"
      v-for="sub in subCategories"
      :key="sub.id"
      :to="sub.url"
      @click="closeNavigation()"
    >
      <img
        v-if="sub.imageId"
        class="navigation-desktop-circles__image"
        draggable="false"
        :src="getImage(sub)"
      />
      <img
        v-else
        src="../../static/images/missing_image_desktop.svg"
        class="navigation-desktop-circles__image"
      />
      <div class="navigation-desktop-circles__label">{{ sub.name }}</div>
    </router-link>
  </div>
</template>

<style>
.navigation-desktop-circles {
  font-size: 12px;
  column-count: 7;
  max-height: 350px;
  margin-left: 53px;
  display: flex;
  flex-wrap: wrap;
}

.navigation-desktop-circles a {
  color: var(--color-text-standard);
  text-decoration: none;
}

.navigation-desktop-circles__image {
  width: 90px;
  height: 90px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-bottom: 8px;
}
.navigation-desktop-circles__missing-image {
  width: 90px;
  height: 90px;
  object-position: center;
  border-radius: 50%;
  margin-bottom: 8px;
  object-fit: none;
}

.navigation-desktop-circles__item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
  margin-bottom: 48px;
}
</style>