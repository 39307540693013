<script>
import ModalContainer from '../ModalContainer.vue';
import MyPagesMenu from './MyPagesMenu.vue';
import MyPagesOrders from './MyPagesOrders.vue';
import PersonProfile from './PersonProfile.vue';
import OrganizationProfile from './OrganizationProfile.vue';
import { routeKey } from '@drapejs/core';

export default {
  components: {
    ModalContainer,
    MyPagesMenu,
    MyPagesOrders,
    PersonProfile,
    OrganizationProfile,
  },
  props: {
    menuItem: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedMenuItem: '',
    };
  },
  inject: {
    registerModal: 'registerModal',
    unregisterModal: 'unregisterModal',
    route: routeKey,
  },
  emits: ['close'],
  watch: {
    'route.href'() {
      this.close();
    },
  },
  computed: {
    showMenu() {
      return this.selectedMenuItem === '';
    },
    showOrders() {
      return this.selectedMenuItem === 'orders';
    },
    showPersonProfile() {
      return this.selectedMenuItem === 'personProfile';
    },
    showOrganizationProfile() {
      return this.selectedMenuItem === 'organizationProfile';
    },
  },
  methods: {
    close() {
      this.unregisterModal('MyPages');
      this.$emit('close');
    },
    onMenuNavigate(menuItem) {
      this.selectedMenuItem = menuItem;
    },
    onMenuBack() {
      this.selectedMenuItem = '';
    },
  },
  created() {
    this.selectedMenuItem = this.menuItem || '';
  },
  mounted() {
    this.registerModal('MyPages');
  },
};
</script>

<template>
  <modal-container
    @close-modal="close()"
    @navigate-back="close"
    position="right"
    :show-exit-button="showMenu"
    class="my-pages__modal"
  >
    <div class="my-pages">
      <my-pages-menu
        v-if="showMenu"
        @navigate="onMenuNavigate"
        @close="close"
      />

      <my-pages-orders
        v-if="showOrders"
        @back="onMenuBack"
        @close-modal="close()"
      />

      <person-profile
        v-if="showPersonProfile"
        @back="onMenuBack"
        @close-modal="close()"
      />

      <organization-profile
        v-if="showOrganizationProfile"
        @back="onMenuBack"
        @close-modal="close()"
      />
    </div>
  </modal-container>
</template>

<style>
.my-pages {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.my-pages__modal .modal-container {
  padding: 0;
}

.my-pages__modal .modal-container__exit-button {
  width: 16px;
  margin-top: 0.5rem;
  right: 30px;
}
</style>
