<script>
import { defineComponent } from "vue";
import NavigationPhoneArrowIcon from "./NavigationPhoneArrowIcon.vue";
import NavigationPhoneMenuCircle from "./NavigationPhoneMenuCircle.vue";

export default defineComponent({
  components: {
    NavigationPhoneArrowIcon,
    NavigationPhoneMenuCircle,
  },
  inject: ["closeNavigation"],
  props: {
    label: {
      type: String,
      required: true,
    },
    showCircleImages: {
      type: Boolean,
      default: false,
    },
    children: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    isExpanded: false,
  }),
  computed: {
    hasChildren() {
      if (!this.children || this.children?.length === 0) return false;
      return true;
    },
    hasAnyChildrenWithoutImage() {
      return this.children?.find((c) => !c.category?.imageId) || true;
    },
  },
  methods: {
    toggleIsExpanded() {
      this.isExpanded = !this.isExpanded;
    },
  },
});
</script>

<template>
  <div class="navigation-phone-menu-item-with-circles">
    <div
      class="navigation-phone-menu-item-with-circles__label"
      @click="toggleIsExpanded()"
    >
      <div>{{ label }}</div>
      <navigation-phone-arrow-icon v-if="hasChildren && !isExpanded" />
    </div>
    <div
      class="navigation-phone-menu-item-with-circles__circles"
      v-if="isExpanded"
    >
      <div v-if="showCircleImages || !hasAnyChildrenWithoutImage">
        <navigation-phone-menu-circle
          v-for="child in children"
          class="navigation-phone-menu-item-with-circles__circle"
          :key="child.id"
          :category="child"
          :size="56"
        />
      </div>
      <div v-else class="navigation-phone-menu-item-with-circles__fallback">
        <router-link
          v-for="child in children"
          :key="child.id"
          class="navigation-phone-menu-item-with-circles__fallback-link"
          :to="child.url"
          @click="closeNavigation()"
        >
          {{ child.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<style>
.navigation-phone-menu-item {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.navigation-phone-menu-item-with-circles:last-child {
  margin-bottom: 200px; /* padding to ensure bottom items in menu are clickable on mobile devices */
}

.navigation-phone-menu-item-with-circles__label {
  margin-left: 14px;
  font-weight: bold;
  margin: 5px 0 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.navigation-phone-menu-item-with-circles__circles {
  width: 100%;
  overflow-x: auto;
  padding-left: 12px;
  margin-bottom: 7px;
  white-space: nowrap;
  padding-bottom: 0.2rem;
}

.navigation-phone-menu-item-with-circles__circles {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.navigation-phone-menu-item-with-circles__circles::-webkit-scrollbar {
  display: none;
}

.navigation-phone-menu-item-with-circles__circle {
  margin: 7px 3px;
  width: 80px;
}

.navigation-phone-menu-item-with-circles__fallback {
  overflow: hidden;
  padding-left: 2px;
}

.navigation-phone-menu-item-with-circles__fallback-link {
  padding: 0.75rem 0;
  font-size: 16px;
  display: block;
  line-height: 18.4px;
}
</style>