<script>
export default {
  emits: ["exit", "back"],
  methods: {
    goBack() {
      this.$emit("back");
    },
    exit() {
      this.$emit("exit");
    },
  },
};
</script>

<template>
  <div class="my-pages-back">
    <div
      class="my-pages-back__back"
      @click="goBack()"
    >
      <img
        class="my-pages-back__icon"
        src="../../../static/icons/arrow_left_icon.svg"
      />
      {{ $globalTexts.global__label_back }}
    </div>
    <img
      class="my-pages-back__exit"
      @click="exit()"
      src="../../../static/icons/cancel_icon.svg"
    />
  </div>
</template>

<style>
.my-pages-back {
  display: flex;
  align-items: center;
  padding: 11px 14px;
  line-height: 22px;
  background-color: var(--color-neutrals-00);
  cursor: pointer;
}

.my-pages-back__back {
  display: flex;
  align-items: center;
}

.my-pages-back__icon {
  height: 16px;
  padding-right: 1rem;
}

.my-pages-back__exit {
  margin-left: auto;
  height: 16px;
}

@media (min-width: 768px) {
  .my-pages-back {
    padding: 21px 30px;
  }
}
</style>
