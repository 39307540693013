<script>
export default {
  props: {
    selectedItem: {
      type: String,
      required: true,
    },
    showBack: {
      type: Boolean,
      required: true,
    },
    showSettings: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['selectItem'],
};
</script>

<template>
  <div class="main-menu__wrapper">
    <div class="main-menu">
      <div
        :class="['main-menu__item',
          {'main-menu__item--selected': selectedItem === 'menu-front'}]"
        @click="$emit('selectItem', 'menu-front')"
      >
        <img src="../../static/icons/giftcard-front.svg" class="main-menu__item-icon" >
        {{ $globalTexts.giftcard__front || 'giftcard__front' }}
      </div>
      <div
        :class="['main-menu__item',
          {'main-menu__item--selected': selectedItem === 'menu-inside'}]"
        @click="$emit('selectItem', 'menu-inside')"
      >
        <img src="../../static/icons/giftcard-inside.svg" class="main-menu__item-icon" >
        {{ $globalTexts.giftcard__inside || 'giftcard__inside' }}
      </div>
      <div
        v-if="showBack"
        :class="['main-menu__item',
          {'main-menu__item--selected': selectedItem === 'menu-back'}]"
        @click="$emit('selectItem', 'menu-back')"
      >
        <img src="../../static/icons/giftcard-front.svg" class="main-menu__item-icon mirror-image" >
        {{ $globalTexts.giftcard__back || 'giftcard__back' }}
      </div>
      <div
        :class="['main-menu__item',
          {'main-menu__item--selected': selectedItem === 'menu-web'}]"
        @click="$emit('selectItem', 'menu-web')"
      >
        <img src="../../static/icons/giftcard-web.svg" class="main-menu__item-icon" >
        {{ $globalTexts.giftcard__web || 'giftcard__web' }}
      </div>
      <div class="vertical-spacer"></div>
      <div
        v-show="showSettings"
        :class="['main-menu__item',
          {'main-menu__item--selected': selectedItem === 'menu-settings'}]"
        @click="$emit('selectItem', 'menu-settings')"
      >
        <img src="../../static/icons/giftcard-summary.svg" class="main-menu__item-icon" >
        {{ $globalTexts.giftcard__summary || 'giftcard__summary' }}
      </div>
    </div>
  </div>
</template>

<style>
.main-menu__wrapper {
  width: 100%;
  background: var(--color-white);
}

.main-menu {
  min-height: 134px;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  align-items: center;
}

.main-menu__item {
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #9D9FA6;
  text-align: center;
  cursor: pointer;
  user-select: none;
  flex: 1;
}

.main-menu__item--selected {
  color: #333;
  pointer-events: none;
  position: relative;
}

.main-menu__item--selected::after {
  content: '';
  position: absolute;
  background: #fff;
  height: 25px;
  width: 25px;
  top: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}

.main-menu__item-icon {
  display: block;
  margin: auto;
  filter: invert(69%) sepia(3%) saturate(485%) hue-rotate(189deg) brightness(91%) contrast(94%)
}

.mirror-image {
  transform: rotateY(180deg);
}

.main-menu__item--selected .main-menu__item-icon {
  filter: none;
}

.vertical-spacer {
  border-right: 1px solid #f0f0f0;
  height: 94px;
}

@media (min-width: 1075px) {
  .main-menu {
    gap: 20px;
  }
}

@media (--phoneAndTablet) {
  .main-menu__wrapper {
    box-shadow: var(--shadow-outer-05);
  }

  .main-menu__item-icon {
    height: 50px;
  }

  .vertical-spacer {
    display: none;
  }
}

</style>
