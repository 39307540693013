<script>
export default {
  props: {
    message: {
      type: String,
    },
  },
};
</script>

<template>
  <div v-if="message" class="error-message">
    {{ message }}
  </div>
</template>

<style>
.error-message {
  color: var(--color-feedback-error);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.18px;
  margin-top: 0.25rem;
}
</style>
