<script>
import { defineComponent, inject } from 'vue';
import { injectionKeys as litiumKeys } from '@motillo/drapejs-litium';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default defineComponent({
    props: {
        selectedVariant: {
            type: Object,
            default: {},
        },
    },
    setup() {
        const toLitiumMediaUrl = inject(litiumKeys.toLitiumMediaUrlKey, () => '');
        const { websiteText } = useWebsiteTexts();
        return {
            toLitiumMediaUrl,
            websiteText,
        }
    },
    computed: {
        imageUrl() {
            return this.toLitiumMediaUrl(this.selectedVariant.co2ImageId);
        },
        showCo2() {
            return !!this.selectedVariant.co2ImageId;
        },
        co2Amount(){
            return this.selectedVariant.co2EmissionKg
        }
    },
});
</script>

<template>
    <div v-if="showCo2" class="product-info__co2">
        <img :src="imageUrl" loading="lazy" class="product-info__co2-image" />
        <p class="product-info__co2-kg">
            {{ websiteText('pdp__info_co2_kg',{amount: co2Amount }).value }}
        </p>
    </div>
</template>

<style>

.product-info__co2{
    margin: 1rem 0 1rem 1rem;
    display: flex;
}

.product-info__co2-image {
    max-width: 100px;
    max-height: 60px;
}

.product-info__co2-kg{
    margin-left: 1rem;
    font-size: 20px;
    font-weight: bold;
    vertical-align: bottom;
}

</style>
